/* eslint-disable class-methods-use-this */
import { Subscription } from 'rxjs'
import { DMGCoreNavigationConfiguration } from 'services/common/entities/Configuration'
import log from 'lib/logLevel'
import { detectAppViewMode } from 'utils/app'
import LanguageService from 'app/services/Language'
import ConfigurationService from 'app/services/Configuration'
import Plugin from '../Plugin'
import { AppPluginStore } from '../types'

class DmgMoriCoreNavPlugin extends Plugin {
  namespace = 'DmgMoriCoreNavPlugin'

  pluginStore!: AppPluginStore

  subscriptions: Subscription[] = []

  config: DMGCoreNavigationConfiguration | null = null

  node: HTMLElement | null = null

  scriptEl: HTMLScriptElement | null = null

  scriptUrl = 'https://dmgmori.com/corenavigation/v1/component.js'

  mounted = false

  init(pluginStore: AppPluginStore): void {
    this.pluginStore = pluginStore
  }

  shouldInstall(): boolean {
    const isWebViewMode = !detectAppViewMode()
    const config = ConfigurationService.pluginsConfiguration()?.dmgCoreNavigation
    return isWebViewMode && Boolean(config?.enabled)
  }

  getPluginName(): string {
    return `${this.namespace}@1.0.0`
  }

  getDependencies(): string[] {
    return []
  }

  loadScript(): void {
    this.scriptEl = document.createElement('script')
    this.scriptEl.setAttribute('type', 'module')
    this.scriptEl.setAttribute('src', this.scriptUrl)
    document.head.appendChild(this.scriptEl)
  }

  mountInto(container: HTMLElement): void {
    if (!this.shouldInstall()) {
      this.mounted = false
      return
    }

    this.createNode(container)
  }

  createNode(container: HTMLElement): void {
    if (!this.config || !this.config.type) {
      this.mounted = false
      return
    }
    if (!this.node) {
      this.node = document.createElement('core-navigation')
      this.node.setAttribute('variant', LanguageService.currentLanguage().language)
      this.node.setAttribute('type', this.config.type)
    }
    this.appendNode(container)

    const subscription = LanguageService.language().subscribe((language) => {
      if (this.node) {
        this.node.setAttribute('variant', language.language)
      }
    })
    this.mounted = true
    this.subscriptions.push(subscription)
  }

  appendNode(container: HTMLElement): void {
    if (!this.node) {
      this.createNode(container)
    } else {
      container.appendChild(this.node)
    }
  }

  activate(): void {
    log.info(`${this.namespace}:: activate`)
    this.config = ConfigurationService.pluginsConfiguration()?.dmgCoreNavigation
    this.loadScript()
    this.ready()
  }

  deactivate(): void {
    log.log(`${this.namespace}:: deactivate`)
    this.subscriptions.forEach((subscription) => subscription.unsubscribe())
    this.subscriptions = []
    this.mounted = false

    if (this.node) {
      this.node.remove()
      this.node = null
    }

    if (this.scriptEl) {
      document.head.removeChild(this.scriptEl)
      this.scriptEl = null
    }
  }
}

export default new DmgMoriCoreNavPlugin()
