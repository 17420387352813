interface ICache<T> {
  [key: string]: T
}

class Cache<T> {
  private cache: ICache<T>

  constructor() {
    this.cache = {}
  }

  get(key: string): T | undefined {
    return this.cache[key]
  }

  set(key: string, value: T): void {
    this.cache[key] = value
  }

  remove(key: string): void {
    delete this.cache[key]
  }

  has(key: string): boolean {
    return key in this.cache
  }

  clear(): void {
    this.cache = {}
  }
}

export default Cache
