/* eslint-disable no-restricted-syntax */
import { take } from 'rxjs/operators'
import { DialogType, LoginPromptSource, useDialogStore, useUserStore } from '../store'
import { VideoAsset } from '../../services/common/entities/Asset'
import { Session } from '../../services/common/entities/Session'
import { addQueryParam } from '../../utils/url'
import uuid from '../../lib/uuid'
import Service from './Service'
import LocationService from './Location'
import AuthService from './Auth'
import VideoProgressService from './VideoProgress'

class VideoSliderDeepLinkService extends Service {
  private static readonly PARAM_NAME = 'video'

  private static readonly PARAM_SPLITTER = '-'

  private static registeredVideos = new Map<string, VideoAsset[]>()

  private static activeVideoId?: string

  private static processURL(url: URL, videos?: VideoAsset[], eventId?: string): void {
    const videoParam = new URLSearchParams(url.search).get(this.PARAM_NAME)

    if (!videoParam) {
      return
    }

    const [videoId, sessionId] = videoParam.split(this.PARAM_SPLITTER)

    if (this.activeVideoId === videoId) {
      // video already shown
      return
    }

    if (videoId && this.activeVideoId !== videoId) {
      if (videos) {
        this.searchAndShowVideoInSubset(videoId, videos, sessionId, eventId)
      } else {
        this.searchAndShowVideo(videoId, sessionId, eventId)
      }
    }
  }

  private static searchAndShowVideo(videoId: string, sessionId?: string, eventId?: string) {
    for (const videoAssets of Array.from(this.registeredVideos.values())) {
      for (const video of videoAssets) {
        if (video?._id === videoId) {
          this.showVideo(video, sessionId, eventId)
          return
        }
      }
    }
  }

  private static searchAndShowVideoInSubset(videoId: string, videos: VideoAsset[], sessionId?: string, eventId?: string) {
    for (const video of videos) {
      if (video?._id === videoId) {
        this.showVideo(video, sessionId, eventId)
        return
      }
    }
  }

  private static showVideo(video: VideoAsset, sessionId?: string, eventId?: string): void {
    this.activeVideoId = video._id

    const onDialogClose = () => {
      VideoProgressService.refresh(this.activeVideoId);
      this.activeVideoId = undefined
      LocationService.removeQueryParam(this.PARAM_NAME)
    }

    const showVideoDialog = () =>
      useDialogStore.getState().showDialog({
        type: DialogType.VIDEO,
        context: {
          sessionId,
          asset: video,
          eventId,
          onInit: async (pc) => {
            const positions = await VideoProgressService.getAssetLastEndPositions([video._id]).pipe(take(1)).toPromise()
            const lastEndPosition = positions[video._id]
            if (lastEndPosition) {
              pc.currentTime(lastEndPosition)
            }
          }
        },
        onDialogClose,
      })

    if (AuthService.hasAccess(video.executeAccess || [{ _id: '', accessPolicyType: 'Platform' }], useUserStore.getState().isLoggedIn())) {
      showVideoDialog()
    } else {
      useDialogStore.getState().showDialog({
        type: DialogType.LOGIN,
        onDialogClose,
        context: {
          onSuccess: () => {
            showVideoDialog()
          },
          promptSource: LoginPromptSource.CONTENT_REQUIRES_LOGIN
        },
      })
    }
  }

  public static generateVideoParam(search: string, asset: VideoAsset, session?: Session): string {
    if (!session) {
      return addQueryParam(search, this.PARAM_NAME, asset._id)
    }

    return addQueryParam(search, this.PARAM_NAME, `${asset._id}${this.PARAM_SPLITTER}${session._id}`)
  }

  public static async init(): Promise<void> {
    LocationService.url().subscribe((url) => {
      this.processURL(url)
    })
  }

  public static register(videos: VideoAsset[], eventId?: string): string {
    const id = uuid()
    this.registeredVideos.set(id, videos)
    this.processURL(LocationService.currentUrl(), videos, eventId)
    return id
  }

  public static unregister(id: string): void {
    this.registeredVideos.delete(id)
  }
}

export default VideoSliderDeepLinkService
