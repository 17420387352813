import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import isBetween from 'dayjs/plugin/isBetween'

// add system locale by platform
// https://github.com/iamkun/dayjs/tree/dev/src/locale
import 'dayjs/locale/de'
import 'dayjs/locale/en'
import 'dayjs/locale/es' // spanish
import 'dayjs/locale/fr'
import 'dayjs/locale/it'
import 'dayjs/locale/pl'
import 'dayjs/locale/ja' // Japanese
import 'dayjs/locale/ru'
import 'dayjs/locale/zh-cn' // china
import 'dayjs/locale/ko' // korean
import 'dayjs/locale/cs' // czech
import 'dayjs/locale/tk' // Turkmen

// add base plugins
dayjs.extend(relativeTime)
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(localizedFormat)
dayjs.extend(isBetween)

/**
 * Switch dayjs locale
 * Try match the right language from i18next
 */
const setLocaleByI18n = (language: string): string => {
  let lng: string
  switch (language) {
    case 'jp':
      lng = 'ja'
      break
    case 'kr':
      lng = 'ko'
      break
    case 'cn':
      lng = 'zh-cn'
      break
    case 'cz':
      lng = 'cs'
      break
    case 'tr':
      lng = 'tk'
      break
    case 'us':
      lng = 'en'
      break
    default:
      lng = language
      break
  }

  return dayjs.locale(lng)
}

export { dayjs, setLocaleByI18n }
