import axios, { AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios'
import { getPreviewParam, setPreviewToken, previewStorageKey } from '../../utils/preview'
import { ErrorInterceptor } from '../../types/api'
import { anonymousAccessPass } from '../../app/config/routes'
import isDev from '../../utils/isDev'

export const addPreview = (config: AxiosRequestConfig): AxiosRequestConfig => {
  // todo: localStorage
  const previewToken = getPreviewParam()

  if (previewToken) {
    // eslint-disable-next-line no-param-reassign
    config.headers[previewStorageKey] = previewToken
  }

  return config
}

export const removePreview = (error: AxiosError): ErrorInterceptor => {
  const {
    response,
    request,
  }: {
    response?: AxiosResponse
    request?: XMLHttpRequest
  } = error

  if (axios.isCancel(error)) {
    if (isDev) console.warn('Request canceled:', error.message)
  }

  // invalid status attempt handler
  if (response !== undefined) {
    const { config } = response
    const url = config.url ? config.url : ''

    // anonymous access
    if (anonymousAccessPass(url)) {
      return response
    }

    if (response.status === 400) {
      setPreviewToken()
    }
  } else if (request) {
    if (isDev) console.error('Request failed. Please try again.')
    return null
  }

  return Promise.reject(error)
}
