import ConfigurationService from '../app/services/Configuration'
import { StIconId, ST_ICON_CODEPOINTS } from '../types/icon-types'

const getIconClassNameByTheme = (iconId: string, additionalClass = ''): StIconId => {
  const themeConfiguration = ConfigurationService.themeConfiguration()
  const iconConfigurationType = themeConfiguration?.iconFont || ''

  if (isOfTypeStIconId(`${iconConfigurationType}-ic_${iconId}`)) {
    return `icon ${additionalClass} icon-${iconConfigurationType}-ic_${iconId}` as StIconId
  }

  return `icon ${additionalClass} icon-ic_${iconId}` as StIconId
}

function isOfTypeStIconId(key: string): boolean {
  return Object.keys(ST_ICON_CODEPOINTS).find((k) => key === k) !== undefined
}

export default getIconClassNameByTheme
