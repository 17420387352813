import { BehaviorSubject, Observable } from 'rxjs'
import Service from './Service'
import { addListener, useUserStore, ViewMode } from '../store'
import { AuthUser } from '../../services/common/entities/AuthUser'
import {
  AccessPolicy,
  isEventTicketAccessPolicy,
  isPlatformAccessPolicy,
  isPublicAccessPolicy,
} from '../../services/common/entities/AccessPolicy'
import GetAuthUser from '../../services/auth/GetAuthUser'
import LayoutEditorService from './LayoutEditor'
// eslint-disable-next-line import/no-cycle
import AzureAdService from './auth/AzureAd'
// eslint-disable-next-line import/no-cycle
import OpenIdConnectService from './auth/OpenIdConnect'
import LocationService from './Location'

export type LoginCheckState = 'init' | 'overlay' | 'done'

class AuthService extends Service {

  private static loginCheckStateSubject: BehaviorSubject<LoginCheckState> = new BehaviorSubject<LoginCheckState>('init')

  private static authUserSubject: BehaviorSubject<AuthUser | undefined> = new BehaviorSubject<AuthUser | undefined>(undefined)

  public static loginCheckState(): Observable<LoginCheckState> {
    return AuthService.loginCheckStateSubject.asObservable()
  }

  public static authUser(): Observable<AuthUser | undefined> {
    return AuthService.authUserSubject.asObservable()
  }

  public static currentAuthUser(): AuthUser | undefined {
    return AuthService.authUserSubject.getValue()
  }

  public static hasAccess(accessPolicies: AccessPolicy[], isLoggedIn: boolean, userEventTickets?: string[]): boolean {
    if (!accessPolicies || accessPolicies.length === 0) return true
    if (accessPolicies.find((a) => isPublicAccessPolicy(a))) return true
    if (accessPolicies.find((a) => isPlatformAccessPolicy(a)) && isLoggedIn) return true
    if (accessPolicies.find((a) => isEventTicketAccessPolicy(a) && (userEventTickets || []).includes(a.eventTicket)) && isLoggedIn) return true
    return false
  }

  static async init(): Promise<void> {
    addListener((state) => {
      if (state.user !== AuthService.authUserSubject.getValue()) {
        AuthService.authUserSubject.next(state.user)
      }
    })

    if (useUserStore.getState().user) {
      AuthService.authUserSubject.next(useUserStore.getState().user)
    }
  }

  private static addTokenEventListener(): void {
    window.addEventListener('message', (e) => {
      console.warn('message received; e:', e)
      console.warn('message received; e.data:', e.data)
      const messageToken = e.data?.token
      const clientId = e.data?.clientId
      console.warn('Auth.ts message received', messageToken, clientId)
      if (messageToken) {
        AuthService.loginFromPostMessage(messageToken, clientId)
      }
    })
  }

  // Login from the outside of an iFrame
  public static async checkParentLogin(): Promise<void> {
    if(LocationService.currentQueryParams().useParentLogin) {
      AuthService.addTokenEventListener()

      if (document.readyState === 'complete') { 
        window.parent.postMessage({ 'action': 'getToken' }, '*')
      } else {
        window.addEventListener('load', () => {
          window.parent.postMessage({ 'action': 'getToken' }, '*')
        })
      }
 
    } else {
      const isAppViewMode = localStorage.getItem('viewMode') === ViewMode.App
      if(isAppViewMode) {
        AuthService.addTokenEventListener()
      }
    }
  }

  private static async loginFromPostMessage(messageToken: string, clientId?: string): Promise<void> {
    const token = await AzureAdService.tokenViaAdToken(messageToken, clientId)

    if (token){
      const userState = useUserStore.getState()
      userState.setUser(undefined, token)
      console.warn('Auth.ts loginFromPostMessage', token)
      await AuthService.checkToken(token, true)
    }
  }

  private static async checkToken(newToken: string, showError = false): Promise<boolean> {
    const userState = useUserStore.getState()

    try {
      const userData = await GetAuthUser(newToken)
      console.warn('Auth.ts checkToken', newToken, userData)
      if (showError && 'errorTag' in userData) {
        userState.setError(userData)
      } else if ('_id' in userData) {
        userState.setUser(userData, newToken)
        return true
      }
    } catch (err) {
      userState.resetUser()
    }

    return false
  }

  public static async checkLogin(): Promise<void> {
    const userState = useUserStore.getState()

    try {
      // check if user is already logged in
      if (userState.token && (await AuthService.checkToken(userState.token))) {
        AuthService.loginCheckStateSubject.next('done')
        return
      }

      // if we are in the layout editor
      if (LayoutEditorService.currentLayoutEditorMode()) {
        await AuthService.checkToken('preview') // can be a random string, the preview token will be used
        AuthService.loginCheckStateSubject.next('done')
        return
      }

      let token: string | null = null


      // check if we have an azuread redirect
      if (AzureAdService.isEnabled()) {
        if (await AzureAdService.loginInProgress()) {
          AuthService.loginCheckStateSubject.next('overlay')
          token = await AzureAdService.token()
        } else {
          const azureAdAuthResult  = await AzureAdService.performSilentLogin()
          const adToken = azureAdAuthResult?.idToken

          if (adToken) {
            AuthService.loginCheckStateSubject.next('overlay')
            token = await AzureAdService.tokenViaAdToken(adToken)
          }
        }
      }

      // check if we have an openid connect redirect
      if (OpenIdConnectService.isEnabled() && await OpenIdConnectService.loginInProgress()) {
        AuthService.loginCheckStateSubject.next('overlay')
        token = await OpenIdConnectService.token()
      }

      if (token) {
        userState.setUser(undefined, token)
        await AuthService.checkToken(token, true)
      } else if (userState.user || userState.token) {
        userState.resetUser()
      }

    } catch (e) {
      console.error('Caught error in App.tsx', e)
    }

    AuthService.loginCheckStateSubject.next('done')
  }

  public static logout(): void {
    const userState = useUserStore.getState()
    userState.resetUser()

    if (AzureAdService.isEnabled()) {
      // Manual page reload is not required here as Azure AD logout uses MSAL's `logoutRedirect`.
      // The `logoutRedirect` method automatically redirects the user to the Azure AD logout page and back to the app.
      AzureAdService.logout()
    } else {
      window.location.reload()
    }

  }

}

export default AuthService
