import create from 'zustand'

import { persist, purge, useUserStore } from './user-store'
import { getRandomHEXColor } from '../../utils/dom'

import ChatService from '../../services/chat/Chat'
import {
  isPollMessage,
  isTextMessage,
  Message,
  PollMessage,
  TextMessage,
} from '../../services/common/entities/Message'
import { PostChatMessagesBody } from '../../services/common/api/v1/chats/PostChatMessages'
import { PostChatMessagePollAnswersBody } from '../../services/common/api/v1/chats/PostChatMessagePollAnswers'

export type UseChatPersistStoreProps = {
  letters: { [T: string]: string }
  getColorByLetter(letter: string): string
}

export const useChatPersistStore = create<UseChatPersistStoreProps>(
  persist(
    {
      key: 'chat',
    },
    (set, get) => ({
      letters: {},
      reset() {
        purge()
        set((state) => ({ ...state, letters: {} }), true)
      },
      getColorByLetter: (letter: string): string => {
        const { letters } = get()
        let bgColor: string

        if (letter in letters) {
          bgColor = letters[letter]
        } else {
          bgColor = getRandomHEXColor()
          letters[letter] = bgColor
          set((state) => ({ ...state, letters }))
        }

        return bgColor
      },
    })
  )
)

type GetMessageResult = PollMessage | TextMessage | undefined

export type UseChatStore = {
  chatService: ChatService | undefined
  messages: Message[]
  totalCount: number
  addMessage(message: PostChatMessagesBody): Promise<any>
  addPollAnswers(poll: PostChatMessagePollAnswersBody): Promise<any>
  setMessage(message: Message | TextMessage | PollMessage): void
  setTotalCount(totalCount: number): void
  setChatService(chatId: string): void
  getChat(limit?: number, counter?: number): Promise<any>
  getSum(): number
  getLastMessageCounter(): number
  getMessage(messageId: string): GetMessageResult
}

const mergeMessageList = (oldMessages: Message[], message: Message, insert = true): Message[] => {
  // need to change reference, so that React can react to updates correctly
  let sliceValue = 0
  if (oldMessages.length > 100) {
    sliceValue = 1
  }
  const messages = oldMessages.slice(sliceValue)

  // remove if exist // todo add auth
  const foundMessageIndex = messages.findIndex((item) => item._id === message._id)

  if (foundMessageIndex !== -1) {
    if (!insert || messages[foundMessageIndex].messageType === 'TextMessage') {
      messages.splice(foundMessageIndex, 1)
    } else {
      Object.assign(messages[foundMessageIndex], message)
      return messages
    }
  }

  if (!insert) return messages

  const userState = useUserStore.getState()

  if (userState.user?._id === message.user?._id) {
    /* eslint-disable no-param-reassign */
    message.date = message.createdAt
  }

  if (messages.length === 0 || message.date >= messages[messages.length - 1].date) {
    messages.push(message)
  } else {
    for (let i = 0; i < messages.length; i += 1) {
      if (message.date < messages[i].date) {
        messages.splice(i, 0, message)
        break
      }
    }
  }

  return messages
}

export const useChatStore = create<UseChatStore>((set, get) => ({
  chatService: undefined,
  totalCount: 0,
  messages: [],
  addMessage: async (message: PostChatMessagesBody) => {
    return new Promise((resolve, reject) => {
      const { messages, chatService } = get()

      async function tryToAddMessage() {
        if (chatService) {
          let result: any
          try {
            result = await chatService.addMessage(message)
          } catch (e) {
            console.error(e)
            reject(e)
          }

          if (result) {
            const newMessages = mergeMessageList(messages, result)
            set((state) => ({ ...state, messages: newMessages, totalCount: state.totalCount + 1 }))
            resolve(result)
          }
        }
      }

      tryToAddMessage()
    })
  },
  addPollAnswers: async (poll: PostChatMessagePollAnswersBody) => {
    return new Promise((resolve, reject) => {
      const { chatService } = get()

      async function tryToVote() {
        if (chatService) {
          let result: any
          try {
            result = await chatService.addPollAnswers(poll)
          } catch (e) {
            console.error(e)
            reject(e)
          }

          resolve(result)
        }
      }

      tryToVote()
    })
  },
  setMessage: (message: Message) => {
    const { messages } = get()
    const newMessages = mergeMessageList(messages, message)
    set((state) => ({ ...state, messages: newMessages }))
  },
  setTotalCount: (totalCount: number) => {
    set((state) => ({ ...state, totalCount }))
  },
  setChatService: (chatId: string) => {
    set((state) => ({ ...state, chatService: new ChatService(chatId), messages: [] }), true)
    get().chatService?.mitt.on('chat:message', (message: Message) => {
      if (get().chatService?.chat() === message.chat) {
        const { messages } = get()
        const newMessages = mergeMessageList(messages, message)
        set((state) => ({ ...state, messages: newMessages }))
      }
    })
    get().chatService?.mitt.on('chat:message:delete', (message) => {
      const { messages } = get()
      const newMessages = mergeMessageList(messages, message as Message, false)
      set((state) => ({ ...state, messages: newMessages }))
    })
  },
  getChat: async (limit) => {
    const { chatService, messages: currentMessages } = get()
    const chatMessages = await chatService?.getChatMessages({ limit })

    if (chatMessages?.totalCount) {
      const messages = currentMessages.concat(chatMessages.items)

      set((state) => ({
        ...state,
        messages: messages.reverse(),
        totalCount: chatMessages.totalCount,
      }))
    }
  },
  getSum: () => {
    return get().messages.length
  },
  getLastMessageCounter: () => {
    const { messages } = get()

    if (messages.length === 0) return 0

    let largest = 0
    // eslint-disable-next-line no-return-assign
    messages.sort((a, b) => (largest = a.counter > b.counter ? a.counter : b.counter))

    return largest > 0 ? largest - 1 : largest
  },
  getMessage: (messageId: string) => {
    const { messages } = get()
    if (messages.length === 0) return undefined

    const found = messages.find((message) => message._id === messageId)
    if (found) {
      if (isTextMessage(found)) return found as TextMessage
      if (isPollMessage(found)) return found as PollMessage
    }
    return undefined
  },
}))
