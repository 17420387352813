import { AxiosInstance } from 'axios'
import mitt, { Emitter } from 'mitt'

import http from '../api'
import websocketInstance from '../ws'
import { WebsocketInstance } from '../../lib/websocket'

import { TextMessage, DeleteMessage } from '../common/entities/Message'
import {
  PostChatMessagesResponse,
  PostChatMessagesBody,
} from '../common/api/v1/chats/PostChatMessages'
import {
  PostChatMessagePollAnswersBody,
  PostChatMessagePollAnswersResponse,
} from '../common/api/v1/chats/PostChatMessagePollAnswers'

export type GetMessagesProps = {
  limit?: number
  counter?: number
}

type EmitterProps = {
  'chat:message': TextMessage
  'chat:message:delete': DeleteMessage
}

class ChatService {
  private wsService: WebsocketInstance

  private apiService: AxiosInstance

  private chatId: string

  messageLimit: number

  messageLimitLive: number

  mitt: Emitter<EmitterProps>

  constructor(chatId: string) {
    this.wsService = websocketInstance
    this.apiService = http
    this.chatId = chatId
    this.mitt = mitt()
    this.messageLimit = 100
    this.messageLimitLive = 500

    this.wsService.on<TextMessage>('chat:message', (message) => {
      if (message.chat === chatId) {
        this.mitt.emit('chat:message', message)
      }
    })

    this.wsService.on<DeleteMessage>('chat:message:delete', (message) => {
      this.mitt.emit('chat:message:delete', message)
    })

    this.wsService.register('chat', { chat: chatId }, `chat:${chatId}`)
  }

  chat(): string {
    return this.chatId
  }

  addMessage(message: PostChatMessagesBody): Promise<PostChatMessagesResponse> {
    return this.apiService
      .post(`chats/${this.chatId}/messages`, message)
      .then((result) => result.data)
  }

  addPollAnswers(
    data: PostChatMessagePollAnswersBody
  ): Promise<PostChatMessagePollAnswersResponse> {
    return this.apiService
      .post(`chats/${this.chatId}/messages/${data.poll}/pollanswers`, data)
      .then((result) => result.data)
  }

  getChatMessages(args?: GetMessagesProps): Promise<any> {
    const queryParams: GetMessagesProps = { limit: this.messageLimit }

    if (args?.limit) {
      queryParams.limit = args.limit
    }

    if (args?.counter) {
      queryParams.counter = args.counter
    }

    return this.apiService
      .get(`chats/${this.chatId}/messages`, { params: queryParams })
      .then((response) => response.data)
  }
}

export default ChatService
