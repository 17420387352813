/* eslint-disable no-restricted-syntax */
/* eslint-disable class-methods-use-this */
import { Subscription } from 'rxjs'
import { isDataProtectionRoute } from 'app/router/routes'
import { requestTrackingPermission } from 'services/AppBridge'
import { UsercentricsV2Configuration } from '../../../services/common/entities/Configuration'
import { AppPluginStore } from '../types'
import Plugin from '../Plugin'
import log from '../../../lib/logLevel'
import ConfigurationService from '../../services/Configuration'
import i18n from '../../../locales/i18n'
import { localeToLanguageMapping } from '../../../lib/usercentrics'
import LanguageService from '../../services/Language'
import LocationService from '../../services/Location'
import PostAcceptAgreements from '../../../services/auth/PostAcceptAgreements'
// eslint-disable-next-line import/no-cycle
import AuthService from '../../services/Auth'
import { detectAppViewMode } from '../../../utils/app'

declare global {
  interface Window {
    UC_UI: any
    UC_UI_SUPPRESS_CMP_DISPLAY?: boolean
    UC_UI_DOMAINS?: {
      crossDomainConsentSharingIFrame: string
    }
  }
}

const UC_HAS_ANALYTICS_CONSENT_KEY = 'uc_has_analytics_consent'

export const setUcConsent = (value: boolean): void => {
  localStorage.setItem(UC_HAS_ANALYTICS_CONSENT_KEY, JSON.stringify(value))
}

export const getUcConsent = (defaultValue = false): boolean => {
  return JSON.parse(
    localStorage.getItem(UC_HAS_ANALYTICS_CONSENT_KEY) ?? JSON.stringify(defaultValue)
  )
}

const trackingPermissionActions = ['onAcceptAllServices', 'onUpdateServices', 'onDenyAllServices']

class UsercentricsV2Plugin extends Plugin {
  namespace = 'Usercentrics-V2'

  pluginStore!: AppPluginStore

  scriptEl!: HTMLScriptElement

  config!: UsercentricsV2Configuration

  scriptUrl = 'https://app.usercentrics.eu/browser-ui/latest/loader.js'

  id!: string

  subscription?: Subscription | null = null

  constructor() {
    super()
    this.dialogListener = this.dialogListener.bind(this)
  }

  shouldInstall(): boolean {
    const config = ConfigurationService.pluginsConfiguration()?.usercentricsV2
    return config?.enabled && !!config?.id
  }

  getPluginName(): string {
    return `${this.namespace}@1.0.0`
  }

  getDependencies(): string[] {
    return []
  }

  init(pluginStore: AppPluginStore): void {
    this.pluginStore = pluginStore
  }

  loadScript(): void {
    log.info(`${this.namespace}:: load script`)

    if (this.config.id && !document.getElementById(this.config.id)) {
      const scriptUrl = this.config.scriptUrl || this.scriptUrl
      const isAppViewMode = detectAppViewMode()
      const { origin } = new URL(scriptUrl)
      this.appendLink('dns-prefetch', origin)
      this.appendLink('preconnect', origin)
      this.appendLink('preload', origin, 'script')

      this.scriptEl = document.createElement('script')
      this.scriptEl.setAttribute('type', 'application/javascript')
      this.scriptEl.setAttribute('src', scriptUrl)
      this.scriptEl.setAttribute('id', 'usercentrics-cmp')
      this.scriptEl.setAttribute('data-settings-id', this.config.id)

      const language = localeToLanguageMapping[i18n.language] || i18n.language
      this.scriptEl.setAttribute('data-language', language)

      this.scriptEl.async = true
      document.head.appendChild(this.scriptEl)

      this.scriptEl.addEventListener('load', () => {
        if (this.config.crossDomainConsent) {
          window.UC_UI_DOMAINS = {
            crossDomainConsentSharingIFrame: this.config.crossDomainConsent,
          }
        }

        window.addEventListener(
          this.config.onConsentEventName ?? 'UC_SDK_EVENT',
          async (event: any) => {
            const serviceName = this.config.analyticsServiceName
            const detail = event?.detail

            if (detail && serviceName && detail.event === 'consent_status') {
              const hasAnalyticsConsent = detail[serviceName] === true
              setUcConsent(hasAnalyticsConsent)

              if (isAppViewMode && trackingPermissionActions.includes(detail.action)) {
                requestTrackingPermission(true)
              }

              if (detail.action === 'onUpdateServices') {
                const isAnyConsentRejected = Object.keys(detail).some(
                  (key) => detail[key] === false
                )
                if (isAnyConsentRejected) {
                  window.location.reload()
                  return
                }
              }

              if (AuthService.currentAuthUser()) {
                await PostAcceptAgreements({ analytics: hasAnalyticsConsent })
              } else {
                this.subscription?.unsubscribe()
                this.subscription = AuthService.authUser().subscribe(async (authUser) => {
                  if (authUser) {
                    this.subscription?.unsubscribe()
                    await PostAcceptAgreements({ analytics: hasAnalyticsConsent })
                  }
                })
              }
            }
          }
        )
      })
    }
  }

  private appendLink(rel: string, href: string, as?: string) {
    const linkElement = document.createElement('link')
    linkElement.setAttribute('rel', rel)
    linkElement.setAttribute('href', href)
    if (as) {
      linkElement.setAttribute('as', as)
    }
    document.head.appendChild(linkElement)
  }

  getConfig(): UsercentricsV2Configuration {
    const usercentricsV2 = ConfigurationService.pluginsConfiguration()?.usercentricsV2

    if (usercentricsV2?.app && detectAppViewMode()) {
      return { enabled: usercentricsV2.enabled, ...usercentricsV2.app }
    }

    return usercentricsV2
  }

  activate(): void {
    log.info(`${this.namespace}:: activate`)

    this.config = this.getConfig()
    this.config.id?.trim()
    this.config.analyticsServiceName?.trim()
    this.config.analyticsServiceId?.trim()
    this.config.scriptUrl?.trim()

    if (isDataProtectionRoute()) {
      window.UC_UI_SUPPRESS_CMP_DISPLAY = true
    }

    if (this.config?.id) {
      this.loadScript()
      LanguageService.language().subscribe((language) => this.languageSwitch(language.language))
      LocationService.url().subscribe((url) => {
        if (isDataProtectionRoute(url.pathname)) {
          // Suppress CMP display on data protection route
          window.UC_UI_SUPPRESS_CMP_DISPLAY = true
          // Close the CMP if it's currently displayed
          window.UC_UI?.closeCMP()
        } else if (window.UC_UI_SUPPRESS_CMP_DISPLAY) {
          // If suppression was active and we are not on the data protection route, restart CMP
          window.UC_UI_SUPPRESS_CMP_DISPLAY = false
          window.UC_UI?.restartCMP()
        }
      })
    }

    document.addEventListener('click', this.dialogListener)
    this.ready()
  }

  async languageSwitch(locale: string): Promise<void> {
    if (!this.shouldInstall()) {
      return
    }

    await this.isReady()
    const language = localeToLanguageMapping[locale] || locale
    window.UC_UI?.updateLanguage?.(language)
  }

  deactivate(): void {
    log.log(`${this.namespace}:: deactivate`)
    if (this.scriptEl && this.scriptEl.parentNode) {
      document.head.removeChild(this.scriptEl)
    }
    this.subscription?.unsubscribe()
    document.removeEventListener('click', this.dialogListener)
  }

  // legacy Usercentrics v1 compatibility
  private dialogListener(ev: MouseEvent) {
    for (const node of ev.composedPath()) {
      if ((node as HTMLElement).nodeName === 'A') {
        const hrefNode = node as HTMLAnchorElement
        if (hrefNode.hash?.includes('uc-central-modal-show')) {
          window.UC_UI?.showSecondLayer()
          return
        }
      }
    }
  }
}

export default new UsercentricsV2Plugin()
