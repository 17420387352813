/* eslint-disable react/button-has-type */
import * as React from 'react'
import classnames from 'classnames'
import { checkRelativeUrl } from 'utils/url'
import LinkElement from 'components/link-element/LinkElement'
import LanguageService from 'app/services/Language'
import ConfigurationService from '../../app/services/Configuration'
import getIconClassNameByTheme from '../../utils/getIconClassNameByTheme'
import { GlobalButtonStyleType } from '../../services/common/entities/Configuration'

import block from '../../lib/bem'
import styles from './Button.module.scss'

const b = block(styles, 'Button')

type ButtonAs = 'button' | 'link' | 'externalLink' | 'unstyled' | 'text-button'
type ButtonStyle = 'primary' | 'secondary' | 'tertiary'
type ButtonType = JSX.IntrinsicElements['button']['type']

export interface ButtonProps {
  as?: ButtonAs
  icon?: string
  iconPosition?: 'start' | 'end'
  id?: string
  type?: ButtonType
  className?: string
  disabled?: boolean
  href?: string
  inStage?: boolean
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void
  rel?: string
  refElement?: React.ForwardedRef<HTMLButtonElement>
  linkRef?: React.ForwardedRef<HTMLAnchorElement>
  size?: 'small' | 'middle' | 'large'
  styleType?: ButtonStyle
  target?: '_blank' | '_self'
  to?: string
  buttonStyle?: GlobalButtonStyleType
  shouldShowArrow?: boolean
}

const Button: React.FC<ButtonProps> = ({
  as = 'button',
  className = undefined,
  type: buttonType = 'button',
  icon = undefined,
  iconPosition = 'end',
  id = undefined,
  href = undefined,
  inStage,
  onClick = undefined,
  refElement = null,
  linkRef = null,
  styleType = 'primary',
  target = '_blank',
  to = undefined,
  buttonStyle,
  disabled,
  shouldShowArrow,
  ...props
}) => {
  const { children, ...rest } = props
  const themeConfiguration = ConfigurationService.themeConfiguration()

  const buttonConfigurationType = React.useMemo(
    () => (buttonStyle || themeConfiguration?.globalButtonStyle) ?? 'default',
    [themeConfiguration, buttonStyle]
  )

  const showArrow = shouldShowArrow || buttonConfigurationType === 'arrow'

  const allClassNames = classnames(
    b({
      styleType,
      inStage,
      croppedCorner: buttonConfigurationType === 'croppedCorner',
      croppedCornerRight: buttonConfigurationType === 'croppedCornerRight',
      showArrow,
      fullWidth: buttonConfigurationType === 'fullWidth',
      disabled,
    }),
    className
  )

  if (!disabled && as === 'link' && to !== undefined) {
    return (
      <LinkElement
        className={allClassNames}
        to={checkRelativeUrl(to, LanguageService.currentLanguage().language)}
        {...props}
        data-style={styleType}
        data-button-type={buttonConfigurationType}
      >
        {icon && iconPosition === 'start' && (
          <i data-icon-position="start" className={getIconClassNameByTheme(icon)} />
        )}
        {children && <span>{children}</span>}
        {showArrow && !icon && (
          <i data-icon-position="end" className={getIconClassNameByTheme('arrow')} />
        )}
        {icon && iconPosition === 'end' && (
          <i data-icon-position="end" className={getIconClassNameByTheme(icon)} />
        )}
      </LinkElement>
    )
    // eslint-disable-next-line no-else-return
  } else if (!disabled && as === 'externalLink' && href !== undefined) {
    return (
      <a
        className={allClassNames}
        href={href}
        target={target || '_blank'}
        data-style={styleType}
        data-button-type={buttonConfigurationType}
        ref={linkRef}
        {...props}
      >
        {icon && iconPosition === 'start' && (
          <i data-icon-position="start" className={getIconClassNameByTheme(icon)} />
        )}
        {children && <span>{children}</span>}
        {showArrow && !icon && (
          <i data-icon-position="end" className={getIconClassNameByTheme('arrow')} />
        )}
        {icon && iconPosition === 'end' && (
          <i data-icon-position="end" className={getIconClassNameByTheme(icon)} />
        )}
      </a>
    )
  } else if (as === 'unstyled') {
    return (
      <button
        className={className}
        onClick={onClick}
        ref={refElement}
        type={buttonType}
        data-style={styleType}
        data-button-type={buttonConfigurationType}
        disabled={disabled}
        {...rest}
      >
        {icon && iconPosition === 'start' && (
          <i data-icon-position="start" className={getIconClassNameByTheme(icon)} />
        )}
        {children && <span>{children}</span>}
        {icon && iconPosition === 'end' && (
          <i data-icon-position="end" className={getIconClassNameByTheme(icon)} />
        )}
      </button>
    )
  } else if (as === 'text-button') {
    return (
      <button
        ref={refElement}
        type={buttonType}
        onClick={onClick}
        data-button-type={buttonConfigurationType}
        className={classnames(b('text-button'), className)}
        disabled={disabled}
      >
        {children && <span>{children}</span>}
      </button>
    )
  }

  return (
    <button
      className={allClassNames}
      id={id}
      onClick={onClick}
      ref={refElement}
      type={buttonType}
      data-style={styleType}
      data-button-type={buttonConfigurationType}
      disabled={disabled}
      {...rest}
    >
      {icon && iconPosition === 'start' && (
        <i data-icon-position="start" className={getIconClassNameByTheme(icon)} />
      )}
      {children && <span>{children}</span>}
      {showArrow && !icon && (
        <i data-icon-position="end" className={getIconClassNameByTheme('arrow')} />
      )}
      {icon && iconPosition === 'end' && (
        <i data-icon-position="end" className={getIconClassNameByTheme(icon)} />
      )}
    </button>
  )
}

export default Button
