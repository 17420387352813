import create from 'zustand'
import uuid4 from 'lib/uuid'
import { PlayerController } from '../../components/video/player/types'
import { GetAuthResponseError } from '../../services/common/api/v1/auth/GetAuth'
import { VideoAsset } from '../../services/common/entities/Asset'
import { Event } from '../../services/common/entities/Event'
import { Form } from '../../services/common/entities/Form'
import LocationService from '../services/Location'
import { EventRegistration } from '../../services/common/entities/EventRegistration'
import { ImageTextOverlay, Marker, MarkerGroup } from '../../services/common/entities/Map'
import { CategoryLookup, CombinedMarker } from '../../components/interactive-map/types'
import { CompatibleProduct } from '../../components/product-list/types'

type DialogOptions = {
  onDialogClose?: () => void
  onDialogOpen?: () => void
  clickOutsideToHide?: boolean
  escToHide?: boolean
  loginCheck?: boolean // flag to show LOGIN dialog, if user is not logged in
  removeQueryParamsOnHide?: string | string[]
  shouldToggleBodyScroll?: boolean
}

export enum DialogType {
  LOGIN = 'login',
  REGISTER_PLATFORM = 'registerplatform',
  EMAIL_IN_USE = 'registerplatform:emailInUse',
  FORGOT_PASSWORD = 'forgotPassword',
  RESET_PASSWORD = 'resetPassword',
  USER_NOT_ACTIVE = 'userNotActive',
  ACCEPT_AGREEMENTS = 'acceptAgreements',
  REGISTER_EVENT = 'registerevent',
  REGISTER_EVENT_DATA = 'registerevent:data',
  REGISTER_EVENT_CANCEL = 'registerevent:cancel',
  APPOINTMENT = 'meetyourexpert',
  EVENT_LANGUAGE_CHANGE_WARNING = 'event:language:change:warning',
  VIDEO = 'video',
  CONTACT = 'contact',
  CONFIRM = 'confirm',
  FORM = 'form',
  AREA = 'area',
  LEGEND = 'legend',
  CUSTOM = 'custom',
  USERCENTRICS = 'usercentrics',
  EXTERNAL_LINK = 'externallink',
  PRODUCT = 'product',
  PRODUCT_DETAILED_INFO = 'productDetailedInfo',
  DIGITAL_PASS = 'digitalPass',
  IMAGE_TEXT = 'imageText',
}

export enum LoginPromptSource {
  LOGIN_BUTTON,
  CONTENT_REQUIRES_LOGIN,
}

export type DialogData = (
  | {
      type: DialogType.FORGOT_PASSWORD | DialogType.EMAIL_IN_USE | DialogType.USERCENTRICS
    }
  | {
      type: DialogType.APPOINTMENT
      context?: { event?: Event }
    }
  | {
      type: DialogType.RESET_PASSWORD
      context: { token: string }
    }
  | {
      type: DialogType.LOGIN
      context: { onSuccess?: () => void; promptSource: LoginPromptSource; closeOnSuccess?: boolean }
    }
  | {
      type: DialogType.REGISTER_PLATFORM
      context?: { variant?: string; callbackRegistered?: () => void }
    }
  | {
      type: DialogType.USER_NOT_ACTIVE
      context: { email: string; password: string }
    }
  | {
      type: DialogType.ACCEPT_AGREEMENTS
      context: { error: GetAuthResponseError }
    }
  | {
      type: DialogType.REGISTER_EVENT
      context: { eventId: string; onSuccess?: () => void }
    }
  | {
      type: DialogType.REGISTER_EVENT_DATA
      context: { event: Event; eventRegistration?: EventRegistration; onSuccess?: () => void }
    }
  | {
      type: DialogType.REGISTER_EVENT_CANCEL | DialogType.EVENT_LANGUAGE_CHANGE_WARNING
      context: { event: Event }
    }
  | {
      type: DialogType.VIDEO
      context: {
        asset: VideoAsset
        sessionId?: string
        onInit?: (playerController: PlayerController) => void
        eventId?: string
      }
    }
  | {
      type: DialogType.CONTACT
      context: {
        contactFormId: string
        event?: Event
      }
    }
  | {
      type: DialogType.CONFIRM
      context: {
        title: string
        cancelText: string
        confirmText: string
        onCancel: () => void
        onConfirm: () => void
        isAsyncConfirm?: boolean
      }
    }
  | {
      type: DialogType.FORM
      context: {
        form: Form
        event?: Event
      }
    }
  | {
      type: DialogType.DIGITAL_PASS
      context: {
        event: Event
      }
    }
  | {
      type: DialogType.AREA
      context: {
        marker: Marker
      }
    }
  | {
      type: DialogType.IMAGE_TEXT
      context: {
        overlay: ImageTextOverlay
      }
    }
  | {
      type: DialogType.LEGEND
      context: {
        group: MarkerGroup
        categoryLookup: CategoryLookup
        selectedMarker: CombinedMarker | null
        onCategoryClick: (marker: CombinedMarker) => void
      }
    }
  | {
      type: DialogType.PRODUCT
      context: {
        product: CompatibleProduct
      }
    }
  | {
      type: DialogType.PRODUCT_DETAILED_INFO
      context: {
        productId: string
      }
    }
  | {
      type: DialogType.CUSTOM
      context: { dialogContent: JSX.Element | (() => JSX.Element) }
    }
) &
  DialogOptions

type DialogDataWithId = DialogData & {
  id: string
}

type ContentStateProps = {
  activeDialogData?: DialogData
  additionalDialogs: DialogDataWithId[]
  showDialog(dialog: DialogData): void
  clearDialog(): void
  showAdditionalDialog(dialogData: DialogData): void
  clearAdditionalDialog(): void
  getActiveDialogType(): DialogType | undefined
}

const checkQueryParam = (props: ContentStateProps) => {
  const removeQueryParam = props.activeDialogData?.removeQueryParamsOnHide
  if (removeQueryParam) {
    LocationService.removeQueryParam(removeQueryParam)
  }
}

export const useDialogStore = create<ContentStateProps>((set, get) => ({
  activeDialogData: undefined,
  additionalDialogs: [],
  showDialog: (activeDialogData) => {
    checkQueryParam(get())
    const partial = { activeDialogData }
    if (activeDialogData.type === 'registerevent' && !activeDialogData.removeQueryParamsOnHide) {
      partial.activeDialogData.removeQueryParamsOnHide = ['registerevent']
    }
    set({ ...partial, additionalDialogs: [] })
  },
  clearDialog: () => {
    checkQueryParam(get())
    set({
      activeDialogData: undefined,
      additionalDialogs: [],
    })
  },
  showAdditionalDialog: (dialogData: DialogData) => {
    const currentDialog = get().activeDialogData
    if (currentDialog) {
      set((state) => ({
        additionalDialogs: [
          ...state.additionalDialogs,
          {
            ...dialogData,
            id: uuid4(),
          },
        ],
      }))
    }
  },
  clearAdditionalDialog: () => {
    set((state) => {
      if (state.additionalDialogs.length > 0) {
        return {
          additionalDialogs: state.additionalDialogs.slice(0, -1),
        }
      }

      return { ...state }
    })
  },
  getActiveDialogType: (): DialogType | undefined => get().activeDialogData?.type,
}))
