import { useQuery, UseQueryResult } from 'react-query'
import http from '../api'
import { GeoIP } from '../common/entities/GeoIP'

export async function GetGeoIP(ip: string): Promise<GeoIP> {
  return http.get(`/configuration/geoip/${ip}`).then((res) => res.data)
}

const useGetGeoIP = (ip: string, enabled = true): UseQueryResult<GeoIP, Error> => {
  return useQuery(['geoip', ip], () => GetGeoIP(ip), { enabled })
}

export default useGetGeoIP
