/* eslint-disable @typescript-eslint/lines-between-class-members */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable max-classes-per-file */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-debugger */
/* eslint-disable no-param-reassign */

import { User, UserManager } from 'oidc-client-ts'
import Service from '../Service'
import ConfigurationService from '../Configuration'
import http from '../../../services/api'
// eslint-disable-next-line import/no-cycle
import { getEventTrackingStatus } from '../../../utils/loadEventTracking'
import LocationService from '../Location'
import environment from '../../../environments/environment'

class OpenIdConnectService extends Service {
  private static _userManager: UserManager
  private static _lastUser: User

  public static get userManager(): UserManager {
    if (!OpenIdConnectService._userManager && OpenIdConnectService.isEnabled()) {
      OpenIdConnectService._userManager = new UserManager({
        client_id: environment.openIdConnect?.clientId || '',
        authority: environment.openIdConnect?.authority || '',
        redirect_uri: window.location.origin,
        scope: 'openid',
      })
    }

    return OpenIdConnectService._userManager
  }

  private static postLogin(token: string) {
    return http.post('auth/login/openidconnect', {
      acceptAnalytics: getEventTrackingStatus()
    }, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((response) => response.data)
  }

  public static isEnabled(): boolean {
    return ConfigurationService.loginConfiguration().logins.some(
      (l) => l.loginType === 'OpenIdConnect' && l.enabled
    )
  }

  public static async loginInProgress(): Promise<boolean> {
    try {
      const user = await OpenIdConnectService.userManager.signinRedirectCallback()
      OpenIdConnectService._lastUser = user

      return !!user
    } catch (err) {
      return false
    }
  }

  public static async token(): Promise<string | null> {
    try {
      if (OpenIdConnectService._lastUser?.access_token) {
        const auth = await OpenIdConnectService.postLogin(OpenIdConnectService._lastUser.access_token)
        const state: any = OpenIdConnectService._lastUser.state as any
        await OpenIdConnectService.userManager?.clearStaleState()

        LocationService.navigate(state.page || null, state.query || {}, 'set')

        if (auth && 'token' in auth && auth.token) {
          return auth.token
        }
      }

    } catch (err) {
      console.warn(err)
    }

    return null
  }

  public static login() {
    const page = LocationService.currentUrl().pathname

    OpenIdConnectService.userManager.signinRedirect({
      state: {
        page: page.endsWith('/') ? page : `${page}/`,
        query: LocationService.currentQueryParams({
          without: ['state', 'session_state', 'code']
        })
      }
    })
  }

  public static logout() {
    console.warn('logout')
  }
}

export default OpenIdConnectService
