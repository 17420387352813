import { DisplayOption, Page } from '../services/common/entities/Page'

const isValidPage = (page: Page, isApp: boolean): boolean => {
  const pageDisplayOption: DisplayOption = page.displayOption ?? 'everywhere'
  const validOptions: DisplayOption[] = isApp
    ? ['onlyApp', 'everywhere']
    : ['onlyWeb', 'everywhere']
  return validOptions.includes(pageDisplayOption)
}

export default isValidPage
