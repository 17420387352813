/* eslint-disable class-methods-use-this */
import { ExternalConsent } from '../../../services/common/entities/Configuration'
import { AppPluginStore } from '../types'
import Plugin from '../Plugin'
import log from '../../../lib/logLevel'
import LocationService from '../../services/Location'
import AuthService from '../../services/Auth'
import { setAgreementService } from '../../../utils/loadEventTracking'
import ConfigurationService from '../../services/Configuration'

class ExternalConsentPlugin extends Plugin {
  namespace = 'ExternalConsent'

  pluginStore!: AppPluginStore

  config!: ExternalConsent

  consentParameter = 'c'

  shouldInstall(): boolean {
    const config = ConfigurationService.pluginsConfiguration()?.externalConsent
    return config?.enabled && !!config?.redirectUrl
  }

  getPluginName(): string {
    return `${this.namespace}@1.0.0`
  }

  getDependencies(): string[] {
    return []
  }

  init(pluginStore: AppPluginStore): void {
    this.pluginStore = pluginStore
  }

  activate(): void {
    this.config = ConfigurationService.pluginsConfiguration().externalConsent
    const consentParameter = this.config.consentParameter || this.consentParameter

    const consentParameterVal = LocationService.currentUrl().searchParams.get(consentParameter)

    // consent was accepted or not on external site => transfers to local storage
    if (consentParameterVal && consentParameterVal.match(/^(0|1)$/g)) {
      LocationService.removeQueryParam(consentParameter)
      localStorage.setItem(this.getStoreKey(), consentParameterVal)
    }

    const userConsent = localStorage.getItem(this.getStoreKey())

    if (!userConsent) {
      window.location.href = this.config.redirectUrl
      return
    }

    AuthService.authUser().subscribe((authUser) => {
      if (authUser) {
        setAgreementService(userConsent === '1')
      }
    })

    this.ready()
  }

  deactivate(): void {
    log.log(`${this.namespace}:: deactivate`)
  }
}

export default new ExternalConsentPlugin()
