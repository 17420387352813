import { BehaviorSubject, Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { Event } from 'services/common/entities/Event'
import Service from './Service'
import EventService from './Event'

const SELECTED_EVENT_ID_KEY = 'selectedEventId'

export enum SelectedEventStatus {
  Unselected,
  Loading,
  Loaded,
}

interface UnselectedState {
  type: SelectedEventStatus.Unselected
}

interface LoadingState {
  type: SelectedEventStatus.Loading
  eventId: string
}

interface LoadedState {
  type: SelectedEventStatus.Loaded
  event: Event
}

type SelectedEventState = UnselectedState | LoadingState | LoadedState

class SelectedEventService extends Service {
  private static selectedEventStateSubject = new BehaviorSubject<SelectedEventState>({
    type: SelectedEventStatus.Unselected,
  })

  public static selectedEventState(): Observable<SelectedEventState> {
    return this.selectedEventStateSubject.asObservable()
  }

  public static selectedEvent(): Observable<Event | null> {
    return this.selectedEventStateSubject.asObservable().pipe(
      map(state => {
        if (state.type === SelectedEventStatus.Loaded) {
          return state.event
        }
        
        return null
      })
    )
  }

  public static currentSelectedEventState(): SelectedEventState {
    return this.selectedEventStateSubject.getValue()
  }

  public static async refreshSelectedEvent(): Promise<void> {
    const state = this.currentSelectedEventState()

    if (state.type === SelectedEventStatus.Loaded) {
      const eventId = state.event._id
      const event = await EventService.get(eventId).refreshEvent(true)
      this.selectedEventStateSubject.next({ type: SelectedEventStatus.Loaded, event })
    }
  }

  public static selectEvent(eventId: string): void {
    this.selectedEventStateSubject.next({ type: SelectedEventStatus.Loading, eventId })

    EventService.get(eventId)
      .eventAsPromise()
      .then((event) => {
        this.selectedEventStateSubject.next({ type: SelectedEventStatus.Loaded, event })
        localStorage.setItem(SELECTED_EVENT_ID_KEY, eventId)
      })
      .catch(() => {
        this.unselectEvent()
      })
  }

  public static selectEventAndObserve(eventId: string): Observable<SelectedEventState> {
    this.selectEvent(eventId)
    return this.selectedEventStateSubject.asObservable()
  }

  public static unselectEvent(): void {
    this.selectedEventStateSubject.next({ type: SelectedEventStatus.Unselected })
    localStorage.removeItem(SELECTED_EVENT_ID_KEY)
  }

  public static unselectEventById(eventId: string): void {
    const state = this.currentSelectedEventState()

    if (state.type === SelectedEventStatus.Unselected) {
      return
    }

    const selectedEventId = state.type === SelectedEventStatus.Loaded ? state.event._id : state.eventId

    if (selectedEventId === eventId) {
      this.unselectEvent()
    }
  }

  public static async init(): Promise<void> {
    const eventId = localStorage.getItem(SELECTED_EVENT_ID_KEY)

    if (eventId) {
      this.selectEvent(eventId)
    }
  }
}

export default SelectedEventService
