import './wdyr'
import './polyfills'
import React from 'react'
import ReactDOM from 'react-dom'
import { Router } from 'react-router-dom'
import { ErrorBoundary } from 'react-error-boundary'
import AppSetup from 'app/AppSetup'

// yup
import * as yup from 'yup'
import yupLocale from 'locales/yup/yupLocale'

// lazy stuff
import Loadable from '@react-loadable/revised'
import LoadingScreen from './components/loading-screen/LoadingScreen'

// utm
import { setUTM } from './lib/utm'
// import './lib/cache-boost'

// platform
import ErrorFallback from './components/common/ErrorFallback'
import reportWebVitals from './reportWebVitals'

// service providers like api, plugins and websocket
import ServiceProviders from './services/ServiceProviders'
import AppPluginProvider from './app/plugins'

// Services
import LocationService from './app/services/Location'

// Web fonts
import './lib/font-observer'

// vendor script loader
import { vendorScriptsLoader } from './lib/vendors'

// global styles
import './styles/index.scss'

// root element for the app
const rootEl = document.getElementById('root') as HTMLElement

// app rendered do the rest safer now
const afterAppRendered = (): void => {
  // the app is ready
  document.children[0].setAttribute('class', 'js ui-loaded')
  // write UTM params
  setUTM()
}

// see: https://github.com/jamiebuilds/react-loadable
const App = Loadable({
  loader() {
    return import(/* webpackChunkName: "app" */ './app/App')
  },
  loading: LoadingScreen,
})

yup.setLocale(yupLocale)

// Single-page application (SPA)
ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <ServiceProviders>
        <AppPluginProvider>
          <Router history={LocationService.history()}>
            <AppSetup>
              <App />
            </AppSetup>
          </Router>
        </AppPluginProvider>
      </ServiceProviders>
    </ErrorBoundary>
  </React.StrictMode>,
  rootEl,
  afterAppRendered
)

vendorScriptsLoader()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
if (process.env.NODE_ENV !== 'production') {
  reportWebVitals(console.warn)
}
