import { nanoid } from 'nanoid'

// https://github.com/ai/nanoid

const uuidLength = 10

/**
 * UID Generator
 */
const uuid4 = (): string => nanoid(uuidLength)

export default uuid4
