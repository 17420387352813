import { match, matchPath } from 'react-router-dom'

export enum QueryParams {
  ACTIVATE = 'activate',
  DIGITAL_PASS = 'digital-pass',
  FORM = 'form',
  PASSWORD_RESET = 'passwordreset',
  PRODUCT = 'product',
  QRID = 'qrid',
  QRMODE = 'qrmode',
  SERIES = 'series',
  MAP = 'map',
  MODE = 'mode',
}

export const routes = {
  APP_FALLBACK: '/:locale/app',
  DATA_PROTECTION: '/:locale/data-protection',
  SEARCH: '/:locale/search',
  USER_PROFILE: '/:locale/profile',
  ASSET: (path: string): string => `/:locale/${path}/:id`,
  EXPLORE: (path: string): string => `/:locale/${path}/:eventId?`,
  EXPLORE_EVENT: (path: string): string => `/:locale/${path}/events/:eventId`,
  EXPLORE_EVENT_PAGE: (path: string): string => `/:locale/${path}/:eventId/:pagename`,
  KEY_TOPIC_OVERVIEW: '/:locale/events/:eventId/keytopics/:id',
  REACTIONS: '/:locale/events/:eventId/reactions/:reactionId',
  EVENT: '/:locale/events/:eventId/:pagename?',
  CORE: '/:locale/:pagename*',
  HOME_LOCALE: '/:locale/',
  HOME: '/',
  NOT_FOUND: '*',
  CHOOSE_A_COUNTRY: '/:locale/choose-a-country',
  EMBEDDED_VIDEO: '/:locale/embedded/video/:videoId',
}

export type AppFallbackRouteParams = {
  locale: string
}

export type DataProtectionRouteParams = {
  locale: string
}

export type SearchRouteParams = {
  locale: string
}

export type UserProfileRouteParams = {
  locale: string
}

export type AssetRouteParams = {
  locale: string
  assetPagePath: string
  id: string
}

export type KeyTopicOverviewRouteParams = {
  locale: string
  eventId: string
  id: string
}

export type ReactionsRouteParams = {
  locale: string
  eventId: string
  reactionId: string
}

export type EventRouteParams = {
  locale: string
  eventId: string
  pagename?: string
}

export type CoreRouteParams = {
  locale: string
  pagename?: string
}

export type ExploreRouteParams = {
  locale: string
  eventId?: string
}

export type ExploreEventRouteParams = {
  locale: string
  eventId: string
}

export type ExploreEventPageRouteParams = {
  locale: string
  eventId: string
  pagename: string
}

export type HomeLocaleRouteParams = {
  locale: string
}

export type ChooseACountryRouteParams = {
  locale: string
}

export type EmbeddedVideoRouteParams = {
  locale: string
  videoId: string
}

export const getEventRouteMatch = (
  pathname: string = window.location.pathname
): match<EventRouteParams> | null => {
  return matchPath<EventRouteParams>(pathname, routes.EVENT)
}

export const getExploreRouteMatch = (
  explorePagePath: string,
  pathname: string = window.location.pathname
): match<ExploreRouteParams> | null => {
  return matchPath<ExploreRouteParams>(pathname, {
    path: routes.EXPLORE(explorePagePath),
    exact: true,
  })
}

export const getExploreEventRouteMatch = (
  explorePagePath: string,
  pathname: string = window.location.pathname
): match<ExploreEventRouteParams> | null => {
  return matchPath<ExploreEventRouteParams>(pathname, routes.EXPLORE_EVENT(explorePagePath))
}

export const getHomeLocaleRouteMatch = (
  pathname: string = window.location.pathname
): match<HomeLocaleRouteParams> | null => {
  return matchPath<HomeLocaleRouteParams>(pathname, routes.HOME_LOCALE)
}

export const getDataProtectionRouteMatch = (
  pathname: string = window.location.pathname
): match<DataProtectionRouteParams> | null => {
  return matchPath<DataProtectionRouteParams>(pathname, routes.DATA_PROTECTION)
}

export const isDataProtectionRoute = (pathname: string = window.location.pathname): boolean => {
  return getDataProtectionRouteMatch(pathname) !== null
}
