import { MutableRefObject } from 'react'

export type BasicTarget<T = HTMLElement> =
  | (() => T | null)
  | T
  | null
  | MutableRefObject<T | null | undefined>

type TargetElement = HTMLElement | Element | Document | Window

export function getTargetElement(
  target?: BasicTarget<TargetElement>,
  defaultElement?: TargetElement
): TargetElement | undefined | null {
  if (!target) {
    return defaultElement
  }

  let targetElement: TargetElement | undefined | null

  if (typeof target === 'function') {
    targetElement = target()
  } else if ('current' in target) {
    targetElement = target.current
  } else {
    targetElement = target
  }

  return targetElement
}

export function nl2br(str: string | null | undefined): string | null | undefined {
  if (!str) {
    return str
  }

  return str.length ? str.replace(/(?:\r\n|\r|\n)/g, '<br>') : str
}

export const getRandomHEXColor = (): string => {
  const random = '0123456789abcdef'
    .split('')
    // eslint-disable-next-line func-names
    .map(function (v, i, a) {
      return i > 5 ? null : a[Math.floor(Math.random() * 16)]
    })
    .join('')

  return `#${random}`
}

export const lightOrDarkFromHEXColor = (hexColor: string): 'light' | 'dark' => {
  const color = hexColor.replace('#', '')
  const r = parseInt(color.substr(0, 2), 16)
  const g = parseInt(color.substr(2, 2), 16)
  const b = parseInt(color.substr(4, 2), 16)
  const yiq = (r * 299 + g * 587 + b * 114) / 1000
  return yiq >= 128 ? 'dark' : 'light'
}

export const isElementInViewport = (element: HTMLElement | null): boolean => {
  if (!element) return false
  const { top, left, height, width } = element.getBoundingClientRect()
  const verticalVisible = top <= window.innerHeight && top + height >= 0
  const horizontalVisible = left <= window.innerWidth && left + width >= 0
  return verticalVisible && horizontalVisible
}

export const isElementFullyInViewport = (element: HTMLElement): boolean => {
  const rect = element.getBoundingClientRect()
  const windowWidth = window.innerWidth || document.documentElement.clientWidth
  const windowHeight = window.innerHeight || document.documentElement.clientHeight
  return rect.top >= 0 && rect.left >= 0 && rect.bottom <= windowHeight && rect.right <= windowWidth
}

export const calculateElementSeenPercentage = (element: HTMLElement): number => {
  const rect = element.getBoundingClientRect()
  const windowHeight = window.innerHeight || document.documentElement.clientHeight
  const fraction = Math.max(0, Math.min(1, (windowHeight - rect.top) / rect.height))
  return Number(fraction.toFixed(2)) * 100
}

export const calculateElementVisibleHeight = (element: HTMLElement): number => {
  const rect = element.getBoundingClientRect()
  const windowHeight = window.innerHeight || document.documentElement.clientHeight

  if (rect.top > 0) {
    return Math.max(0, Math.min(rect.height, windowHeight - rect.top))
  }

  return Math.max(0, Math.min(rect.bottom, windowHeight))
}
