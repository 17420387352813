import { AnchorHTMLAttributes, forwardRef } from 'react'
import { Link } from 'react-router-dom'
import localizedTo from '../../utils/localizedTo'

type LocalizedLinkProps = AnchorHTMLAttributes<HTMLAnchorElement> & {
  to: string
}

export const LocalizedLink = forwardRef<HTMLAnchorElement, LocalizedLinkProps>(
  ({ to, children, ...anchorElementProps }, ref) => {
    return (
      <Link ref={ref} {...anchorElementProps} to={localizedTo(to)}>
        {children}
      </Link>
    )
  }
)

LocalizedLink.displayName = 'LocalizedLink' // Makes the component appear as 'LocalizedLink' in React Developer Tools

export default LocalizedLink
