import { AccessPolicy } from './AccessPolicy';
import { ImageAsset } from './Asset';
import { EventStage } from './EventStage';
import { BackgroundTheme, PageModule, VideoGridSettings, VideoTeaserCollectionSettings } from './PageModule';
import { ShareCTATarget } from './CTAButton';
import { Onboarding } from './Onboarding';
import { Event } from './Event';

export const nonVersionedPageTypes = ['LinkedPage'] as const;
export type NonVersionedPageType = (typeof nonVersionedPageTypes)[number];

export const pageTypes = ['StandardPage', 'StreamPage', 'ExplorePage', 'KeytopicsPage', 'BlankPage', 'AssetPage', 'ProfilePage', 'EmbeddedPage'] as const;
export type PageType = (typeof pageTypes)[number];

export const displayOption = ['everywhere', 'onlyApp', 'onlyWeb'] as const;
export type DisplayOption = (typeof displayOption)[number];

export interface Page {
  _id: string;
  default?: boolean;
  page: string;
  pageVersion: string;
  pageType: PageType;
  path: string;
  fullPath?: string;
  showInMenu: boolean;
  local: { [language: string]: PageLocal };
  readAccess: AccessPolicy[];
  parentPage?: Page;
  publishedAt?: string;
  previewImage: ImageAsset;
  sitemap?: boolean;
  showBackButton?: boolean;
  displayOption?: DisplayOption;
  onboarding?: Onboarding;
  contentChecklistActive?: boolean;
  domainCollection?: string;

  rootChildren?: BasePage[];
  children?: BasePage[];
  breadcrumbs?: BasePage[];
  showSubmenu?: boolean;
  showInSubmenu?: boolean;
}

export type BasePage = Pick<Page, '_id' | 'default' | 'pageType' | 'local' | 'path' | 'fullPath' | 'displayOption' | 'showInSubmenu'>;

export interface NonVersionedBasePage {
  _id: string;
  createdAt: string;
  createdBy: string;
  pageType: NonVersionedPageType;
  internalName: string;
  domainCollection?: string;
}

export interface PageVersion extends Page {
  page: string;
  finalizedAt: string;
  finalizedBy: string;
  publishedAt: string;
  publishedBy: string;
  dirty: boolean;
  change: number;
  forkedAt: string;
  forkedBy: string;
  modifiedAt: string;
  modifiedBy: string;
}

export interface NonVersionedPage extends NonVersionedBasePage {
  default?: boolean;
  path: string;
  fullPath?: string;
  parentPage?: string;
  showSubmenu?: boolean;
  showInSubmenu?: boolean;
}

export interface LinkedPage extends NonVersionedPage {
  pageType: 'LinkedPage';
  linkedPage: string;
}

export interface PageWithEventStage {
  eventStage: EventStage;
}

export interface PageWithPageModules {
  pageModules?: PageModule[];
}

export interface BlankPage extends Page, PageWithPageModules {
  pageType: 'BlankPage';
}

export const AssetScopeTypes = ['FromURL', 'SelectAsset'] as const;
export const AssetScopeTypeLabels = ['From URL', 'Select Asset'] as const;
export type AssetScopeType = (typeof AssetScopeTypes)[number];

export interface AssetPage extends Page, PageWithPageModules {
  pageType: 'AssetPage';
  assetScope: AssetScopeType;
  local: { [language: string]: PageLocal & { asset: string; recomVideo_loadMoreButtonLabel: string } };
  backgroundTheme: BackgroundTheme;
  showShareButton: boolean;
  shareButtonTargets: ShareCTATarget[];
  accessPolicy?: AccessPolicy[];
  paddingTop?: boolean;
  paddingBottom?: boolean;
  paddingLeft?: boolean;
  paddingRight?: boolean;
  videoGridSettings?: VideoGridSettings;
  recommendationsSettings?: VideoTeaserCollectionSettings;
  playerOverlayPicture: ImageAsset;
}

export interface ExplorePage extends Page, PageWithPageModules {
  pageType: 'ExplorePage';
  featuredEvents: Event[];
}

export interface StandardPage extends Page, PageWithPageModules, PageWithEventStage {
  pageType: 'StandardPage';
}

export interface StreamPage extends Page, PageWithPageModules, PageWithEventStage {
  pageType: 'StreamPage';
  path: 'stream';
}

export interface KeytopicsPage extends Page {
  path: 'keytopics';
  pageType: 'KeytopicsPage';
  stageBackgroundTheme?: BackgroundTheme;
}

export interface ProfilePage extends Page {
  pageType: 'ProfilePage';
  profileType: 'user' | 'speaker';
  showSummary: boolean;
  stageImage: ImageAsset;
  stageBackgroundTheme?: BackgroundTheme;
  // event?: string;
  // user?: string;
  // speaker?: string;
}

export interface EmbeddedPage extends Page, PageWithPageModules {
  pageType: 'EmbeddedPage';
}

export interface PageLocal {
  title: string;
  navigationTitle: string;
  displayOption: string;
  metaDescription?: string;

  linkTitle?: string;
  linkDescription?: string;
}

export interface EmbeddedContentLocal {}

export function isBlankPage(page: any): page is BlankPage {
  return page?.pageType === 'BlankPage';
}

export function isAssetPage(page: any): page is AssetPage {
  return page?.pageType === 'AssetPage';
}

export function isExplorePage(page: any): page is ExplorePage {
  return page?.pageType === 'ExplorePage';
}

export function isStandardPage(page: any): page is StandardPage {
  return page?.pageType === 'StandardPage';
}

export function isStreamPage(page: any): page is StreamPage {
  return page?.pageType === 'StreamPage';
}

export function isKeytopicsPage(page: any): page is KeytopicsPage {
  return page?.pageType === 'KeytopicsPage';
}

export function isProfilePage(page: any): page is ProfilePage {
  return page?.pageType === 'ProfilePage';
}

export function isEmbeddedPage(page: any): page is EmbeddedPage {
  return page?.pageType === 'EmbeddedPage';
}

export function isLinkedPage(page: BasePage | Page | NonVersionedPage): page is LinkedPage {
  return page?.pageType === 'LinkedPage';
}

export function isPageWithEventStage(page: any): page is PageWithEventStage {
  return page?.pageType === 'StandardPage' || page?.pageType === 'StreamPage';
}

export function isPageWithPageModules(page: any): page is PageWithPageModules {
  return isStandardPage(page) || isStreamPage(page) || isBlankPage(page) || isAssetPage(page) || isEmbeddedPage(page);
}
