import axios from 'axios'

type IpInfo = {
  ip: string
}
const getIpInfo = async (): Promise<IpInfo | null> => {
  try {
    const res = await axios.get('https://api.ipify.org/?format=json')
    return res.data as IpInfo
  } catch (error) {
    console.error(error)
  }
  return null
}

const getIPAddress = async (): Promise<string | null> => {
  const geoInfo = await getIpInfo()
  return geoInfo?.ip || null
}

export default getIPAddress()
