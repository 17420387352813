import create from 'zustand'
import { BeaconGroup, Map, Marker, Overlay } from '../../services/common/entities/Map'
import { createCombinedMarker } from '../../components/interactive-map/utils'
import { createLookupObject } from '../../utils/object'
import { detectAppViewMode } from '../../utils/app'

export enum ViewMode {
  App = 'app',
  Web = 'web',
}

export enum WalletStatus {
  CLOSED = 'CLOSED',
  OPEN = 'OPEN',
  CODE_ENTRY = 'CODE_ENTRY',
}

export enum NavigationFlow {
  MAIN = 'MAIN',
  WIDGET = 'WIDGET',
}

type BeaconGroupsLookup = {
  [key: string]: BeaconGroup
}

export interface AppState {
  isAppViewMode: boolean
  setIsAppViewMode: (isAppViewMode: boolean) => void
  isPullable: boolean
  setIsPullable: (isPullable: boolean) => void
  navigationFlow: NavigationFlow
  setNavigationFlow: (navigationFlow: NavigationFlow) => void
  hasBeaconPermission: boolean
  setHasBeaconPermission: (hasBeaconPermission: boolean) => void
  showQuickAccessBar: boolean
  setShowQuickAccessBar: (showQuickAccessBar: boolean) => void
  targetTicketId: string | null
  setTargetTicketId: (id: string | null) => void
  isWalletOpen: boolean
  walletStatus: WalletStatus
  setWalletStatus: (walletStatus: WalletStatus) => void
  openWallet: () => void
  closeWallet: () => void
  activeBeaconGroup: BeaconGroup | null
  map: Map | null
  markers: Marker[]
  overlays: Overlay[]
  beaconGroups: BeaconGroup[]
  beaconGroupsLookup: BeaconGroupsLookup
  setMap: (map: Map) => void
  setActiveBeaconGroup: (beaconGroupId: string | null) => void
}

const initalIsAppViewMode = detectAppViewMode()

export const useAppStore = create<AppState>((set) => ({
  isAppViewMode: initalIsAppViewMode,
  setIsAppViewMode: (isAppViewMode) => set({ isAppViewMode }),
  isPullable: true,
  setIsPullable: (isPullable) => set({ isPullable }),
  navigationFlow: NavigationFlow.MAIN,
  setNavigationFlow: (navigationFlow) => set({ navigationFlow }),
  hasBeaconPermission: false,
  setHasBeaconPermission: (hasBeaconPermission) => set({ hasBeaconPermission }),
  showQuickAccessBar: initalIsAppViewMode,
  setShowQuickAccessBar: (showQuickAccessBar) => set({ showQuickAccessBar }),
  targetTicketId: null,
  setTargetTicketId: (targetTicketId) => set({ targetTicketId }),
  isWalletOpen: false,
  walletStatus: WalletStatus.CLOSED,
  setWalletStatus: (walletStatus) => set({ walletStatus }),
  openWallet: () => set({ isWalletOpen: true, walletStatus: WalletStatus.OPEN }),
  closeWallet: () => set({ isWalletOpen: false, walletStatus: WalletStatus.CLOSED }),
  activeBeaconGroup: null,
  map: null,
  markers: [],
  overlays: [],
  beaconGroups: [],
  beaconGroupsLookup: {},
  setMap: (map: Map) => {
    const [submap] = map.submaps
    const markers = submap.markers.map((marker) => createCombinedMarker(marker, map))

    set({
      map,
      markers,
      overlays: map.overlays,
      beaconGroups: map.beaconGroups,
      beaconGroupsLookup: createLookupObject(map.beaconGroups, '_id'),
    })
  },
  setActiveBeaconGroup: (beaconGroupId) => {
    if (!beaconGroupId) {
      set({ activeBeaconGroup: null })
    } else {
      set((state) => ({
        activeBeaconGroup: state.beaconGroupsLookup[beaconGroupId] ?? null,
      }))
    }
  },
}))
