import supportImgLazyAttr from '../utils/supportLazy'

const getVendorPath = (scripPath: string): string => {
  return `${process.env.PUBLIC_URL}/js/${scripPath}`
}

/**
 * Script loader
 * @param scriptName
 * @param scriptPath
 */
export const loadScript = (scriptName: string, scriptPath: string): void => {
  const script = document.createElement('script')

  script.onload = () => document.body.classList.add(scriptName)
  script.onerror = () => document.body.classList.add(`${scriptName}-error`)
  script.src = getVendorPath(scriptPath)
  document.head.appendChild(script)
}

/**
 * Responsive image support (picture and/or srcset) for legacy browsers
 * Lazysizes is built upon the Responsive image standard and extends it with additional functionality.
 *
 * @see: https://github.com/aFarkas/lazysizes
 */
export const importLazySizes = (): void => {
  // not native here?
  if (supportImgLazyAttr === false) {
    loadScript('lazysizes', 'lazysizes-v530.min.js')
  }
}

// auto runner
export function vendorScriptsLoader(): void {
  importLazySizes()
}
