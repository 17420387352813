const getPathLocales = (): string | null => {
  // Since pathname always starts with '/', the locale will be at index 1 after splitting.
  const locale = window.location.pathname.split('/')[1]

  if (!locale) {
    return null
  }

  const hasCountryIsoCode = locale.includes('_')

  if (hasCountryIsoCode) {
    return locale.split('_')[0]
  }

  return locale
}

export default getPathLocales
