import * as H from 'history'
import { Option } from '../components/form-elements/select/Option'

/**
 * Get value for given query string of a URL
 *
 * @param {string} parameterName
 * @returns {string | null}
 */
export const findGetParameter = (parameterName: string): string | null => {
  const parts = document.location.search.substr(1).split('&')
  let result = null

  for (let i = 0; i < parts.length; i += 1) {
    const part = parts[i].split('=')
    const [param, value] = part

    if (value && value.length < 125 && param === parameterName) {
      result = decodeURIComponent(value)
      break
    }
  }

  return result
}

export const addQueryParam = (search: string, paramName: string, paramValue: string): string => {
  const params = new URLSearchParams(search)
  params.set(paramName, paramValue)
  return params.toString()
}

export const removeQueryParam = (search: string, paramName: string): string => {
  const params = new URLSearchParams(search)
  params.delete(paramName)
  return params.toString()
}

export const removeFragment = (url: string): string => {
  return url.split('#')[0]
}

/**
 * Check if given URL is absolute.
 * @param url
 * @returns {boolean}
 */
export const isAbsoluteUrl = (url = '/'): boolean => {
  return /^https?:\/\//i.test(url)
}

export const isJavascript = (url = ''): boolean => {
  // eslint-disable-next-line no-script-url
  return url.startsWith('javascript:')
}

export const isEmailUrl = (url: string): boolean => {
  return url.startsWith('mailto:')
}

export const isExternalUrl = (url = ''): boolean => {
  if (!url) {
    return false
  }

  if (isEmailUrl(url)) {
    return true
  }

  return isAbsoluteUrl(url) && !url.startsWith(window.location.origin)
}

export const isHashLink = (url: string): boolean => {
  return url.startsWith('#') || url.indexOf('#') > -1
}

/**
 * Make sure that there is no accidental double slash "//" when concatinating URL path.
 * @param leftSide part of the URL
 * @param rightSide part of the URL
 * @returns {string} result of concatination
 */
export const concatUrlPath = (leftSide: string, rightSide: string): string => {
  const leftSideSlash = leftSide.endsWith('/')
  const rightSideSlash = rightSide.startsWith('/')

  if ((leftSideSlash && !rightSideSlash) || (!leftSideSlash && rightSideSlash)) {
    return leftSide + rightSide
  }

  if (leftSideSlash && rightSideSlash) {
    return `${leftSide}${rightSide.slice(1)}`
  }

  return `${leftSide}/${rightSide}`
}

/**
 * Creates absolute URL using current origin from given path
 */
export const createAbsoluteUrl = (path = ''): string => {
  return isAbsoluteUrl(path) ? path : concatUrlPath(window.location.origin, path)
}

/**
 * ensures that relative link has language prefix and has been properly concatenated
 */
export const checkRelativeUrl = (path = '', lang: string): string => {
  let pathname = path
  if (pathname.startsWith('?')) return pathname
  if (!pathname.startsWith('/')) {
    pathname = `${window.location.pathname}/${pathname}`
  }
  if (window.location.search) {
    pathname = `${pathname}${window.location.search}`
  }
  if (!urlHasLanguagePrefix(pathname)) {
    pathname = `/${lang}${pathname}`
  }
  return pathname
}

/**
 * Possible types of query filters for FilterPageModule
 */
export enum URLQueryFilters {
  COUNTRIES = 'countries',
  EVENT_TYPES = 'event-types',
  KEYTOPICS = 'keytopics',
  LANGUAGES = 'languages',
  PAGE = 'page',
}

export const createFilterSearchParams = (
  key: URLQueryFilters,
  urlQuery: URLSearchParams,
  options?: readonly Option[] | Option
): URLSearchParams => {
  urlQuery.delete(key)
  urlQuery.delete(URLQueryFilters.PAGE)

  if (Array.isArray(options)) {
    options.forEach((option) => {
      urlQuery.append(key, option.value)
    })
  } else if (options) {
    urlQuery.append(key, options.value)
  }
  return urlQuery
}

/**
 * Adds search parameters to URL
 *
 * @param key
 * @param options
 * @param urlQuery
 * @param history
 */
export const setMultipleSearchParameters = (
  key: URLQueryFilters,
  urlQuery: URLSearchParams,
  history: H.History,
  options?: readonly Option[] | Option
): void => {
  const urlQueryString = createFilterSearchParams(key, urlQuery, options).toString()
  history.push(`?${urlQueryString}`)
}

/**
 * Checks whether the current pathname starts with a two-letter language code (upper or lower case), followed by a forward slash.
 * @returns {boolean} true if the current pathname has a language prefix, false otherwise.
 */
export const hasLanguagePrefix = (): boolean => {
  const languagePrefixRegex = /^\/[a-zA-Z]{2}\//
  return languagePrefixRegex.test(window.location.pathname)
}
export const urlHasLanguagePrefix = (pathname: string): boolean => {
  const languagePrefixRegex = /^\/[a-zA-Z]{2}\//
  return languagePrefixRegex.test(pathname)
}

/**
 * Gets the two-letter language code (upper or lower case) from the beginning of the current pathname, if present.
 * @returns {string | null} the language code, or null if not found.
 */
export const getLanguageFromUrl = (): string | null => {
  const languagePrefixRegex = /^\/([a-zA-Z]{2})\//
  const match = languagePrefixRegex.exec(window.location.pathname)

  if (!match) {
    return null
  }

  return match[1]
}

/**
 * Converts an object to a URL query string parameter
 * @param object The object to convert to a query parameter
 * @returns The encoded query parameter string
 */
export const objectToQueryParam = (object: any = {}): string => {
  return encodeURIComponent(JSON.stringify(object))
}

/**
 * Parses a query parameter string into an object of type T.
 * @template T - The expected type of the parsed object.
 * @param {string} queryParam - The query parameter string to parse.
 * @returns The parsed object of type T, or null if parsing fails.
 * @throws This function does not throw any exceptions.
 */
export const queryParamToObject = <T>(queryParam: string): T | null => {
  try {
    const decoded = decodeURIComponent(queryParam)
    const parsed = JSON.parse(decoded)

    if (typeof parsed === 'object') {
      return parsed as T
    }

    return null
  } catch (error) {
    return null
  }
}

export const buildUrlWithQueryParams = (
  link = '',
  queries: Record<string, string> = {}
): string => {
  const url = new URL(createAbsoluteUrl(link))

  Object.entries(queries).forEach(([key, value]) => {
    const currentValue = url.searchParams.get(key)

    if (!currentValue || currentValue !== value) {
      url.searchParams.append(key, value)
    }
  })

  if (isExternalUrl(link)) {
    return url.toString()
  }

  return url.pathname + url.search
}

export const createLocaleString = (language: string, countryCode: string): string => {
  return `${language}_${countryCode.toUpperCase()}`
}
