import {
  LinkMarkerAction,
  Map,
  Marker,
  MarkerAction,
  OverlayMarkerAction,
  SubmapMarkerAction,
} from '../../services/common/entities/Map'
import { CombinedMarker, KioskModeLayout } from './types'

export const createCombinedMarker = (marker: Marker, map: Map): CombinedMarker => {
  return {
    ...marker,
    icon: map.markerIcons.find((markerIcon) => markerIcon._id === marker.markerIcon),
    group: map.markerGroups.find((markerGroup) => markerGroup._id === marker.markerGroup),
  } as CombinedMarker
}

export const extractDataURI = (inlineSVG: string): string => {
  return decodeURIComponent(inlineSVG).replace('data:image/svg+xml,', '')
}

export const isLinkAction = (action: MarkerAction): action is LinkMarkerAction => {
  return action.markerActionType === 'Link'
}

export const isOverlayAction = (action: MarkerAction): action is OverlayMarkerAction => {
  return action.markerActionType === 'Overlay' || action.markerActionType === 'FullscreenDialog'
}

export const isSubmapAction = (action: MarkerAction): action is SubmapMarkerAction => {
  return action.markerActionType === 'Submap'
}

export const getKioskModeLayout = (
  hasLeftGroup: boolean,
  hasRightGroup: boolean
): KioskModeLayout => {
  if (hasLeftGroup && hasRightGroup) {
    return 'full'
  }

  if (hasLeftGroup) {
    return 'left'
  }

  if (hasRightGroup) {
    return 'right'
  }

  return 'empty'
}
