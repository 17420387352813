import { BehaviorSubject, Observable } from 'rxjs'
import { createBrowserHistory, History } from 'history'
import { useAppStore, ViewMode } from 'app/store/app-store'
import appConfiguration from 'app/config/app'
import Service from './Service'
import { SingleEvent } from '../../services/common/entities/configuration/PlatformConfiguration'

class LocationService extends Service {
  private static locationSubject: BehaviorSubject<URL> = new BehaviorSubject<URL>(new URL(document.URL))

  private static browserHistory = createBrowserHistory()

  private static isParentLoginEnabled = false

  public static urlChanged(url: string): void {
    return LocationService.locationSubject.next(new URL(url))
  }

  public static url(): Observable<URL> {
    return LocationService.locationSubject.asObservable()
  }

  public static currentUrl(): URL {
    return LocationService.locationSubject.getValue()
  }

  public static history(): History<History.LocationState> {
    return LocationService.browserHistory
  }

  public static navigate(path: string | null, queryParams?: { [key: string]: any }, queryParamsMode: 'merge' | 'set' = 'merge'): void {
    const navigationTarget = LocationService.navigateString(path, queryParams, queryParamsMode)

    if (navigationTarget.startsWith('/')) {
      const future = new URL(navigationTarget, LocationService.currentUrl())
      const current = LocationService.currentUrl()

      if (future.toString() !== current.toString()) {
        LocationService.history()?.push(navigationTarget)
      }
    } else {
      LocationService.history()?.push(navigationTarget)
    }
  }

  public static navigateLocale(locale: string, replaceLang: boolean, pathname: string, singleEvent?: string | SingleEvent): void {
    let currentPath = pathname
    if(replaceLang) {
      currentPath = currentPath.slice(currentPath.indexOf('/', 1))
    }

    let path = `/${locale}${currentPath}`
    if (currentPath === '/' && !!singleEvent && typeof singleEvent === 'object') {
      path += `events/${singleEvent?.shortId}`
    }

    const navigationTarget = LocationService.navigateString(path, LocationService.currentQueryParams({includeHash:true}),'set')
    LocationService.history()?.push(navigationTarget)
    localStorage.removeItem('365Platform_localeRedirect')
  }

  public static navigateString(path: string | null, queryParams?: { [key: string]: any }, queryParamsMode: 'merge' | 'set' = 'merge'): string {
    const newUrl = LocationService.navigateURL(path, queryParams, queryParamsMode)
    return `${newUrl.pathname}${newUrl.search}${newUrl.hash}`
  }

  public static navigateURL(path: string | null, queryParams?: { [key: string]: any }, queryParamsMode: 'merge' | 'set' = 'merge'): URL {
    const newPath = path || ''
    const newUrl = new URL(newPath, LocationService.currentUrl())

    if (queryParamsMode === 'set') {
      LocationService.currentUrl().searchParams.forEach((value, key) => {
        newUrl.searchParams.delete(key)
      })
    } else if (queryParamsMode === 'merge') {
      LocationService.currentUrl().searchParams.forEach((value, key) => {
        newUrl.searchParams.set(key, value)
      })
    }

    if (queryParams) {
      Object.keys(queryParams).forEach((key: string) =>{
        if(key === 'hash') {
          newUrl.hash = queryParams.hash
        } else {
          newUrl.searchParams.set(key, queryParams[key])
        }
      })
    }

    return newUrl
  }

  static currentQueryParams(opts?: { without?: string[], only?: string[], includeHash?: boolean }): { [key: string]: string } {
    const queryParams: { [key: string]: string } = {}

    LocationService.currentUrl().searchParams.forEach((val, key) => {
      if (opts?.without?.includes(key)) {
        return
      }
      if (!opts?.only || opts.only.includes(key)) {
        queryParams[key] = val
      }
    })

    if(LocationService.currentUrl().hash !== '' && opts?.includeHash) {
      queryParams.hash = LocationService.currentUrl().hash
    }

    return queryParams
  }

  static removeQueryParam(param: string | string[]) : void{
    const newUrl = new URL('', LocationService.currentUrl())
    const params = Array.isArray(param) ? param : [param]
    const hasAnyTargetParam = params.some((p) => newUrl.searchParams.has(p))

    if (!hasAnyTargetParam) {
      return
    }

    LocationService.currentUrl().searchParams.forEach((value, key) => {
      newUrl.searchParams.set(key, value)
    })

    params.forEach((p: string) => {
      newUrl.searchParams.delete(p)
    })

    LocationService.history()?.replace(`${newUrl.pathname}${newUrl.search}${newUrl.hash}`)
  }

  public static useParentLogin(): boolean {
    return LocationService.isParentLoginEnabled
  }

  private static setUseParentLogin(value: boolean): void {
    LocationService.isParentLoginEnabled = value
  }

  static async init(): Promise<void> {
    LocationService.browserHistory.listen(() => {
      const newURL = new URL(document.URL)
      const currentURL = LocationService.currentUrl()

      if (!!newURL && !!currentURL && newURL.toString() !== currentURL.toString()) {
        LocationService.locationSubject.next(newURL)
      }
    })

    if (LocationService.currentQueryParams()[appConfiguration.urlParamName] === appConfiguration.urlParamValue) {
      localStorage.setItem('viewMode', ViewMode.App)
      useAppStore.setState({ isAppViewMode: true })
    } else {
      // localStorage.setItem('viewMode', ViewMode.Web)
    }

    LocationService.setUseParentLogin(LocationService.currentQueryParams().useParentLogin === 'true')
  }

  public static localeInPath(path: string): boolean {
    return path.substring(0,6).match(/\/\w{2}_\w{2}/) !== null
  }

}

export default LocationService
