import { cancelable, CancelablePromiseType } from 'cancelable-promise'

import websocketInstance from './ws'
import { Ping } from './common/api/v1/websocket/Ping'
import { Pong } from './common/api/v1/websocket/Pong'
import { PostServerTimeResponse } from './common/api/v1/configuration/PostServerTime'
import http from './api'

const timeParamUsable: boolean =
  !process.env.NODE_ENV ||
  process.env.NODE_ENV !== 'production' ||
  window.location.hostname.indexOf('dev') > -1 ||
  window.location.hostname.indexOf('qa') > -1

export type ServerTime = {
  diff: number
} & Pong

export function getServerTime(): CancelablePromiseType<any> {
  const pingData: Ping = { clientDate: new Date().toISOString() }
  const qs = window.location.search.substring(1)
  const params = new URLSearchParams(qs)
  const previewTime = timeParamUsable && params.get('time')

  const promise = new Promise((resolve) => {
    if (previewTime) {
      const previewDate = new Date(parseInt(previewTime, 10)).toISOString()

      resolve({
        diff: 0,
        clientDate: previewDate,
        serverDate: previewDate,
      })
    } else {
      let resolved = false

      const processResult = (data: {
        clientDate: Date | string
        serverDate: Date | string
      }): void => {
        if (!resolved) {
          resolved = true
          const roundTripTime = new Date().getTime() - new Date(data.clientDate).getTime()
          const latency = roundTripTime / 2
          const diff =
            new Date(data.serverDate).getTime() - new Date(data.clientDate).getTime() - latency

          websocketInstance.removeEventListeners('pong')
          resolve({ diff, ...data })
        }
      }

      websocketInstance.on<Pong>('pong', processResult)
      websocketInstance.emit<Ping>('ping', pingData)
      http
        .post('/configuration/servertime', pingData)
        .then((d) => d.data as PostServerTimeResponse)
        .then(processResult)
    }
  })

  return cancelable(promise)
}
