import { FC } from 'react'
import { createPluginStore, PluginProvider } from 'react-pluggable'
import { AppPluginStore } from './types'
import Plugin from './Plugin'
import ExternalConsentPlugin from './external-consent/ExternalConsent'
import GtmPlugin from './gtm/GtmPlugin'
import GtmPlugin2 from './gtm/GtmPlugin2'
import UsercentricsPlugin from './usercentrics/UsercentricsPlugin'
import UsercentricsPluginV2 from './usercentrics-v2/UsercentricsV2Plugin'
import isDev from '../../utils/isDev'
import SourcepointPlugin from './sourcepoint/SourcepointPlugin'
import FacebookPixelPlugin from './facebook-pixel/FacebookPixelPlugin'
import DmgMoriCoreNavPlugin from './dmg-mori-core-nav/DmgMoriCoreNavPlugin'

export const appPluginStore: AppPluginStore = createPluginStore()

// Usercentrics plugin has to before GTM plugin
// see: https://usercentrics.atlassian.net/wiki/spaces/SKB/pages/605716518/How+do+I+configure+the+Google+Tag+Manager+script+and+Usercentrics+in+a+GDPR-compliant+manner+CMP+Version+1
const plugins: Plugin[] = [
  ExternalConsentPlugin,
  UsercentricsPlugin,
  UsercentricsPluginV2,
  GtmPlugin,
  GtmPlugin2,
  SourcepointPlugin,
  FacebookPixelPlugin,
  DmgMoriCoreNavPlugin,
]

// use debug mode?
if (isDev) (window as any).debug = true

// install available plugins
export const pluginsReady = (): Promise<void> => {
  return plugins.reduce(async (a, b) => {
    await a
    if (b.shouldInstall()) {
      appPluginStore.install(b)
      return b.isReady()
    }
    return Promise.resolve()
  }, Promise.resolve())
}

const AppPluginProvider: FC = ({ children }) => (
  <PluginProvider pluginStore={appPluginStore}>{children}</PluginProvider>
)

export default AppPluginProvider
