import axios, { AxiosResponse, AxiosError } from 'axios'
import { ErrorInterceptor } from '../../types/api'
import { setPreviewToken } from '../../utils/preview'
import { anonymousAccessPass } from '../../app/config/routes'
import isDev from '../../utils/isDev'

function errorInterceptor(error: AxiosError): ErrorInterceptor {
  const {
    response,
    request,
  }: {
    response?: AxiosResponse
    request?: XMLHttpRequest
  } = error

  if (axios.isCancel(error)) {
    if (isDev) console.warn('Request canceled:', error.message)
    return response
  }

  // invalid status attempt handler
  if (response !== undefined) {
    const { config } = response
    const url = config.url ? config.url : ''

    if (response.status >= 400 && response.status < 500) {
      if (response.status === 404) {
        if (isDev) console.error(`API URL '${config.url}' not found!`)
      } else if ((response.status >= 400 && response.status <= 403) || response.status === 409) {
        // auth actions?
        if (anonymousAccessPass(url)) {
          if (isDev) console.warn(request)
          return response
        }

        // remove preview
        setPreviewToken()
      }
    }
  }

  return Promise.reject(error)
}

export default errorInterceptor
