import { Mode } from 'fs'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import {
  isLiveSessionMedia,
  isReLiveSessionMedia,
  isSessionWithSessionMedia,
  isVodSessionMedia,
  Session,
} from '../../services/common/entities/Session'
import { Stream } from '../../services/common/entities/Stream'
import { isFutureSession, isLiveSession, isPastSession } from '../utils/session'
import LanguageService from './Language'
import ServerTimeService from './ServerTime'
import Service from './Service'
import { VideoAsset } from '../../services/common/entities/Asset'

export type SlideAsset = {
  videoAsset: VideoAsset | undefined
  session?: Session
}

class SessionHelperService extends Service {

  public static isPastSession(session: Session): Observable<boolean> {
    return ServerTimeService.get()
      .serverTime()
      .pipe(map((serverTime) => isPastSession(serverTime, session)))
  }

  public static isLiveSession(session: Session): Observable<boolean> {
    return ServerTimeService.get()
      .serverTime()
      .pipe(map((serverTime) => isLiveSession(serverTime, session)))
  }

  public static isFutureSession(session: Session): Observable<boolean> {
    return ServerTimeService.get()
      .serverTime()
      .pipe(map((serverTime) => isFutureSession(serverTime, session)))
  }

  public static asset(session: Session): SlideAsset | undefined {
    if (isSessionWithSessionMedia(session)) {
      const sessionMedia = session.sessionMedia.current

      if (isVodSessionMedia(sessionMedia) || isReLiveSessionMedia(sessionMedia) || isLiveSessionMedia(sessionMedia)) {
        return {
          videoAsset: sessionMedia.asset,
          session
        }
      }
    }

    return undefined
  }

  public static stream(session: Session): Stream | undefined {
    if (isSessionWithSessionMedia(session)) {
      const sessionMedia = session.sessionMedia.current

      if (isLiveSessionMedia(sessionMedia)) {
        return sessionMedia?.stream
      }
    }

    return undefined
  }

  public static streamingUrl(session: Session, mode: Mode): string | undefined {
    if (isSessionWithSessionMedia(session)) {
      const language = LanguageService.currentLanguage()
      const sessionMedia = session.sessionMedia[language.language]

      if (isLiveSessionMedia(sessionMedia)) {
        const newStreamingUrls =
          (mode === 'live'
            ? sessionMedia?.stream?.streamingUrls
            : sessionMedia?.asset?.streamingUrls) || []
        return newStreamingUrls[0]
      }

      if (isVodSessionMedia(sessionMedia) || isReLiveSessionMedia(sessionMedia)) {
        const newStreamingUrls = sessionMedia?.asset?.streamingUrls || []
        return newStreamingUrls[0]
      }
    }

    return undefined
  }

  public static eventId(session: Session): string {
    if (typeof session.event === 'string') {
      return session.event
    }

    return session.event._id
  }

  public static eventTypeId(session: Session): string | undefined {
    if (typeof session.event === 'string') {
      return undefined
    }

    return session.event.eventType
  }
}

export default SessionHelperService
