declare global {
  interface Window {
    usercentrics: any
  }
}

// Portal uses locale instead of language, so we need a mapping here
export const localeToLanguageMapping: Record<string, string> = {
  jp: 'ja',
  us: 'en',
  cn: 'zh',
  cz: 'cs',
  kr: 'ko',
}

const ucLanguageSwitch = (locale: string): void => {
  const { usercentrics } = window
  const language = localeToLanguageMapping[locale] || locale

  if (usercentrics && 'updateLanguage' in usercentrics && usercentrics.isInitialized) {
    usercentrics.updateLanguage(language)
  }
}

export default ucLanguageSwitch
