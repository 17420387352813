export const accessPolicyTypes = ['Public', 'Platform', 'Event', 'EventTicket', 'GlobalTicket', 'NoTicket', 'VIPTicket', 'Tags'] as const;
export type AccessPolicyType = (typeof accessPolicyTypes)[number];

export interface AccessPolicy {
  _id: string;
  accessPolicyType: AccessPolicyType;
}

export interface PublicAccessPolicy extends AccessPolicy {
  accessPolicyType: 'Public';
}

export interface PlatformAccessPolicy extends AccessPolicy {
  accessPolicyType: 'Platform';
  inverted?: boolean;
}

export interface EventAccessPolicy extends AccessPolicy {
  accessPolicyType: 'Event';
  event: string;
}

export interface EventTicketAccessPolicy extends AccessPolicy {
  accessPolicyType: 'EventTicket';
  event: string;
  eventTicket: string;
}

export interface GlobalTicketAccessPolicy extends AccessPolicy {
  accessPolicyType: 'GlobalTicket';
  globalTicket: string;
}

export interface VIPTicketAccessPolicy extends AccessPolicy {
  accessPolicyType: 'VIPTicket';
  event: string;
  eventTicket: string;
}

export interface NoTicketAccessPolicy extends AccessPolicy {
  accessPolicyType: 'NoTicket';
  event: string;
  loggedIn: boolean;
}

export interface TagsAccessPolicy extends AccessPolicy {
  accessPolicyType: 'Tags';
  negatePolicy?: boolean;
  tags: string[];
}

export interface AccessPolicyContext {
  loggedIn?: boolean;
  eventTickets?: string[];
  vipTickets?: string[];
  globalTickets?: string[];
  userTags?: string[];
}

export interface AccessPolicyKey extends AccessPolicyContext {
  key: string;
  base64?: string;
}

export function isPublicAccessPolicy(object: any): object is PublicAccessPolicy {
  return object.accessPolicyType === 'Public';
}

export function isPlatformAccessPolicy(object: any): object is PlatformAccessPolicy {
  return object.accessPolicyType === 'Platform';
}

export function isEventAccessPolicy(object: any): object is EventAccessPolicy {
  return object.accessPolicyType === 'Event';
}

export function isEventTicketAccessPolicy(object: any): object is EventTicketAccessPolicy {
  return object.accessPolicyType === 'EventTicket';
}

export function isGlobalTicketAccessPolicy(object: any): object is GlobalTicketAccessPolicy {
  return object.accessPolicyType === 'GlobalTicket';
}

export function isVIPTicketAccessPolicy(object: any): object is VIPTicketAccessPolicy {
  return object.accessPolicyType === 'VIPTicket';
}

export function isTagsAccessPolicy(object: any): object is TagsAccessPolicy {
  return object.accessPolicyType === 'Tags';
}

export function isNoTicketAccessPolicy(object: any): object is NoTicketAccessPolicy {
  return object.accessPolicyType === 'NoTicket';
}
