import dayjs from 'dayjs'

const formatter = (value: string | Date, format: string | undefined): string => {
  if (value instanceof Date) {
    if (format) {
      return dayjs(value).format(format)
    }
    return value.toLocaleTimeString()
  }
  return value
}

export default formatter
