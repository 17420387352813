import { useEffect, useState } from 'react'
import LoadingScreen from '../components/loading-screen/LoadingScreen'
import servicesPromises from './services/services'

export enum AppSetupState {
  Loading = 'loading',
  Ready = 'ready',
  Error = 'error',
}

type AppSetupProps = {
  children: JSX.Element
}

const AppSetup = ({ children }: AppSetupProps): JSX.Element => {
  const [state, setState] = useState(AppSetupState.Loading)

  useEffect(() => {
    servicesPromises
      .then(() => {
        setState(AppSetupState.Ready)
      })
      .catch(() => {
        setState(AppSetupState.Error)
      })
  }, [])

  if (state === AppSetupState.Loading) {
    return <LoadingScreen />
  }

  if (state === AppSetupState.Error) {
    return <LoadingScreen error={Error('Loading error')} />
  }

  return children
}

export default AppSetup
