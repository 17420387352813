import { getUcConsent } from 'app/plugins/usercentrics-v2/UsercentricsV2Plugin'
import { Beacon } from './common/entities/Map'

interface RegisterBeaconsConfig {
  searchTimer?: number
  refreshInterval?: number
}

interface WebkitMessageHandlers {
  iOSMessageHandler: {
    postMessage: (message: any) => void
  }
  iOSMessageHandlerWithReply: {
    postMessage: <R>(message: any) => Promise<R>
  }
}

declare global {
  interface Window {
    webkit?: {
      messageHandlers: WebkitMessageHandlers
    }
    JSAndroidBridge?: {
      onAppReady: () => void
      registerBeacons: (json: string, config: string) => void
      appLoadQRScan: (data?: string) => void
      openInAppBrowser: (data?: string) => void
      requestBeaconPermission: (data?: string) => void
      hasBeaconPermission: () => boolean
      requestNotificationPermission: () => void
    }
  }
}

export function signalAppReady(): void {
  try {
    const message = {
      name: 'onAppReady',
    }

    window.webkit?.messageHandlers.iOSMessageHandler.postMessage(message)
  } catch (error) {
    console.warn('Error notifying iOS that app is ready:', error)
  }

  try {
    window.JSAndroidBridge?.onAppReady()
  } catch (error) {
    console.warn('Error notifying Android that app is ready:', error)
  }
}

export async function checkBeaconPermission(): Promise<boolean> {
  try {
    const iosPermission = await window.webkit?.messageHandlers.iOSMessageHandlerWithReply.postMessage<boolean>(
      {
        name: 'hasBeaconPermission',
      }
    )

    if (typeof iosPermission === 'boolean') {
      return iosPermission
    }
  } catch (error) {
    console.warn(error)
  }

  try {
    const androidPermission = window.JSAndroidBridge?.hasBeaconPermission()

    if (typeof androidPermission === 'boolean') {
      return androidPermission
    }
  } catch (error) {
    console.warn(error)
  }

  return false
}

export function registerBeacons(beacons: Beacon[], config: RegisterBeaconsConfig): void {
  const beaconsJson = JSON.stringify(beacons)
  const configJson = JSON.stringify(config)

  try {
    window.webkit?.messageHandlers.iOSMessageHandler.postMessage({
      name: 'registerBeacons',
      more: beaconsJson,
      config: configJson,
    })
  } catch (error) {
    console.warn(error)
  }

  try {
    window.JSAndroidBridge?.registerBeacons(beaconsJson, configJson)
  } catch (error) {
    console.warn(error)
  }
}

export function openQRScan(data?: string): void {
  const dataTrimmed = data?.trim()
  try {
    const message = dataTrimmed
      ? { name: 'appLoadQrScan', more: dataTrimmed }
      : { name: 'appLoadQrScan' }

    window.webkit?.messageHandlers.iOSMessageHandler.postMessage(message)
  } catch (error) {
    console.warn(error)
  }
  try {
    if (dataTrimmed) {
      window.JSAndroidBridge?.appLoadQRScan(dataTrimmed)
    } else {
      window.JSAndroidBridge?.appLoadQRScan()
    }
  } catch (error) {
    console.warn(error)
  }
}

export function openInAppLink(data?: string): void {
  const dataTrimmed = data?.trim()

  try {
    const message = dataTrimmed
      ? { name: 'openInAppBrowser', more: dataTrimmed }
      : { name: 'openInAppBrowser' }
    window.webkit?.messageHandlers.iOSMessageHandler.postMessage(message)
  } catch (error) {
    console.warn(error)
  }

  try {
    if (dataTrimmed) {
      window.JSAndroidBridge?.openInAppBrowser(dataTrimmed)
    } else {
      window.JSAndroidBridge?.openInAppBrowser()
    }
  } catch (error) {
    console.warn(error)
  }
}

export function requestLocationRights(data?: string): void {
  const dataTrimmed = data?.trim()
  const hasAnalyticsConsent = getUcConsent().toString()

  try {
    const message = dataTrimmed
      ? { name: 'requestBeaconPermission', hasAnalyticsConsent, more: dataTrimmed }
      : { name: 'requestBeaconPermission', hasAnalyticsConsent }

    window.webkit?.messageHandlers.iOSMessageHandler.postMessage(message)
  } catch (error) {
    console.warn(error)
  }

  try {
    if (dataTrimmed) {
      window.JSAndroidBridge?.requestBeaconPermission(dataTrimmed)
    } else {
      window.JSAndroidBridge?.requestBeaconPermission()
    }
  } catch (error) {
    console.warn(error)
  }
}

export function requestTrackingPermission(hasAnalyticsConsent: boolean): void {
  if (!hasAnalyticsConsent) {
    return
  }

  try {
    const message = {
      name: 'requestTrackingPermission',
    }

    window.webkit?.messageHandlers.iOSMessageHandler.postMessage(message)
  } catch (error) {
    console.warn(error)
  }
}

export function requestNotificationPermission(): void {
  try {
    const message = {
      name: 'requestNotificationPermission',
    }

    window.webkit?.messageHandlers.iOSMessageHandler.postMessage(message)
  } catch (error) {
    console.warn(error)
  }

  try {
    window.JSAndroidBridge?.requestNotificationPermission()
  } catch (error) {
    console.warn(error)
  }

  // Set a flag in localStorage to track that permission has been requested
  localStorage.setItem('notificationPermissionRequested', 'true')
}
