import { AxiosInstance } from 'axios'
import LayoutEditorService from '../../app/services/LayoutEditor'
import { addListener } from '../../app/store/user-store'
import Websocket from '../ws'

let lastToken: string | undefined

// add / remove token from user state
const initAuthHeader = (api: AxiosInstance): void => {
  addListener((state) => {
    if (state.token !== lastToken && !LayoutEditorService.currentLayoutEditorMode()) {
      if (state.token) {
        // eslint-disable-next-line no-param-reassign
        api.defaults.headers.common.Authorization = `Bearer ${state.token}`
        Websocket.reconnect(state.token)
      } else {
        // eslint-disable-next-line no-param-reassign
        delete api.defaults.headers.common.Authorization
        Websocket.reconnect()
      }
    }
    lastToken = state.token
  })
}

export default initAuthHeader
