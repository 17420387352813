import isDev from '../utils/isDev'

const CACHE_BOOST_LC = 'CB365Platform'
const rootEl = document.getElementById('root') as HTMLElement
const cacheBoost = rootEl?.dataset.cb
const lastCacheBoost = localStorage.getItem(CACHE_BOOST_LC)

if (cacheBoost !== lastCacheBoost) {
  localStorage.setItem(CACHE_BOOST_LC, `${cacheBoost}`)

  // eslint-disable-next-line array-callback-return
  Object.keys(localStorage).map((key) => {
    if (key.startsWith('365Platform') && !key.startsWith('365Platform_me')) {
      localStorage.removeItem(key)
      if (isDev) console.warn(`Cache ${key} deleted`)
    }
  })
}
