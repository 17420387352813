import axios, { AxiosInstance, AxiosResponse, AxiosRequestConfig } from 'axios'
import i18nextAppOpts from 'app/config/i18next'
import LanguageService from 'app/services/Language'
import errorInterceptor from './interceptors/error-interceptor'
import { addPreview, removePreview } from './interceptors/preview-interceptor'
import environment from '../environments/environment'

// auth
import initAuthHeader from './interceptors/auth-interceptor'

const { CancelToken } = axios
export const cancelTokenSource = CancelToken.source()

const prefixUrl = `${environment.apiEndpoint}/`
const httpOptions: AxiosRequestConfig = {
  baseURL: prefixUrl,
  withCredentials: true,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  cancelToken: cancelTokenSource.token,
}

/**
 * HTTP API service
 */
const http: AxiosInstance = axios.create(httpOptions)

http.interceptors.request.use(
  (config) => {
    const currentCountry = LanguageService?.currentCountry()?.isoCode
    // eslint-disable-next-line no-param-reassign
    if (currentCountry) config.headers['operating-country'] = currentCountry
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

// add auth header
initAuthHeader(http)

let tick: NodeJS.Timeout | null

const progressAdd = (config: AxiosRequestConfig) => {
  document.body.classList.add('progress-api')
  return config
}

const progressRemove = (response: AxiosResponse) => {
  if (!tick) {
    tick = setTimeout(() => {
      document.body.classList.remove('progress-api')
      tick = null
    }, 250)
  }

  return response
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const request = (options: any, url: string, payload: any, callBack: any): any => {
  return http
    .get(url)
    .then((response) => {
      callBack(null, { status: response.status, data: response.data })
    })
    .catch(callBack)
}

// Add global handling for: data and errors see https://axios-http.com/docs/interceptors/
http.interceptors.request.use(progressAdd)
http.interceptors.response.use(progressRemove)
http.interceptors.response.use(async (response: AxiosResponse) => response, errorInterceptor)

// preview parts
http.interceptors.request.use(addPreview)
http.interceptors.response.use(async (response: AxiosResponse) => response, removePreview)

let cacheHashInMemory = localStorage.getItem('cacheHash')

http.interceptors.response.use((response) => {
  const cacheHash = response.headers['x-cache-hash']

  if (!cacheHash || cacheHash === cacheHashInMemory) {
    return response
  }

  const storedHash = localStorage.getItem('cacheHash')

  if (!storedHash) {
    localStorage.setItem('cacheHash', cacheHash)
    cacheHashInMemory = cacheHash
  } else if (cacheHash !== storedHash) {
    localStorage.setItem('cacheHash', cacheHash)

    Object.keys(localStorage).forEach((key) => {
      if (key.startsWith(i18nextAppOpts.languageKeyName)) {
        localStorage.removeItem(key)
      }
    })

    window.location.reload()
  }

  return response
})

export default http
