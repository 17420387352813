import { BehaviorSubject, combineLatest, Observable, ReplaySubject } from 'rxjs'
import { map } from 'rxjs/operators'
import { PageVersionReload } from 'services/common/api/v1/websocket/PageVersionReload'
import http from '../../services/api'
import {
  AssetConfiguration,
  Configuration,
  ContactConfiguration,
  DomainConfiguration,
  LoginConfiguration,
  MenuConfiguration,
  PlatformConfiguration,
  PluginsConfiguration,
  ThemeConfiguration,
} from '../../services/common/entities/Configuration'
import { isPageWithPageModules, Page } from '../../services/common/entities/Page'
import { PageVersionPatch } from '../../services/common/api/v1/websocket/PageVersionPatch'
import { PageRegister } from '../../services/common/api/v1/websocket/PageRegister'
import Websocket from '../../services/ws'
import LanguageService from './Language'
import LocalizeService from './Localize'
import Service from './Service'
import { PatchExecutor } from '../../services/common/patch/PatchExecutor'
import { detectAppViewMode } from '../../utils/app'
import isValidPage from '../../utils/page'
import { ImageAsset } from '../../services/common/entities/Asset'

class ConfigurationService extends Service {

  private static pages: {
    [path: string]: {
      subject: ReplaySubject<Page>,
      value: Page | null,
    }
  } = {};

  private static configuration: BehaviorSubject<Configuration> = new BehaviorSubject<Configuration>(null as unknown as Configuration);

  public static menuConfiguration(): Observable<MenuConfiguration> {
    return LanguageService.language().pipe(map((language) => {
      const current = ConfigurationService.configuration.getValue()
      if(current.menu) {
        LocalizeService.menuItems(current.menu.headerMenuItems, language.language);
        LocalizeService.menuItems(current.menu.footerMenuItems, language.language);
        LocalizeService.menuItems(current.menu.userContextMenuItems, language.language);
        return { ...current.menu }
      }
      return {
        headerMenuItems: [],
        footerMenuItems: [],
        userContextMenuItems: []
      }
    }))
  }

  public static contactConfiguration(): Observable<ContactConfiguration> {
    return LanguageService.language().pipe(map(language => {
      const contactConfiguration = ConfigurationService.configuration.getValue().contact
      LocalizeService.arrayWithLocal(contactConfiguration.contactMethods, language.language)
      return { ...contactConfiguration }
    }))
  }

  public static currentMenuConfiguration(): MenuConfiguration {
    return ConfigurationService.configuration.getValue().menu
  }

  public static currentContactConfiguration(): ContactConfiguration {
    return ConfigurationService.configuration.getValue().contact
  }

  public static platformConfiguration(): PlatformConfiguration {
    return ConfigurationService.configuration.getValue().platform
  }

  public static assetConfiguration(): AssetConfiguration {
    return ConfigurationService.configuration.getValue().asset
  }

  public static getExplorePagePath(): string | null {
    const { explorePage } = ConfigurationService.configuration.getValue().platform

    if (!explorePage || typeof explorePage === 'string') {
      return null
    }

    return explorePage.path
  }

  public static themeConfiguration(): ThemeConfiguration | null {
    return ConfigurationService.configuration.getValue()?.theme || null
  }

  public static pluginsConfiguration(): PluginsConfiguration {
    return ConfigurationService.configuration.getValue().plugins
  }

  public static loginConfiguration(): LoginConfiguration {
    return ConfigurationService.configuration.getValue().login
  }

  public static domainConfiguration(): DomainConfiguration {
    return ConfigurationService.configuration.getValue().domain
  }

  public static async refreshPage(path: string): Promise<Page> {
    const isAppViewMode = detectAppViewMode()

    try {
      const response = await http.get(`/configuration/pages/${path}`, {
        params: { appViewMode: isAppViewMode ? 'true' : 'false' },
      })
      const page = response.data as Page
      ConfigurationService.pages[path].value = page
      ConfigurationService.pages[path].subject.next(page)
      return page
    } catch (error) {
      console.error(error)
      throw new Error('Failed to refresh the page')
    }
  }

  private static page(path: string): Observable<Page> {
    const isAppViewMode = detectAppViewMode()

    if (!ConfigurationService.pages[path]) {
      ConfigurationService.pages[path] = {
        subject: new ReplaySubject(1),
        value: null
      };

      http.get(`/configuration/pages/${path}`, {
        params: {
          appViewMode: isAppViewMode ? 'true' : 'false'
        }
      }).then((result) => {
        const page = result.data as Page
        Websocket.register<PageRegister>('page', { page: page.page }, `page:${page.page}`)
        ConfigurationService.pages[path].value = page
        ConfigurationService.pages[path].subject.next(page)
      }).catch((error) => {
        console.error(error)
      })
    } else {
      this.reloadPageModules(path)
    }

    return ConfigurationService.pages[path].subject.asObservable()
  }

  /**
   * Without a given array of page module ids, it reloads all pageModules with ignoreCache = true
   * @param path
   * @param pageModules
   */
  private static reloadPageModules(path: string, pageModules?: string[]) {
    const oldPage = ConfigurationService.pages[path] && ConfigurationService.pages[path].value

    if (oldPage && isPageWithPageModules(oldPage)) {
      const oldPageModules = oldPage.pageModules || []
      const pageModulesToReload = pageModules
        ? oldPageModules.filter(p => pageModules.includes(p._id))
        : oldPageModules.filter(p => p.ignoreCache)

      if (pageModulesToReload.length > 0) {
        const ids = pageModulesToReload.map(i => i._id)
        const isAppViewMode = detectAppViewMode()

        http.get(`/configuration/pages/${path}?pageModules=${ids.join(',')}`).then((result) => {
          let pages = result.data.items
          pages = pages.filter((page: Page) => isValidPage(page, isAppViewMode))
          const newPage = pages[0] as Page

          if (!newPage || !isPageWithPageModules(newPage)) return

          for (const pageModule of pageModulesToReload) {
            const index = oldPageModules.findIndex(p => p._id === pageModule._id)
            const newPageModule = newPage.pageModules?.find(p => p._id === pageModule._id)

            if (newPageModule && index >= 0) {
              oldPageModules.splice(index, 1, newPageModule)
            }
          }
        }).catch((error) => {
          console.error(error)
        })
      }
    }
  }

  public static getPage(path = 'default'): Observable<Page> {
    return combineLatest([
      ConfigurationService.page(path),
      LanguageService.language(),
    ]).pipe(map(([page, language]) => {
      LocalizeService.page(page, language.language)

      if (isPageWithPageModules(page) && page.pageModules) {
        LocalizeService.pageModules(page.pageModules, language.language)
      }

      return { ...page }
    }))
  }

  public static async init(): Promise<void> {
    const configuration: Configuration | null = (await http.get(`/configuration`)).data
    if (configuration) {
      ConfigurationService.configuration.next(configuration)
    }

    LanguageService.language().subscribe((language) => {
      LocalizeService.configuration(this.configuration.getValue(), language.language)
    })

    const checkIcon: ImageAsset | undefined = configuration?.theme.loginDialogCheckIcon

    if (checkIcon && checkIcon.mimeType === 'image/svg+xml') {
      document.documentElement.style.setProperty('--st--login-dialog-list-item-icon-url', `url("${checkIcon.paths.dataUri}")`);
    }

    Websocket.on<PageVersionPatch>('pageversion:patch', async (pageVersionPatch) => {
      if (this.pages[pageVersionPatch.page]) {
        const currentValue = await this.pages[pageVersionPatch.page].value

        if (currentValue?.pageVersion === pageVersionPatch.pageVersion) {
          PatchExecutor.patch(currentValue, pageVersionPatch.patch)
          this.pages[pageVersionPatch.page].subject.next(currentValue)
        }
      }
    })


    Websocket.on<PageVersionReload>('pageversion:reload', async (pageVersionReload):Promise<void> => {
      if (this.pages[pageVersionReload.page]) {
        const currentValue = await this.pages[pageVersionReload.page].value

        if (currentValue?.fullPath && currentValue?.pageVersion === pageVersionReload.pageVersion) {
          this.reloadPageModules(currentValue.fullPath, pageVersionReload.pageModules)
        }
      }
    })
  }

}

export default ConfigurationService
