/* eslint-disable class-methods-use-this */
import { GTMConfiguration } from '../../../services/common/entities/Configuration'
import { AppPluginStore } from '../types'
import Plugin from '../Plugin'
import gtmScript from './gtm-script'
import log from '../../../lib/logLevel'

import isDev from '../../../utils/isDev'
import ConfigurationService from '../../services/Configuration'
import { getUTMsAsObject } from '../../../lib/utm'
import UsercentricsPlugin from '../usercentrics/UsercentricsPlugin'
import { detectAppViewMode } from '../../../utils/app'

declare global {
  interface Window {
    dataLayer: Record<string, any>[]
  }
}

window.dataLayer = window.dataLayer || []

/**
 * Google Tag Manager Plugin
 */
class GtmPlugin2 extends Plugin {
  namespace = 'GTM'

  pluginStore!: AppPluginStore // definite assignment assertions

  gtmScript!: HTMLScriptElement

  config!: GTMConfiguration

  shouldInstall(): boolean {
    const config = ConfigurationService.pluginsConfiguration()?.gtm2
    return config?.enabled && !!config?.id
  }

  getPluginName(): string {
    return `${this.namespace}@1.0.0`
  }

  getDependencies(): string[] {
    return []
  }

  init(pluginStore: AppPluginStore): void {
    this.pluginStore = pluginStore
  }

  loadScript(): void {
    log.info(`${this.namespace}:: load script`)

    console.warn('this.config.id', this.config.id)
    if (this.config.id && !document.getElementById(this.config.id)) {
      // props
      this.gtmScript = document.createElement('script')
      this.gtmScript.setAttribute('type', this.config.scriptTagType || 'application/javascript')
      this.gtmScript.setAttribute('id', this.config.id)

      // Usercentrics - GTM integration
      if (UsercentricsPlugin.shouldInstall()) {
        this.gtmScript.setAttribute('data-usercentrics', 'Google Tag Manager')
      }

      if (isDev) {
        // logging
        const loadState = (e: Event) => log.log(`GTM Script :: loading state :: ${e}`)

        this.gtmScript.addEventListener('load', loadState)
        this.gtmScript.addEventListener('error', loadState)
      }

      // runner
      this.gtmScript.appendChild(gtmScript(this.config.id))
      document.head.appendChild(this.gtmScript)

      log.log(`${this.namespace}:: script created`, this.gtmScript)
    }
  }

  getConfig(): GTMConfiguration {
    const gtm2 = ConfigurationService.pluginsConfiguration()?.gtm2

    if (gtm2?.app && detectAppViewMode()) {
      return { enabled: gtm2.enabled, ...gtm2.app }
    }

    return gtm2
  }

  activate(): void {
    this.config = this.getConfig()

    if (this.config?.id) {
      const UTMs = getUTMsAsObject()
      if (Object.values(UTMs).length > 0) {
        // we want these to be pushed before the script is loaded
        window.dataLayer.push({ ...UTMs })
      }
      this.loadScript()
    }

    this.ready()
  }

  deactivate(): void {
    log.log(`${this.namespace}:: deactivate`)
    if (this.gtmScript && this.gtmScript.parentNode) {
      document.head.removeChild(this.gtmScript)
    }
  }
}

export default new GtmPlugin2()
