import { BehaviorSubject, Observable } from 'rxjs'
import { distinctUntilKeyChanged, filter } from 'rxjs/operators'
import ucLanguageSwitch from '../../lib/usercentrics'
import i18n from '../../locales/i18n'
import http from '../../services/api'
import { Language } from '../../services/common/entities/Language'
import LocationService from './Location'
import Service from './Service'
import { SingleEvent } from '../../services/common/entities/configuration/PlatformConfiguration'
import { OperatingCountry } from '../../services/common/entities/OperatingCountry'
import LocalizeService from './Localize'
import GeoIpService from './GeoIp'

class LanguageService extends Service {
  private static languagesSubject: BehaviorSubject<Language[]> = new BehaviorSubject<Language[]>([])

  private static operatingCountriesSubject: BehaviorSubject<OperatingCountry[]> = new BehaviorSubject<OperatingCountry[]>([])

  private static selectableLanguagesSubject: BehaviorSubject<Language[]> = new BehaviorSubject<Language[]>([])

  private static selectedLanguageSubject: BehaviorSubject<Language> = new BehaviorSubject<Language>(null as unknown as Language)

  private static selectedCountrySubject: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null)

  private static pathExcludes: string[] = ['robots.txt', 'sitemap.xml']

  public static languageViaCountrySubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)

  public static languageViaCountry(): boolean {
    return LanguageService.languageViaCountrySubject.getValue()
  }

  public static languages(): Observable<Language[]> {
    return LanguageService.languagesSubject.asObservable()
  }

  public static language(): Observable<Language> {
    return LanguageService.selectedLanguageSubject.pipe(filter(l => !!l), distinctUntilKeyChanged('language'))
  }

  public static selectableLanguages(): Observable<Language[]> {
    return LanguageService.selectableLanguagesSubject.asObservable()
  }

  public static currentLanguage(): Language {
    return LanguageService.selectedLanguageSubject.getValue() || { language: 'en', active: true, selectable: true }
  }

  public static currentLanguages(): Language[] {
    return LanguageService.languagesSubject.getValue()
  }

  public static currentSelectableLanguages(): Language[] {
    return LanguageService.selectableLanguagesSubject.getValue()
  }

  public static currentCountry(): OperatingCountry | null {
    const country = LanguageService.currentCountries().find(c => c.isoCode === LanguageService.selectedCountrySubject.getValue())
    if (country)
    LocalizeService.operatingCountry(country, LanguageService.currentLanguage().language)
    return country || null
  }

  private static currentCountries(): OperatingCountry[] {
    return LanguageService.operatingCountriesSubject.getValue()
  }

  public static findOperatingCountryByISO(language: string): OperatingCountry | undefined {
    return this.currentCountries().find((c) => c.isoCode.toLowerCase() === language.toLowerCase());
  }

  public static findOperatingCountryByFavoriteLanguage(language: string): OperatingCountry | undefined {
    return this.currentCountries().find((c) => (c.favoriteLanguages || []).includes(language));
  }

  public static findOperatingCountryByLanguage(language: string): OperatingCountry | undefined {
    return this.findOperatingCountryByISO(language) || this.findOperatingCountryByFavoriteLanguage(language);
  }

  private static languageNotInPath(path: string[]): boolean {
    return LanguageService.currentLanguages().findIndex(l => l.language === path[1] || (path[0].indexOf('_') >= 0 && l.language === path[1].substring(0,2))) !== -1
  }

  public static currentLocale(): string {
    if (LanguageService.languageViaCountrySubject.getValue()) {
      return `${LanguageService.currentLanguage().language}_${LanguageService.currentCountry()?.isoCode.toUpperCase()}`
    }
    return LanguageService.currentLanguage().language
  }

  private static navigateLocale(locale: string, singleEvent?: string | SingleEvent) {
    let {pathname} = LocationService.currentUrl()
    const localeRedirect = localStorage.getItem('365Platform_localeRedirect')
    if (localeRedirect) {
      pathname = localeRedirect
    }

    if(!LanguageService.pathExcludes.some(path => pathname.includes(path))) {
      const path = pathname.split('/')
      const languageNotInPath = LanguageService.languageNotInPath(path)
      const localeInPath = LocationService.localeInPath(pathname)
      const replaceLang = languageNotInPath || localeInPath

      if (path[1] !== locale || (singleEvent && path[2] === '')) {
        LocationService.navigateLocale(locale, replaceLang, pathname, singleEvent)
      }
    }
  }

  private static parseLanguage(language: Language | string): Language | undefined {
    if(typeof language === 'string') {
      return LanguageService.languagesSubject.getValue().find(l => l.language === language)
    }
    return language
  }

  public static selectLanguage(language: Language | string, singleEvent?: string | SingleEvent): void {
    const parsedLanguage = this.parseLanguage(language)

    if (parsedLanguage) {
      LanguageService.selectedLanguageSubject.next(parsedLanguage)
      LanguageService.navigateLocale(parsedLanguage.language, singleEvent)
    }
  }

  public static selectLocale(language: string, country: string, singleEvent?: string | SingleEvent): void {
    const parsedLanguage = this.parseLanguage(language)
    const locale = `${parsedLanguage?.language}_${country.toUpperCase()}`
    LanguageService.selectCountry(country)
    localStorage.setItem('currentCountry', country.toUpperCase())

    if (parsedLanguage) {
      LanguageService.selectedLanguageSubject.next(parsedLanguage)
      LanguageService.navigateLocale(locale, singleEvent)
    }
  }

  public static changeLanguage(language: Language, country?: string): void {
    LanguageService.selectedLanguageSubject.next(language)

    if (country && this.languageViaCountry()) {
      LanguageService.selectCountry(country)
    }
  }

  public static selectCountry( country: string ): void {
    LanguageService.selectedCountrySubject.next(country)
    localStorage.setItem('currentCountry', country.toUpperCase())
  }

  public static selectPlatformLanguage(language: string): void {
    localStorage.setItem('platformLanguage', language)
    LanguageService.selectLanguage(language)
    LanguageService.navigateLocale(language)
  }

  public static updateSelectableLanguages(languages: string[] = []): void {
    const currentLanguages = LanguageService.currentLanguages()

    if (languages.length) {
      return LanguageService.selectableLanguagesSubject.next(
        currentLanguages.filter((language) => languages.includes(language.language))
      )
    }

    return LanguageService.selectableLanguagesSubject.next(
      currentLanguages.filter((language) => language.active && language.selectable)
    )
  }

  public static applyPlatformLanguage(): void {
    LanguageService.updateSelectableLanguages()
    const currentLanguage = LanguageService.currentLanguage()
    const selectableLanguages = LanguageService.currentSelectableLanguages()
    const country = LanguageService.countryFromURL() || LanguageService.retrieveCountry()

    // 1. Extract the language segment from the URL
    const [languageSegment] = window.location.pathname.split('/')[1].split('_')

    // 2. Get the selectable language based on the language segment
    const language = selectableLanguages.find((l) => l.language === languageSegment)

    // 3. Handle the case where no language is found
    if (!language) {
      if (LanguageService.languageViaCountry()) {
        if (country.country && country.language) {
          LanguageService.selectLocale(country.language, country.country)
        }
      } else {
        LanguageService.selectLanguage(selectableLanguages[0])
      }

      return
    }

    // 4. If the language is the same as the current language, return
    if (language.language === currentLanguage.language) {
      return
    }

    // 5. Apply the language
    LanguageService.changeLanguage(language, country.country)
  }

  static checkForRedirects(singleEvent?: string | SingleEvent, languageViaCountry?: boolean): void {
    const params = LocationService.currentUrl().searchParams
    const languages = LanguageService.currentLanguages()

    const currentLanguage = [
      params.get('language'),
      params.get('lang'),
      LocationService.currentUrl().pathname.substring(3,1),
      localStorage.getItem('currentLanguage'),
      navigator.language?.substring(0, 2),
      'en',
    ].find(item => languages.some(t => t.language === item)) || languages[0]?.language as string;

    if (languageViaCountry) {
      let languageCountry = LanguageService.countryFromURL()
      if(!languageCountry) {
        languageCountry = LanguageService.retrieveCountry()
      }
      if(languageCountry.country) {
        const language = languageCountry.language || currentLanguage
        LanguageService.selectedCountrySubject.next(languageCountry.country)
        LanguageService.selectLocale(language, languageCountry.country, singleEvent)
      } else {
        LanguageService.selectLanguage(currentLanguage, singleEvent)
      }
    } else {
      LanguageService.selectLanguage(currentLanguage, singleEvent)
      localStorage.removeItem('currentCountry')
    }
  }

  private static countryFromURL(): { language?: string, country?: string } |null {
    const countryIsoFromURL = LocationService.currentUrl().pathname.substring(4,6)
    const countries = LanguageService.currentCountries()
    const countryFromURL = countries.find(c => c.isoCode === countryIsoFromURL)
    const favoriteLanguages = countryFromURL?.favoriteLanguages
    if(countryFromURL && favoriteLanguages) {
      return {
        language: favoriteLanguages[0],
        country: countryFromURL.isoCode
      }
    }
    return null
  }

  private static retrieveCountry(): { language?: string, country?: string } {
    const currentCountryFromLocalStorage =  localStorage.getItem('currentCountry')
    if(currentCountryFromLocalStorage && LanguageService.currentCountry()?.isoCode === currentCountryFromLocalStorage) {
      return {
        country: currentCountryFromLocalStorage
      }
    }

    const operationCountry = GeoIpService.getOperatingCountry()
    if(operationCountry) {
      const favoriteLanguages = operationCountry?.favoriteLanguages
      if(favoriteLanguages) {
        localStorage.setItem('currentCountry', operationCountry.isoCode)
        return {
          language: favoriteLanguages[0],
          country: operationCountry.isoCode
        }
      }
    }

    return {}
  }


  static async init(): Promise<void> {
    LanguageService.language().subscribe((language) => {
      localStorage.setItem('currentLanguage', language.language)
      i18n.changeLanguage(language.language);
      ucLanguageSwitch(language.language)
    })
    const languages = (await http.get(`configuration/languages`).then((res) => res.data)).items as Language[]

    LanguageService.languagesSubject.next(languages)
    LanguageService.updateSelectableLanguages()

    const operatingCountries = (await http.get(`configuration/operating-countries`).then((res) => res.data)).items
    LanguageService.operatingCountriesSubject.next(operatingCountries)
  }
}

export default LanguageService
