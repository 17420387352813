/* eslint-disable prettier/prettier */
import { BehaviorSubject, Observable } from 'rxjs'
import { getServerTime } from '../../services/WsPing'
import Service from './Service'

class ServerTimeService extends Service {
  private static serverTimeService: ServerTimeService | null = null

  private difference = 0

  private serverTimeSubject: BehaviorSubject<Date> = new BehaviorSubject(new Date())

  constructor() {
    super()

    setInterval(() => {
      this.serverTimeSubject.next(new Date(new Date().getTime() + this.difference))
    }, 1000)
  }

  public serverTime(): Observable<Date> {
    return this.serverTimeSubject.asObservable()
  }

  public currentServerTime(): Date {
    return this.serverTimeSubject.getValue()
  }

  public setDifference(difference: number): void {
    this.difference = difference
    // this.serverTimeSubject.next(new Date(new Date().getTime() + this.difference))
  }

  public static get(): ServerTimeService {
    if (!ServerTimeService.serverTimeService) {
      ServerTimeService.serverTimeService = new ServerTimeService()
    }
    return ServerTimeService.serverTimeService
  }

  public static async init(): Promise<void> {
    await getServerTime().then((serverTime) => {
      ServerTimeService.get().setDifference(serverTime.diff)
    })
  }
}

export default ServerTimeService
