import Websocket, { WebsocketOpts } from '../lib/websocket'
import environment from '../environments/environment'
import { getPreviewParam, previewStorageKey } from '../utils/preview'

const previewToken = getPreviewParam()
const wsOpts: WebsocketOpts = {
  upgrade: true,
  autoConnect: true,
  rejectUnauthorized: true,
  reconnection: true,
  reconnectionAttempts: Infinity,
  reconnectionDelay: 1000 * 1,
  reconnectionDelayMax: 1000 * 10,
}

if (previewToken) {
  if (typeof wsOpts.extraHeaders !== 'object') {
    wsOpts.extraHeaders = {}
  }
  wsOpts.extraHeaders[previewStorageKey] = previewToken
}

// if (previewToken) wsOpts.headers.

Websocket.connect(environment.wsEndpoint, '/ws/v1/websocket', wsOpts)

// https://medium.com/swlh/build-a-real-time-chat-app-with-react-hooks-and-socket-io-4859c9afecb0

export default Websocket
