import loglevel from 'loglevel'
import isDev from '../utils/isDev'

/*
 see: https://github.com/pimterry/loglevel
 Minimal lightweight simple logging for JavaScript.
 loglevel replaces console.log() and friends with level-based logging and filtering,
 with none of console's downsides.

 @example:
    import log from 'logLevel'
    log.warn('module-tastic')
 */

const prefix = 'ST'
const globalDebug = localStorage.getItem('debug') || undefined
let level = isDev ? loglevel.levels.DEBUG : loglevel.levels.ERROR

if (globalDebug && globalDebug === '*') level = loglevel.levels.DEBUG

loglevel.setDefaultLevel(level)

export default loglevel.getLogger(prefix)
