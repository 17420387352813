import React, { HTMLProps } from 'react'

type ExternalLinkProps = Omit<HTMLProps<HTMLAnchorElement>, 'href'> & {
  to: string
  children: React.ReactNode
  target?: '_blank' | '_self'
}

const ExternalLink: React.FC<ExternalLinkProps> = ({
  to,
  children,
  target = '_blank',
  ...anchorElementProps
}: ExternalLinkProps): JSX.Element => {
  const isBlankTarget = target === '_blank'

  return (
    <a
      {...anchorElementProps}
      href={to}
      target={target}
      {...(isBlankTarget && { rel: 'noopener noreferrer' })}
    >
      {children}
    </a>
  )
}

export default ExternalLink
