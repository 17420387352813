import { RECENT_ARTICLES_DAYS } from "constants/ui"
import Service from "./Service"

interface SeenArticle {
  link: string
  date: string
}

const SEEN_ARTICLES_KEY = 'seenArticles'

class NewsFeedService extends Service {
  private static seenArticles: SeenArticle[] = []

  public static isArticleSeen(link: string): boolean {
    return this.seenArticles.some((article) => article.link === link)
  }

  public static markArticleAsSeen(link: string): void {
    if (this.isArticleSeen(link)) {
      return
    }

    this.seenArticles.push({
      link,
      date: new Date().toISOString(),
    })

    localStorage.setItem(SEEN_ARTICLES_KEY, JSON.stringify(this.seenArticles))
  }

  public static async init(): Promise<void> {
    try {
      const storedArticles = JSON.parse(localStorage.getItem(SEEN_ARTICLES_KEY) || '[]')

      if (!storedArticles || !Array.isArray(storedArticles)) {
        return
      }

      const cutoffDate = new Date()
      cutoffDate.setDate(cutoffDate.getDate() - RECENT_ARTICLES_DAYS)

      this.seenArticles = (storedArticles as SeenArticle[]).filter((article) => {
        const articleDate = new Date(article.date)
        return articleDate > cutoffDate
      })

      localStorage.setItem(SEEN_ARTICLES_KEY, JSON.stringify(this.seenArticles))
    } catch (error) {
      this.seenArticles = []
    }
  }
}

export default NewsFeedService
