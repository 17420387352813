/* eslint-disable @typescript-eslint/lines-between-class-members */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable max-classes-per-file */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-debugger */
/* eslint-disable no-param-reassign */
import { BehaviorSubject, Observable, combineLatest, Subscription } from 'rxjs'
import { map, distinctUntilChanged, distinctUntilKeyChanged } from 'rxjs/operators'
import { registerBeacons } from 'services/AppBridge'
import http from '../../services/api'
import { useAppStore } from '../store/app-store'
import { getEventRouteMatch } from '../router/routes'
import { Event, EventPhase, eventPhase, SessionMode } from '../../services/common/entities/Event'
import {
  isLiveSessionMedia,
  isSessionWithSessionMedia,
  isVodSessionMedia,
  Session,
} from '../../services/common/entities/Session'
import { EventUpdate } from '../../services/common/api/v1/websocket/EventUpdate'
import { EventVersionUpdate } from '../../services/common/api/v1/websocket/EventVersionUpdate'
import { EventUpdatedAt } from '../../services/common/api/v1/websocket/EventUpdatedAt'
import { EventVersionPatch } from '../../services/common/api/v1/websocket/EventVersionPatch'
import { EventSessions } from '../../services/common/api/v1/websocket/EventSessions'
import ServerTimeService from './ServerTime'
import LocalizeService from './Localize'
import LanguageService from './Language'
import Service from './Service'
import { isFutureSession, isLiveSession, isPastSession, mostRelevantSession, sessionsChanged } from '../utils/session'
import LocationService from './Location'
import SessionHelperService from './SessionHelper'
import Websocket from '../../services/ws'
import { PatchExecutor } from '../../services/common/patch/PatchExecutor'
import { EventViewRegister } from '../../services/common/api/v1/websocket/EventViewRegister'
import { EventViewUnregister } from '../../services/common/api/v1/websocket/EventViewUnregister'
import { EventRegister } from '../../services/common/api/v1/websocket/EventRegister'
import { EventUnregister } from '../../services/common/api/v1/websocket/EventUnregister'
import { GetEventsQuery, GetEventsResponse } from '../../services/common/api/v1/events/GetEvents';
import { GetLocalizedMap } from '../../services/configuration/GetMap'
import { detectAppViewMode } from '../../utils/app'
import LayoutEditorService from './LayoutEditor'
import AuthService from './Auth'

export interface SelectedSession {
  mode: SessionMode
  session: Session | null
}

export type SessionPlayerState = 'none' | 'running' | 'paused' | 'ended'

export type SessionPlayerStatus = {
  session?: Session
  timeLeft?: number
  state?: SessionPlayerState
}

export type SessionPlayerProperties = {
  mode?: SessionMode
  pause?: boolean
  streamingUrl?: string
  session?: Session
  destinationDate?: Date
}

class SessionPlayerController {

  private sessionPlayerStatusSubject: BehaviorSubject<SessionPlayerStatus> = new BehaviorSubject<SessionPlayerStatus>((null as unknown) as SessionPlayerStatus)
  private sessionPlayerPropertiesSubject: BehaviorSubject<SessionPlayerProperties> = new BehaviorSubject<SessionPlayerProperties>((null as unknown) as SessionPlayerProperties)

  private _subscriptions: Subscription[] = []

  constructor(
    private eventService: EventService
  ) {
    this._subscriptions.push(combineLatest([
      eventService.selectedSession(),
      eventService.futureSessions(),
      LanguageService.language(),
    ]).subscribe(([selectedSession, futureSessions]) => {
      if (selectedSession?.session) {
        if (futureSessions.find(s => s._id === selectedSession.session?._id)) {
          let destinationDate = selectedSession.session.startAt

          // if it's a hidden session, we want the countdown to the next non hidden session
          if (selectedSession.session.hidden) {
            const nonHiddenSession = mostRelevantSession(futureSessions.filter(s => !s.hidden), false)
            if (nonHiddenSession) {
              destinationDate = nonHiddenSession.startAt
            }
          }

          this.sessionPlayerPropertiesSubject.next({
            mode: selectedSession.mode,
            session: selectedSession.session,
            destinationDate: new Date(destinationDate)
          })

        } else {
          this.sessionPlayerPropertiesSubject.next({
            pause: this.currentSessionPlayerStatus()?.state !== 'running',
            mode: selectedSession.mode,
            session: selectedSession.session,
            streamingUrl: SessionHelperService.streamingUrl(selectedSession.session, selectedSession.mode) || ''
          })
        }
      } else {
        this.sessionPlayerPropertiesSubject.next({
          mode: selectedSession?.mode,
        })
      }
    }))
  }

  dispose() {
    this._subscriptions.forEach(s => s.unsubscribe())
  }

  public sessionPlayerStatus(): Observable<SessionPlayerStatus> { return this.sessionPlayerStatusSubject.asObservable() }
  public sessionPlayerProperties(): Observable<SessionPlayerProperties> { return this.sessionPlayerPropertiesSubject.asObservable() }
  public currentSessionPlayerStatus(): SessionPlayerStatus { return this.sessionPlayerStatusSubject.getValue() }
  public currentSessionPlayerProperties(): SessionPlayerProperties { return this.sessionPlayerPropertiesSubject.getValue() }

  public sessionPlayerState(): Observable<{ session?: Session, state?: SessionPlayerState }> {
    return this.sessionPlayerStatusSubject.pipe(map((s) => ({
      session: s?.session,
      state: s?.state,
      key: `${s?.session?._id || '###'}-${s?.state || '###'}`
    })), distinctUntilKeyChanged('key'))
  }

  public updateSessionPlayerStatus(sessionStatus: SessionPlayerStatus): void {
    this.sessionPlayerStatusSubject.next({ ...sessionStatus })
  }

  public async playSession(session: Session) {

    if (this.eventService.currentSelectedSession()?.session?._id !== session._id) {
      this.eventService.selectSession(session)
    }

    const event = await EventService.get(SessionHelperService.eventId(session)).eventAsPromise()
    const url = `/${LanguageService.currentLanguage().language}/events/${event.shortId}/stream`
    const currentUrl = LocationService.currentUrl().pathname

    if (url !== currentUrl) {
      LocationService.navigate(url)
    }

    setTimeout(() => {
      this.resumeSession()
    });

  }

  public pauseSession(): void {
    this.sessionPlayerPropertiesSubject.next({
      ...this.sessionPlayerPropertiesSubject.getValue(),
      pause: true
    })
  }

  public resumeSession(): void {
    this.sessionPlayerPropertiesSubject.next({
      ...this.sessionPlayerPropertiesSubject.getValue(),
      pause: false
    })
  }
}

export interface CategorizedSessions {
  pastSessions: Session[]
  liveSessions: Session[]
  futureSessions: Session[]
}

class EventService extends Service {
  private static events: { [eventId: string]: EventService } = {}
  private static _lastEventIdFromUrl: string | null = null

  private _ready: Promise<Event> | undefined
  private _sessionPlayerController: SessionPlayerController

  private selectedSessionSubject: BehaviorSubject<SelectedSession> = new BehaviorSubject<SelectedSession>((null as unknown) as SelectedSession)
  private eventSubject: BehaviorSubject<Event> = new BehaviorSubject<Event>((null as unknown) as Event)
  private pastSessionsSubject: BehaviorSubject<Session[]> = new BehaviorSubject<Session[]>([])
  private liveSessionsSubject: BehaviorSubject<Session[]> = new BehaviorSubject<Session[]>([])
  private futureSessionsSubject: BehaviorSubject<Session[]> = new BehaviorSubject<Session[]>([])
  private categorizedSessionsSubject: BehaviorSubject<CategorizedSessions> = new BehaviorSubject<CategorizedSessions>({
    pastSessions: [],
    liveSessions: [],
    futureSessions: [],
  })

  private _subscriptions: Subscription[] = []

  get sessionPlayerController(): SessionPlayerController {
    return this._sessionPlayerController
  }

  constructor(private eventId: string) {
    super()
    this._sessionPlayerController = new SessionPlayerController(this)
  }

  private nextEvent(event: Event) {
    LocalizeService.event(event, LanguageService.currentLanguage().language)
    const layoutEditorMode = LayoutEditorService.currentLayoutEditorMode()


    if (layoutEditorMode?.eventPhase && event.currentEventVersion._id === layoutEditorMode.eventVersion) {
      event.currentEventVersion.phase.current = event.currentEventVersion.phase[layoutEditorMode.eventPhase]
    } else {
      const phase = eventPhase(event, ServerTimeService.get().currentServerTime())
      event.currentEventVersion.phase.current = event.currentEventVersion.phase[phase]
    }

    this.eventSubject.next(event)
  }

  public async initEvent(opts: { selectedSession?: string; shouldSelectSession: boolean; } = { shouldSelectSession: true }): Promise<Event> {
    if (!this._ready) {
      this._ready = http.get(`events/${this.eventId}`).then((res) => {
        const event = res.data as Event

        if (!EventService.events[event._id]) EventService.events[event._id] = this
        if (!EventService.events[event.shortId]) EventService.events[event.shortId] = this
        const eventRouteMatch = getEventRouteMatch(window.location.pathname)
        const isCurrentEvent = this.eventId === eventRouteMatch?.params.eventId

        if (isCurrentEvent && detectAppViewMode()) {
          setTimeout(() => {
            const [eventMapId] = (event.currentEventVersion.eventMaps?.filter(Boolean) || [])

            if (!eventMapId) {
              return
            }

           GetLocalizedMap(eventMapId, LanguageService.currentLanguage().language).then((eventMap) => {
                if ('errorTag' in eventMap) {
                  console.error(`Map not found for ID: ${eventMapId}`)
                } else {
                  useAppStore.getState().setMap(eventMap)
                  registerBeacons(eventMap.beacons, {
                    searchTimer: eventMap.beaconSearchTimer,
                    refreshInterval: eventMap.beaconRefreshInterval,
                  })
                }
              })
          }, 2000)
        }

        this.nextEvent(event)

        Websocket.register<EventRegister>('event', { event: event._id }, `event:${event._id}`)

        if (opts.shouldSelectSession) {
          let session: Session | undefined
          const localSessions = event.currentEventVersion.sessions.filter(s => s.local[LanguageService.currentLanguage().language])
          if (opts.selectedSession) {
            session = localSessions.find(s => s._id === opts.selectedSession)
          }

          if (!session) {
            const url = LocationService.currentUrl()
            const sessionParam = url.searchParams.get("session")
            const sessionParamParts = sessionParam?.split('-') || []

            if (sessionParam && (sessionParamParts?.length === 1 || [event._id, event.shortId].includes(sessionParamParts[0]))) {
              session = localSessions.find(s => s._id === sessionParamParts[sessionParamParts.length - 1])
            }
          }

          if (!session) {
            const liveSessions = localSessions.filter((s) => isLiveSession(ServerTimeService.get().currentServerTime(), s))
            session = mostRelevantSession(liveSessions, true)
          }

          if (!session) {
            const futureSessions = localSessions.filter((s) => isFutureSession(ServerTimeService.get().currentServerTime(), s))
            session = mostRelevantSession(futureSessions, true)
          }

          if (!session) {
            const pastSessions = localSessions.filter((s) => !s.hidden && isPastSession(ServerTimeService.get().currentServerTime(), s))
            session = mostRelevantSession(pastSessions, true)
          }

          setTimeout(() => {
            if (session) {
              this.selectSession(session)
            }
          })
        }

        // each second there is a check for changes on past, live or future sessions
        this._subscriptions.push(combineLatest([
          this.localSessions(),
          ServerTimeService.get().serverTime()
        ]).subscribe(([sessions, serverTime]) => {
          const newPastSessions = sessions.filter((s) => isPastSession(serverTime, s))
          const newLiveSessions = sessions.filter((s) => isLiveSession(serverTime, s))
          const newFutureSessions = sessions.filter((s) => isFutureSession(serverTime, s))

          const pastSessionsChanged = sessionsChanged(this.pastSessionsSubject.getValue(), newPastSessions)
          const liveSessionsChanged = sessionsChanged(this.liveSessionsSubject.getValue(), newLiveSessions)
          const futureSessionsChanged = sessionsChanged(this.futureSessionsSubject.getValue(), newFutureSessions)

          if (pastSessionsChanged) {
            this.pastSessionsSubject.next(newPastSessions)
          }

          if (liveSessionsChanged) {
            this.liveSessionsSubject.next(newLiveSessions)
          }

          if (futureSessionsChanged) {
            this.futureSessionsSubject.next(newFutureSessions)
          }

          if (pastSessionsChanged || liveSessionsChanged || futureSessionsChanged) {
            this.categorizedSessionsSubject.next({
              pastSessions: newPastSessions,
              liveSessions: newLiveSessions,
              futureSessions: newFutureSessions,
            })
          }
        }))

        // this subscription decides what to do, if the past, live or future sessions changes
        this._subscriptions.push(combineLatest([
          this.selectedSession(),
          this.categorizedSessions()
        ]).subscribe(([selectedSession, categorizedSessions]) => {
          const currentUrl = LocationService.currentUrl()
          const pathParts = currentUrl.pathname.split('/')

          if (selectedSession?.session && pathParts[0] === 'events' && (pathParts[1] === this.currentEvent().shortId || this.currentEvent()._id)) {
            LocationService.navigate(null, {
              session: `${selectedSession.session?.event}-${selectedSession.session?._id}`
            })
          }

          const { pastSessions, liveSessions, futureSessions } = categorizedSessions

          // The current status of a SessionPlayer, if visible to the user
          let sessionPlayerStatus: SessionPlayerStatus | null = this.sessionPlayerController.currentSessionPlayerStatus()

          if (sessionPlayerStatus?.session?._id !== selectedSession?.session?._id) {
            sessionPlayerStatus = null
          }

          // An automatic session change is only relevant, if the user is not in the onDemand mode
          if (selectedSession?.mode === 'live' || selectedSession?.mode === 'relive') {

            // if the user paused the session or if there are only 10 seconds left in a relive session, we will keep that session
            if (sessionPlayerStatus && selectedSession && sessionPlayerStatus.session?._id === selectedSession?.session?._id) {
              if (sessionPlayerStatus.state === 'paused' || sessionPlayerStatus.state === 'running' && selectedSession.mode === 'relive' && (sessionPlayerStatus.timeLeft || 99999999) < 10) {
                return
              }
            }

            // if we are in a session that's live and not skippable, keep that session
            if (
              selectedSession &&
              !selectedSession.session?.skippable &&
              (!sessionPlayerStatus || sessionPlayerStatus?.state !== 'ended') &&
              liveSessions.find((s) => s._id === selectedSession?.session?._id)
            ) {
              return
            }

            // if the session is over or skippable, find the next one
            if (!selectedSession || pastSessions.find((s) => s._id === selectedSession.session?._id) || sessionPlayerStatus?.state === 'ended') {
              const liveSession = mostRelevantSession(liveSessions, true)

              if (liveSession) {
                this.selectSession(liveSession)
                return
              }

              const futureSession = mostRelevantSession(futureSessions, false)

              if (futureSession) {
                this.selectSession(futureSession)
                return
              }
            }

            // select first session
            if (liveSessions.length === 0 && futureSessions.length === 0) {
              const firstSession = mostRelevantSession(pastSessions.filter(s => !s.hidden), false)

              if (firstSession) {
                this.selectSession(firstSession)
              }
            }
          }
        }))

        this._subscriptions.push(this.eventPhase().subscribe((phase: EventPhase) => {
          this.eventSubject.getValue().currentEventVersion.phase.current = this.eventSubject.getValue().currentEventVersion.phase[phase];
          this.nextEvent({ ...this.eventSubject.getValue() })
        }))

        LanguageService.language().subscribe(() => {
          this.nextEvent({ ...this.eventSubject.getValue() })
        })

        return event

      })
    }

    return this._ready
  }

  public dispose() {
    this._subscriptions.forEach(s => s.unsubscribe())
    Websocket.unregister<EventUnregister>('event', { event: this.eventSubject.getValue()._id }, `event:${this.eventSubject.getValue()._id}`)
  }

  public event(): Observable<Event> {
    return this.eventSubject.asObservable()
  }

  public async eventAsPromise(): Promise<Event> {
    if (this.eventSubject.getValue()) {
      return this.eventSubject.getValue()
    }
    return await this._ready as unknown as Event
  }

  public sessions(): Observable<Session[]> { return this.event().pipe(map((event) => event?.currentEventVersion?.sessions || [])) }

  public localSessions(): Observable<Session[]> {
    return combineLatest([
      this.sessions(),
      LanguageService.language(),
    ]).pipe(
      map(([sessions, selectedLanguage]) => {
        return (sessions || []).filter((s) => s.local && s.local[selectedLanguage.language])
      })
    )
  }

  public pastSessions(): Observable<Session[]> { return this.pastSessionsSubject.asObservable() }
  public liveSessions(): Observable<Session[]> { return this.liveSessionsSubject.asObservable() }
  public futureSessions(): Observable<Session[]> { return this.futureSessionsSubject.asObservable() }
  public categorizedSessions(): Observable<CategorizedSessions> { return this.categorizedSessionsSubject.asObservable() }
  public selectedSession(): Observable<SelectedSession> { return this.selectedSessionSubject.asObservable() }

  public currentEvent(): Event { return this.eventSubject.getValue() }
  public currentSessions(): Session[] { return this.eventSubject.getValue()?.currentEventVersion?.sessions || [] }
  public currentPastSessions(): Session[] { return this.pastSessionsSubject.getValue() }
  public currentLiveSessions(): Session[] { return this.liveSessionsSubject.getValue() }
  public currentFutureSessions(): Session[] { return this.futureSessionsSubject.getValue() }
  public currentCategorizedSessions(): CategorizedSessions { return this.categorizedSessionsSubject.getValue() }
  public currentSelectedSession(): SelectedSession { return this.selectedSessionSubject.getValue() }

  public eventPhase(): Observable<EventPhase> {
    return combineLatest([
      this.event(),
      ServerTimeService.get().serverTime(),
      LayoutEditorService.layoutEditorMode(),
    ]).pipe(map(([event, serverTime, layoutEditorMode]) => {
      if (layoutEditorMode?.eventPhase && event?.currentEventVersion._id === layoutEditorMode.eventVersion) {
        return layoutEditorMode.eventPhase
      }
      return event ? eventPhase(event, serverTime) : 'UNKNOWN'
    })).pipe(distinctUntilChanged())
  }

  public currentEventPhase(): EventPhase {
    const layoutEditorMode = LayoutEditorService.currentLayoutEditorMode()
    const event = this.currentEvent()
    if (layoutEditorMode?.eventPhase && event?.currentEventVersion._id === layoutEditorMode.eventVersion) {
      return layoutEditorMode.eventPhase
    }
    return eventPhase(this.currentEvent(), ServerTimeService.get().currentServerTime())
  }

  public selectSession(sessionIdOrObject?: Session | string | null): void {
    const currentSelectedSession = this.currentSelectedSession()

    if (sessionIdOrObject) {
      const session = typeof sessionIdOrObject === 'string' ? this.eventSubject.getValue().currentEventVersion.sessions.find(s => s._id === sessionIdOrObject) : sessionIdOrObject

      // if (!session || this.currentSelectedSession() && this.currentSelectedSession().session === session) return
      if (!session) return

      const mode = EventService.determineMode(session)

      if (currentSelectedSession?.session?._id !== session._id || currentSelectedSession?.mode !== mode) {
        this.selectedSessionSubject.next({ session, mode })
      }

    } else {
      this.selectedSessionSubject.next({
        session: null,
        mode: currentSelectedSession?.mode
      })
    }
  }

  public selectInitialSession() {
    const { pastSessions, liveSessions, futureSessions } = this.categorizedSessionsSubject.getValue()

    if (liveSessions.some(s => s._id === this.selectedSessionSubject.getValue()?.session?._id)) {
      return
    }

    const liveSession = mostRelevantSession(liveSessions, true)

    if (liveSession) {
      this.selectSession(liveSession)
      return
    }

    const futureSession = mostRelevantSession(futureSessions, false)

    if (futureSession) {
      this.selectSession(futureSession)
      return
    }

    const firstSession = mostRelevantSession(pastSessions.filter(s => !s.hidden), false)

    if (firstSession) {
      this.selectSession(firstSession)
    }
  }

  public async updateEvent(event: Event) {
    this.nextEvent(event)
    setTimeout(() => {
      const currentSession = this.currentSelectedSession()

      if (currentSession?.session) {
        const session = event.currentEventVersion.sessions.find(s => s._id === currentSession.session?._id)

        if (session) {
          this.selectedSessionSubject.next({
            session,
            mode: currentSession.mode
          })
        }
      }
    });
  }

  public async refreshEvent(force = false) {
    const event = await http.get(`events/${this.eventId}`).then((e) => e.data as Event)

    if (force || event && new Date(event.updatedAt).getTime() > new Date(this.currentEvent().updatedAt).getTime()) {
      this.updateEvent(event)
    }

    return event
  }

  public executePatch(eventVersionPatch: EventVersionPatch) {
    const currentEvent = this.eventSubject.getValue()
    PatchExecutor.patch(currentEvent.currentEventVersion, eventVersionPatch.patch)
    this.nextEvent({ ...currentEvent })
  }

  public setSessions(sessions: Session[]) {
    const currentEvent = this.eventSubject.getValue()
    currentEvent.currentEventVersion.sessions = sessions
    this.updateEvent(currentEvent)
  }

  private static determineMode(session: Session): SessionMode {
    const isVod = isSessionWithSessionMedia(session) && isVodSessionMedia(session.sessionMedia.current)
    const isLive = isSessionWithSessionMedia(session) && isLiveSessionMedia(session.sessionMedia.current)
    const isPast = isPastSession(ServerTimeService.get().currentServerTime(), session)
    return isPast || isVod ? 'demand' : (isLive ? 'live' : 'relive') as SessionMode
  }

  public static get(eventId: string, options: { selectedSession?: string; shouldSelectSession?: boolean; } = {}): EventService {
    // default `shouldSelectSession` to true for backwards compatability
    const opts = { selectedSession: options.selectedSession, shouldSelectSession: options.shouldSelectSession ?? true }

    if (!EventService.events[eventId]) {
      EventService.events[eventId] = new EventService(eventId)
      EventService.events[eventId].initEvent(opts)
    } else if (opts.selectedSession) {
      EventService.events[eventId]._ready?.then(() => {
        EventService.events[eventId].selectSession(opts.selectedSession)
      })
    }
    return EventService.events[eventId]
  }

  public static async selectSessionById(eventId: string, sessionId: string): Promise<void> {
    EventService.get(eventId, { selectedSession: sessionId })
  }

  public static queryEvents(query?: GetEventsQuery): Observable<GetEventsResponse> {
    return combineLatest([http.get('/events', { params: query }), LanguageService.language()]).pipe(map(([result, language]) => {
      const events = result.data
      for (const event of events.items) {
        LocalizeService.event(event, language.language)
      }
      return events
    }))
  }

  public static async init(): Promise<void> {
    LocationService.url().subscribe(async (url) => {
      const sessionId = url.searchParams.get('session')
      const parts = url.pathname.split('/')
      const eventIndex = parts.indexOf('events')
      const currentLastEventIdFromUrl = EventService._lastEventIdFromUrl
      const sessionIdParts = sessionId?.split('-')

      // we want to go to the live session, if there isnt a sessionId in the URL anymore
      if (EventService._lastEventIdFromUrl && !sessionId) {
        EventService.get(EventService._lastEventIdFromUrl).selectInitialSession()
      }

      if (eventIndex >= 0 && parts.length >= eventIndex + 2) {
        const eventId = parts[parts.indexOf('events') + 1]

        if (eventId) {

          if (sessionIdParts && sessionIdParts.length > 1) {
            EventService.selectSessionById(sessionIdParts[0], sessionIdParts[1])
          } else if (sessionId) {
            EventService.selectSessionById(eventId, sessionId)
          } else {
            EventService.get(eventId)
          }

          if (EventService._lastEventIdFromUrl !== eventId) {
            EventService._lastEventIdFromUrl = eventId

            const event = await EventService.get(eventId).eventAsPromise()

            if (event) {
              const isAppViewMode = detectAppViewMode()
              const currentLanguage = LanguageService.currentLanguage();
              const eventLanguages = event.currentEventVersion.languages
              LanguageService.updateSelectableLanguages(eventLanguages)

              if (!isAppViewMode && eventLanguages.length > 0 && currentLanguage && !eventLanguages.includes(currentLanguage.language)) {
                const language = eventLanguages.includes('en') ? 'en' : eventLanguages[0];

                LanguageService.selectLanguage(language)
              }

              Websocket.register<EventViewRegister>('eventview', { event: event._id, eventVersion: event.currentEventVersion._id })
            }
          }
        }
      } else {
        EventService._lastEventIdFromUrl = null
      }

      if (currentLastEventIdFromUrl && !EventService._lastEventIdFromUrl) {
        Websocket.unregister<EventViewUnregister>('eventview', {})
      }

    })

    let currentAuthUserId: string | null = AuthService.currentAuthUser()?._id || null
    AuthService.authUser().subscribe((user) => {
      const newUserId = user?._id || null
      if (newUserId !== currentAuthUserId) {
        currentAuthUserId = newUserId

        for (const eventId of Object.keys(EventService.events)) {
          EventService.events[eventId].refreshEvent(true)
        }
      }
    })

    Websocket.on<EventUpdate>('event:update', (event) => {
      if (this.events[event._id]) {
        this.events[event._id].updateEvent(event)
      }
    })

    Websocket.on<EventUpdatedAt>('event:updatedat', (eventUpdatedAt) => {
      if (this.events[eventUpdatedAt.event]) {
        const event = this.events[eventUpdatedAt.event].currentEvent()

        if (event?.updatedAt && new Date(event?.updatedAt).getTime() !== new Date(eventUpdatedAt.updatedAt).getTime()) {
          this.events[eventUpdatedAt.event].refreshEvent()
        }
      }
    })

    Websocket.on<EventVersionPatch>('eventversion:patch', (eventVersionPatch) => {
      if (this.events[eventVersionPatch.event]) {
        const currentEvent = this.events[eventVersionPatch.event].currentEvent()
        if (currentEvent.currentEventVersion._id === eventVersionPatch.eventVersion) {
          this.events[eventVersionPatch.event].executePatch(eventVersionPatch)
        }
      }
    })

    Websocket.on<EventVersionUpdate>('eventversion:update', (eventVersion) => {
      if (this.events[eventVersion.event]) {
        const currentEvent = this.events[eventVersion.event].currentEvent();

        if (currentEvent.currentEventVersion._id === eventVersion._id) {
          currentEvent.currentEventVersion = eventVersion;
          this.events[eventVersion.event].updateEvent({ ...currentEvent });
        }
      }
    })

    Websocket.on<EventSessions>('event:sessions', ({ event, sessions }) => {
      if (this.events[event]) {
        this.events[event].setSessions(sessions)
      }
    })
  }

}

export default EventService
