import Loadable from '@react-loadable/revised'

// global page loading state
import LoadingPage from '../../components/loading-screen/LoadingPage'

export const CorePage = Loadable({
  loader() {
    return import(/* webpackChunkName: "home" */ '../pages/content/CorePage')
  },
  loading: LoadingPage,
})

export const EventPage = Loadable({
  loader() {
    return import(/* webpackChunkName: "content" */ '../pages/content/EventPage')
  },
  loading: LoadingPage,
})

export const ExplorePage = Loadable({
  loader() {
    return import(/* webpackChunkName: "explore" */ '../pages/explore-page/ExplorePage')
  },
  loading: LoadingPage,
})

export const AssetPage = Loadable({
  loader() {
    return import(/* webpackChunkName: "asset" */ '../pages/asset-page/AssetPage')
  },
  loading: LoadingPage,
})

export const ReactionsPage = Loadable({
  loader() {
    return import(/* webpackChunkName: "reactions" */ '../pages/reactions/ReactionsPage')
  },
  loading: LoadingPage,
})

export const UserProfilePage = Loadable({
  loader() {
    return import(/* webpackChunkName: "user-profile" */ '../pages/user-profile/UserProfilePage')
  },
  loading: LoadingPage,
})

export const SearchPage = Loadable({
  loader() {
    return import(/* webpackChunkName: "search" */ '../pages/search-page/SearchPage')
  },
  loading: LoadingPage,
})

export const AppFallbackPage = Loadable({
  loader() {
    return import(/* webpackChunkName: "app-fallback" */ '../pages/app-fallback/AppFallbackPage')
  },
  loading: LoadingPage,
})

export const KeyTopicOverviewPage = Loadable({
  loader() {
    return import(
      /* webpackChunkName: "keytopic" */ '../pages/keytopic-overview/KeyTopicOverviewPage'
    )
  },
  loading: LoadingPage,
})

export const anonymousAccessPass = (url: string): RegExpMatchArray | null => {
  return url.match(/^\/(auth)/)
}
