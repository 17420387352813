/* eslint-disable no-underscore-dangle */
/* eslint-disable class-methods-use-this */
import { Subscription } from 'rxjs'
import { SourcePointConfiguration } from '../../../services/common/entities/Configuration'
import { AppPluginStore } from '../types'
import Plugin from '../Plugin'
import log from '../../../lib/logLevel'
import ConfigurationService from '../../services/Configuration'
import { configScript, mainScript } from './sourcepoint-script'
import { concatUrlPath } from '../../../utils/url'
import { setAgreementService } from '../../../utils/loadEventTracking'
import AuthService from '../../services/Auth'
import LayoutEditorService from '../../services/LayoutEditor'

class SourcepointPlugin extends Plugin {
  namespace = 'Sourcepoint'

  pluginStore!: AppPluginStore

  mainScript!: HTMLScriptElement

  configScript!: HTMLScriptElement

  messagingScript!: HTMLScriptElement

  config!: SourcePointConfiguration

  subscription?: Subscription | null = null

  shouldInstall(): boolean {
    const config = ConfigurationService.pluginsConfiguration()?.sourcepoint
    // don't install in layout editor mode
    return !LayoutEditorService.currentLayoutEditorMode() && config?.enabled && !!config?.id
  }

  getPluginName(): string {
    return `${this.namespace}@1.0.0`
  }

  getDependencies(): string[] {
    return []
  }

  init(pluginStore: AppPluginStore): void {
    this.pluginStore = pluginStore
  }

  loadScript(): void {
    if (!document.getElementById(this.config.id)) {
      const baseEndpoint = this.config.baseEndpoint || 'https://cdn.privacy-mgmt.com'
      const config = {
        accountId: this.config.id,
        baseEndpoint,
        targetingParams: this.config.targetingParams,
      }

      // main script
      this.mainScript = document.createElement('script')
      this.mainScript.setAttribute('type', 'text/javascript')
      this.mainScript.appendChild(mainScript())

      // config script
      this.configScript = document.createElement('script')
      this.configScript.setAttribute('type', 'text/javascript')
      this.configScript.appendChild(configScript(config))

      // wraper mesaging script
      this.messagingScript = document.createElement('script')
      this.messagingScript.setAttribute('type', 'text/javascript')
      this.messagingScript.setAttribute(
        'src',
        concatUrlPath(baseEndpoint, 'unified/wrapperMessagingWithoutDetection.js')
      )

      document.head.appendChild(this.mainScript)
      document.head.appendChild(this.configScript)
      document.head.appendChild(this.messagingScript)
      ;(window as any).__tcfapi('addEventListener', 2, (tcData: any, success: boolean) => {
        if (success) {
          ;(window as any).__tcfapi(
            'getCustomVendorConsents',
            2,
            (data: any, apiSuccess: boolean) => {
              const customAnalyticsVendorId = this.config.analyticsServiceId?.trim()
              if (!apiSuccess || !customAnalyticsVendorId) {
                return
              }

              const hasAnalyticsConsent =
                data?.grants?.[customAnalyticsVendorId]?.vendorGrant === true

              if (AuthService.currentAuthUser()) {
                setAgreementService(hasAnalyticsConsent)
              } else {
                this.subscription?.unsubscribe()
                this.subscription = AuthService.authUser().subscribe((authUser) => {
                  if (authUser) {
                    this.subscription?.unsubscribe()
                    setAgreementService(hasAnalyticsConsent)
                  }
                })
              }
            }
          )
        }
      })
    }
  }

  activate(): void {
    log.info(`${this.namespace}:: activate`)

    this.config = ConfigurationService.pluginsConfiguration()?.sourcepoint

    if (this.config?.id) {
      this.loadScript()
    }

    this.ready()
  }

  deactivate(): void {
    log.log(`${this.namespace}:: deactivate`)
    if (this.mainScript && this.mainScript.parentNode) {
      document.head.removeChild(this.mainScript)
    }
    if (this.configScript && this.configScript.parentNode) {
      document.head.removeChild(this.configScript)
    }
    if (this.messagingScript && this.messagingScript.parentNode) {
      document.head.removeChild(this.messagingScript)
    }
  }
}

export default new SourcepointPlugin()
