/* eslint-disable import/prefer-default-export */
import appConfiguration from 'app/config/app'

export const detectAppViewMode = (): boolean => {
  const urlParams = new URLSearchParams(window.location.search)
  const urlViewMode = urlParams.get(appConfiguration.urlParamName)

  return (
    localStorage.getItem('viewMode') === 'app' || urlViewMode === appConfiguration.urlParamValue
  )
}
