import environment from '../environments/environment'
import PostAcceptAgreements from '../services/auth/PostAcceptAgreements'
import ucLanguageSwitch from '../lib/usercentrics'
import i18n from '../locales/i18n'
import isDev from './isDev'

// eslint-disable-next-line import/no-cycle
import UsercentricsPlugin from '../app/plugins/usercentrics/UsercentricsPlugin'
// eslint-disable-next-line import/no-cycle
import UsercentricsV2Plugin from '../app/plugins/usercentrics-v2/UsercentricsV2Plugin'

export const loadEventTracking = (): void => {
  window.usercentrics = window.usercentrics || {}
  window.addEventListener('onConsent', (event: any) => {
    if (event.data.event === 'consents_changed_finished') {
      const isAnyConsentRejected = Object.keys(event.data).some((key) => event.data[key] === false)
      if (isAnyConsentRejected) {
        window.location.reload()
      } else if (event.data[environment.eventService.usercentricsConsentString] !== undefined) {
        const hasConsent = event.data[environment.eventService.usercentricsConsentString]
        setAgreementService(hasConsent)
      }
    }
  })

  // Beispielcode aus der DOKU warum so?
  if (window.usercentrics.isInitialized) {
    // eslint-disable-next-line no-console
  } else {
    window.usercentrics.onViewInit = (): void => {
      const eventService = window.usercentrics.getConsents(
        environment.eventService.usercentricsTemplateId
      )
      setAgreementService(eventService.consentStatus)

      setTimeout(() => {
        const ucLang = window.usercentrics.settings.language
        if (i18n.language && ucLang !== i18n.language) {
          ucLanguageSwitch(i18n.language)
        }
      }, 250)
    }
  }
}

export const setAgreementService = (hasConsent: boolean): void => {
  PostAcceptAgreements({ analytics: hasConsent })
    .then(() => {
      if (isDev) console.warn(`PostAcceptAgreements analytics: ${hasConsent}`)
    })
    .catch((error) => {
      if (isDev) console.error(error)
    })
}

export const getEventTrackingStatus = (): boolean => {
  if (UsercentricsPlugin.shouldInstall()) {
    const { usercentrics } = window
    if (usercentrics && 'getConsents' in usercentrics) {
      const eventService = window.usercentrics.getConsents(
        environment.eventService.usercentricsTemplateId
      )
      return eventService.consentStatus || false
    }
  } else if (UsercentricsV2Plugin.shouldInstall()) {
    const serviceId = UsercentricsV2Plugin.config.analyticsServiceId
    const analyticsService = window.UC_UI?.getServicesBaseInfo().find(
      (service: any) => service.id === serviceId
    )
    return analyticsService?.consent?.status === true
  }
  return false
}
export default loadEventTracking
