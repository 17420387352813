/* eslint-disable no-async-promise-executor */
import { map, pairwise } from 'rxjs/operators'
import EventService from './Event'
import LanguageService from './Language'
import LocationService from './Location'
import NewsFeedService from './NewsFeed'
import SelectedEventService from './SelectedEvent'
import ServerTimeService from './ServerTime'
import LayoutEditorService from './LayoutEditor'
import ConfigurationService from './Configuration'
import AuthService from './Auth'
import VideoSliderDeepLinkService from './VideoSliderDeepLink'
import { pluginsReady } from '../plugins'
import EventRegistrationService from './EventRegistration'
import AzureAdService from './auth/AzureAd'
import VideoProgressService from './VideoProgress'
import TrackingService from './Tracking'
import { getEventRouteMatch } from '../router/routes'
import GeoIpService from './GeoIp'

export type ServiceState = 'loading' | 'ready' | 'error'

// This control has to be initialized before React
export default new Promise<ServiceState>((resolve: (result: ServiceState) => void, _reject: (err?: any) => void) => {
  Promise.all([
    ServerTimeService.init(),
    LanguageService.init(),
    ConfigurationService.init(),
    LocationService.init(),
    AuthService.init(),
    VideoSliderDeepLinkService.init(),
    LayoutEditorService.init(),
    TrackingService.init(),
    NewsFeedService.init(),
    GeoIpService.init()
  ]).then(async () => {
    await pluginsReady()
    await AuthService.checkLogin()
    await AuthService.checkParentLogin()


    await Promise.all([
      EventService.init(),
      EventRegistrationService.init(),
      VideoProgressService.init(),
    ])

    await Promise.all([SelectedEventService.init()])

    if (LocationService.currentUrl().searchParams.get('login')?.toLowerCase() === 'azuread') {
      LocationService.removeQueryParam('login')

      if (!AuthService.currentAuthUser()) {
        await AzureAdService.login()
        return
      }
    }

    const singleEvent = ConfigurationService.platformConfiguration()?.singleEvent;
    const languageViaCountry = ConfigurationService.platformConfiguration().languageAndCountryMode === 'languageViaCountry'
    LanguageService.checkForRedirects(singleEvent, languageViaCountry)
    LanguageService.languageViaCountrySubject.next(languageViaCountry)

    EventRegistrationService.lastEventUpdate().subscribe((eventId) => {
      if (typeof eventId === 'string') {
        EventService.get(eventId)?.refreshEvent(true)
      }
    })

    LocationService.url().pipe(
      map((url) => getEventRouteMatch(url.pathname)),
      pairwise()
    ).subscribe(([previousMatch, currentMatch]) => {
      const hasLeftEventPage = previousMatch && !currentMatch

      if (hasLeftEventPage) {
        LanguageService.applyPlatformLanguage()
      }
    })

  }).then(() => {
    resolve('ready')
  }).catch(() => {
    resolve('error')
  })
})
