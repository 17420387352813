import { LanguageDetectorModule, Services, InitOptions } from 'i18next'

// detections
import getNavigatorLocales from './browserLookups/navigator-locales'
import getQueryLocales from './browserLookups/query-param-locales'
import getPathLocales from './browserLookups/path-locales'

type BrowserLanguageDetectorOpts = {
  order: Array<string>
  lookupLocalStorage: string
  lookupQuerystring: string
  services?: Services
  i18nextOptions?: InitOptions
}

const browserLanguageDetectorOpt: BrowserLanguageDetectorOpts = {
  order: ['path', 'localStorage', 'navigator', 'querystring', 'htmlTag'],
  lookupLocalStorage: '365PlatformLng',
  lookupQuerystring: 'lang',
}

// @see 'i18next-browser-languagedetector' (based on it)
const browserLanguageDetector: LanguageDetectorModule = {
  type: 'languageDetector',
  init(
    services: Services,
    detectorOptions: BrowserLanguageDetectorOpts,
    i18nextOptions: InitOptions
  ): void {
    browserLanguageDetectorOpt.order = detectorOptions.order
    browserLanguageDetectorOpt.lookupLocalStorage = detectorOptions.lookupLocalStorage
    browserLanguageDetectorOpt.services = services
    browserLanguageDetectorOpt.i18nextOptions = i18nextOptions
  },
  detect(): string {
    const { fallbackLng } = browserLanguageDetectorOpt.i18nextOptions || { fallbackLng: 'en' }
    let lng: string | undefined | null

    for (let i = 0; i < browserLanguageDetectorOpt.order.length; i += 1) {
      if (!lng && browserLanguageDetectorOpt.order[i] === 'path') {
        lng = getPathLocales()
        if (lng) {
          break
        }
      }

      if (browserLanguageDetectorOpt.order[i] === 'localStorage') {
        lng = localStorage.getItem(browserLanguageDetectorOpt.lookupLocalStorage) as string
        if (lng) {
          break
        }
      }

      if (!lng && browserLanguageDetectorOpt.order[i] === 'navigator') {
        lng = getNavigatorLocales()
        if (lng) {
          break
        }
      }
      if (!lng && browserLanguageDetectorOpt.order[i] === 'querystring') {
        lng = getQueryLocales(browserLanguageDetectorOpt.lookupQuerystring)
        if (lng) {
          break
        }
      }
    }

    if (!lng) {
      lng = fallbackLng as string
    }

    return browserLanguageDetectorOpt.services?.languageUtils.getBestMatchFromCodes([lng]) ?? lng
  },
  cacheUserLanguage(lng: string): void {
    localStorage.setItem(browserLanguageDetectorOpt.lookupLocalStorage, lng)
  },
}

export default browserLanguageDetector
