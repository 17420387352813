/* eslint-disable class-methods-use-this */
import { UsercentricsConfiguration } from '../../../services/common/entities/Configuration'
import { AppPluginStore } from '../types'
import Plugin from '../Plugin'
import log from '../../../lib/logLevel'
// eslint-disable-next-line import/no-cycle
import { loadEventTracking } from '../../../utils/loadEventTracking'
import ConfigurationService from '../../services/Configuration'

declare global {
  interface Window {
    usercentrics: any
  }
}

class UsercentricsPlugin extends Plugin {
  namespace = 'Usercentrics'

  pluginStore!: AppPluginStore

  scriptEl!: HTMLScriptElement

  config!: UsercentricsConfiguration

  scriptUrl = 'https://app.usercentrics.eu/latest/main.js'

  id!: string

  shouldInstall(): boolean {
    const config = ConfigurationService.pluginsConfiguration()?.usercentrics
    return config?.enabled && !!config?.id
  }

  getPluginName(): string {
    return `${this.namespace}@1.0.0`
  }

  getDependencies(): string[] {
    return []
  }

  init(pluginStore: AppPluginStore): void {
    this.pluginStore = pluginStore
  }

  loadScript(): void {
    if (!document.getElementById(this.config.id)) {
      this.scriptEl = document.createElement('script')
      this.scriptEl.setAttribute('type', 'application/javascript')
      this.scriptEl.setAttribute('src', this.config.scriptUrl || this.scriptUrl)
      this.scriptEl.setAttribute('id', this.config.id)
      document.head.appendChild(this.scriptEl)

      this.scriptEl.addEventListener('load', () => {
        loadEventTracking()
      })
    }
  }

  activate(): void {
    log.info(`${this.namespace}:: activate`)

    this.config = ConfigurationService.pluginsConfiguration()?.usercentrics

    if (this.config?.id) {
      this.loadScript()
    }

    this.ready()
  }

  deactivate(): void {
    log.log(`${this.namespace}:: deactivate`)
    if (this.scriptEl && this.scriptEl.parentNode) {
      document.head.removeChild(this.scriptEl)
    }
  }
}

export default new UsercentricsPlugin()
