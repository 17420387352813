import { BrowserCacheLocation, Configuration, LogLevel, RedirectRequest } from '@azure/msal-browser'
import environment from '../../environments/environment'

const system: Configuration['system'] = {
  asyncPopups: true,
  loggerOptions: {
    logLevel: process.env.NODE_ENV === 'production' ? LogLevel.Error : LogLevel.Verbose,
    loggerCallback: (level: LogLevel, message: string, containsPii: boolean): void => {
      if (containsPii) {
        return
      }
      // eslint-disable-next-line default-case
      switch (level) {
        case LogLevel.Error:
          console.error(message)
          break
        case LogLevel.Info:
          // eslint-disable-next-line no-console
          console.info(message)
          break
        case LogLevel.Verbose:
          // eslint-disable-next-line no-console
          console.debug(message)
          break
        case LogLevel.Warning:
          console.warn(message)
          break
      }
    },
  },
}

export type MSALConfiguration = {
  config: Configuration
  loginRedirectRequest: RedirectRequest
  registerRedirectRequest: RedirectRequest
}

export const defaultMSALConfiguration: MSALConfiguration = {
  /**
   * Configuration class for @azure/msal-browser:
   * https://azuread.github.io/microsoft-authentication-library-for-js/ref/msal-browser/modules/_src_config_configuration_.html
   * https://asys365platform.b2clogin.com/2c3f110c-60c5-492d-bb33-19e09cedb444/oauth2/v2.0/authorize?p=B2C_1_si&client_id=bf07f712-f5ad-4420-8deb-5d6535dc4c0e&nonce=defaultNonce&redirect_uri=https%3A%2F%2Fqa.asys365platform.com&scope=openid&response_type=id_token&prompt=login
   */
  config: {
    // https://azuread.github.io/microsoft-authentication-library-for-js/ref/modules/_azure_msal_browser.html#browserauthoptions
    auth: {
      clientId: environment.azureAd?.clientId || '',
      authority: environment.azureAd?.authority,
      redirectUri: window.location.origin,
      postLogoutRedirectUri: window.location.origin,
      knownAuthorities: environment.azureAd?.knownAuthorities || [],
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
    },
    system,
  },
  loginRedirectRequest: {
    scopes: ['openid'],
  },
  registerRedirectRequest: {
    scopes: ['openid'],
    authority: environment.azureAd?.authorityRegister,
  },
}

export const appMSALConfiguration: MSALConfiguration = {
  config: {
    auth: {
      clientId: environment.appAzureAd?.clientId || '',
      authority: environment.appAzureAd?.authority,
      redirectUri: window.location.origin,
      postLogoutRedirectUri: window.location.origin,
      knownAuthorities: environment.appAzureAd?.knownAuthorities || [],
    },
    system,
  },
  loginRedirectRequest: {
    scopes: ['openid'],
  },
  registerRedirectRequest: {
    scopes: ['openid'],
    authority: environment.appAzureAd?.authorityRegister,
  },
}
