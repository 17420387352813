// just write the UTM Params from URL to user localStorage
const utmQuery = decodeURIComponent(document.location.search.substring(1))
const utmVariables = utmQuery.split('&')
const utmParams = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_content', 'utm_term'] as const

// eslint-disable-next-line consistent-return
const getUTMValue = (inputParameter: string) => {
  for (let i = 0; i < utmVariables.length; i += 1) {
    const ParameterName = utmVariables[i].split('=')
    if (ParameterName[0] === inputParameter) {
      return ParameterName[1] === null ? null : ParameterName[1]
    }
  }
}

const valueExists = (value: any): value is string => {
  return value !== null && value !== '' && value !== undefined
}

const utmNewFlag = (param: string) => `${param}-is-new`

export const setUTM = (): void => {
  utmParams.forEach((param) => {
    const pValue = getUTMValue(param)

    if (valueExists(pValue)) {
      localStorage.setItem(param, pValue as string)
      localStorage.setItem(utmNewFlag(param), 'true')
    }
  })
}

export const getUTMsAsObject = (): Record<string, string> => {
  const UTMs: Record<string, string> = {}

  utmParams.forEach((param) => {
    const pValue = getUTMValue(param)

    if (valueExists(pValue)) {
      UTMs[param] = pValue
    }
  })

  return UTMs
}

export const getUTM = (): string => {
  const userUTM: string[] = []

  utmParams.forEach((param) => {
    const pValue = localStorage.getItem(param)

    if (valueExists(pValue)) {
      userUTM.push(`${param}=${pValue}`)
    }
  })

  return userUTM.join('&')
}

export const getNewUTMs = (): string => {
  const userUTM: string[] = []

  utmParams.forEach((param) => {
    const pValue = localStorage.getItem(param)
    const isNew = localStorage.getItem(utmNewFlag(param))

    if (valueExists(pValue) && isNew === 'true') {
      userUTM.push(`${param}=${pValue}`)
    }
  })

  return userUTM.join('&')
}

export const markUTMsAsOld = (): void => {
  utmParams.forEach((param) => localStorage.setItem(utmNewFlag(param), 'false'))
}
