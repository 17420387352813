import { Poll } from './Poll';

export const messageTypes = ['TextMessage', 'PollMessage'] as const;
export type MessageType = (typeof messageTypes)[number];

export type MessageState = 'OPEN' | 'CONFIRMED' | 'REJECTED' | 'DELETED';

export interface MessageUser {
  _id?: string;
  firstName?: string;
  lastName?: string;
  trackingId?: string;
}

export interface Message {
  _id: string;
  replyTo?: string;
  originalMessage?: Message | null;
  trackingId?: string;
  messageType: MessageType;
  messageState?: MessageState;

  expert: boolean;

  chat: string;
  user: MessageUser | undefined;
  date: string;
  createdAt: string;
  adminUser?: string;

  counter: number;

  confirmed: boolean;
  confirmedBy?: string;
  confirmedAt?: string;
  deletedAt?: string;

  data: any;
}

export interface TextMessageData {
  text: string;
}

export interface TextMessage extends Message {
  messageType: 'TextMessage';
  messageState: MessageState;
  rejectionReason?: string;
  likes: number;
  data: TextMessageData;
}

export interface DeleteMessage {
  _id: string;
}

export interface PollMessageAnswer {
  option: string;
  count?: number;
}

export interface PollMessage extends Message {
  messageType: 'PollMessage';
  data: {
    poll: Poll;
    answers?: PollMessageAnswer[];
    users?: number;
  };
  answered?: boolean;
}

export function isTextMessage(object: any): object is TextMessage {
  return object.messageType === 'TextMessage';
}

export function isPollMessage(object: any): object is PollMessage {
  return object.messageType === 'PollMessage';
}
