import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useQueries, useQuery, UseQueryOptions, UseQueryResult } from 'react-query'
import { GetMapResponse, GetMapResponseError } from '../common/api/v1/configuration/maps/GetMap'
import LocalizeService from '../../app/services/Localize'
import Cache from '../Cache'
import http from '../api'

const cache = new Cache<GetMapResponse | GetMapResponseError>()

export async function GetMap(mapId: string): Promise<GetMapResponse | GetMapResponseError> {
  if (cache.has(mapId)) {
    return cache.get(mapId) as GetMapResponse | GetMapResponseError
  }

  return http
    .get(`configuration/maps/${mapId}`)
    .then((res) => res.data)
    .then((map) => {
      cache.set(mapId, map)
      return map
    })
}

export async function GetLocalizedMap(
  mapId: string,
  language: string
): Promise<GetMapResponse | GetMapResponseError> {
  return GetMap(mapId).then((map) => {
    if ('errorTag' in map) {
      return map
    }

    return LocalizeService.map(map, language)
  })
}

export const useGetMap = (mapId: string): UseQueryResult<GetMapResponse, GetMapResponseError> => {
  const { i18n } = useTranslation()
  const result = useQuery(`configuration/maps/${mapId}`, () => GetMap(mapId))

  const data = useMemo(() => {
    if (!result.data || 'errorTag' in result.data) {
      return result.data
    }

    return LocalizeService.map(result.data, i18n.language)
  }, [result.data, i18n.language])

  return { ...result, data } as UseQueryResult<GetMapResponse, GetMapResponseError>
}

export const useGetMaps = (
  mapIds: string[]
): UseQueryResult<GetMapResponse, GetMapResponseError>[] => {
  const { i18n } = useTranslation()

  const options: UseQueryOptions[] = mapIds.map((mapId) => ({
    queryKey: `configuration/maps/${mapId}`,
    queryFn: () => GetMap(mapId),
  }))

  const results = useQueries(options) as UseQueryResult<GetMapResponse, GetMapResponseError>[]

  const localizedResults = useMemo(() => {
    return results.map((result) => {
      if (!result.data || 'errorTag' in result) {
        return result
      }

      LocalizeService.map(result.data, i18n.language)

      return result
    })
  }, [results, i18n.language])

  return localizedResults as UseQueryResult<GetMapResponse, GetMapResponseError>[]
}

export default useGetMap
