import http from '../api'
import {
  PostAcceptAgreementsBody,
  PostAcceptAgreementsResponse,
  PostAcceptAgreementsResponseError,
} from '../common/api/v1/auth/PostAcceptAgreements'

function PostAcceptAgreements(
  postData: PostAcceptAgreementsBody
): Promise<PostAcceptAgreementsResponse | PostAcceptAgreementsResponseError> {
  return http.post('/auth/acceptagreements', postData).then((response) => response.data)
}

export default PostAcceptAgreements
