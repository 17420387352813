import { IPlugin } from "../../plugin/IPlugin"
import { PageModule } from "./PageModule"

export type CustomPageModule = {
  pageModuleType: 'Custom',
  pageModules: PageModule[],
  error?: string,
  customPageModuleType: string,
  plugin: IPlugin,
  configuration: {[key: string]: string},
} & Omit<PageModule, 'pageModuleType' | 'backgroundTheme' | 'achnor' | 'paddingTop' | 'paddingRight' | 'paddingBottom' | 'paddingLeft'>

export function isCustomPageModule(pageModule: PageModule): pageModule is CustomPageModule {
  return pageModule.pageModuleType === 'Custom'
}

export type CustomPageModuleType = {
  customPageModuleType: string,
  fields: {
    name: string,
    type: 'string',
    options?: string[],
  }[]
}