import { EventVersion } from './EventVersion';

//export type EventType = "DigitalEvent" | "Webinar" | "HybridEvent" | "OnSiteEvent";
export interface Event {
  _id: string;
  internalName: string;
  eventType: string;
  shortId: string;
  public: boolean;
  country?: string;
  contactmail?: string[];
  hideLoginButton?: boolean;
  hideLanguageSelector?: boolean;
  hideBeaconIcon?: boolean;
  showBackButton?: boolean;

  announcedAt: string;
  releasedAt: string;

  updatedAt: string;
  updatedBy: string;
  deletedAt: string;
  deletedBy: string;

  totalVersions: number;
  currentEventVersion: EventVersion;

  totalStartAt: string; // earliest local start date
  totalEndAt: string; // latest local end date

  // base64 encodes AccessPolicyKeys (including the md5 hash keys)
  accessPolicyKeys: string[];

  /**
   * @deprecated
   */
  chats: { [region: string]: string };

  /**
   * @deprecated
   */
  promoted: boolean;

  /**
   * @deprecated
   */
  regions: string[];
  externalEvent?: {
    externalEventId: string;
    url: string;
    updatedAt: string | Date;
  };
  eventSerie?: string;
  lastPublishedAt?: Date;
}

function calculateLocalDate(timeZone: string, date: Date): string | null {
  if (!date.getTime()) {
    console.warn('Invalid Date', date);
    return null;
  }
  const dateParts = Intl.DateTimeFormat('en-US', { timeZone: timeZone }).format(date).split('/');
  return `${dateParts[2]}-${dateParts[0].padStart(2, '0')}-${dateParts[1].padStart(2, '0')}`;
}

export type SessionMode = 'demand' | 'live' | 'relive';

export const eventPhases = ['ANNOUNCED', 'RELEASED', 'LIVE', 'ARCHIVE', 'UNKNOWN'] as const;
export type EventPhase = (typeof eventPhases)[number];

export function eventPhase(event: Event, date: Date = new Date()): EventPhase {
  if (!event || event.promoted) return 'ARCHIVE';

  if (event.currentEventVersion?.local?.current) {
    const timeZone = event.currentEventVersion.eventTimeZone || 'Europe/Berlin';
    const localDate = calculateLocalDate(timeZone, date);

    if (!localDate) {
      return 'UNKNOWN';
    }

    if (event.currentEventVersion?.local.current?.endAt) {
      const endAt = new Date(event.currentEventVersion.local.current.endAt);
      const localEndAt = calculateLocalDate(timeZone, endAt);

      if (!localEndAt) {
        return 'UNKNOWN';
      }

      if (localDate > localEndAt) return 'ARCHIVE';
    }

    if (event.currentEventVersion?.local.current?.startAt) {
      const startAt = new Date(event.currentEventVersion.local.current.startAt);
      const localStartAt = calculateLocalDate(timeZone, startAt);

      if (!localStartAt) {
        return 'UNKNOWN';
      }

      if (localDate >= localStartAt) return 'LIVE';
    }
  }

  if (event.releasedAt && new Date(event.releasedAt) < date) return 'RELEASED';
  if (event.announcedAt && new Date(event.announcedAt) < date) return 'ANNOUNCED';

  return 'UNKNOWN';
}

// export function isDigitalEvent(object: any): object is DigitalEvent {
//   return object?.eventType === 'DigitalEvent';
// }

// export function isOnSiteEvent(object: any): object is OnSiteEvent {
//   return object?.eventType === 'OnSiteEvent';
// }

// export function isHybridEvent(object: any): object is HybridEvent {
//   return object?.eventType === 'HybridEvent';
// }

// export function isWebinar(object: any): object is Webinar {
//   return object?.eventType === 'Webinar';
// }
