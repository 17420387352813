import { Stream } from './Stream';
import { Event } from './Event';
import { Asset, VideoAsset } from './Asset';
import { RelatedContent } from './RelatedContent';

export const sessionTypes = ['SpeakerSession', 'BreakoutSession'] as const;
export type SessionType = (typeof sessionTypes)[number];

export const speakerRoles = ['MainSpeaker', 'AdditionalSpeaker'] as const;
export type SpeakerRole = (typeof speakerRoles)[number];

export interface Session {
  _id: string;
  sessionType: SessionType;
  event: string | Event;

  // deprecated
  regional: { [region: string]: SessionRegion };

  local: { [language: string]: SessionLanguage };

  startAt: string;
  endAt: string;

  actualStartAt: string;
  actualEndAt: string;

  highlight: boolean;
  mainStageHighlight: boolean; // deprecated
  hidden: boolean;
  skippable?: boolean;

  tags: string[];
  stages: string[];

  keytopics: string[];

  images: {
    main: Asset;
  };
  maxChatMessages: number;
}

export interface SessionRegion {
  title: string;
  description: string;
  highlightTeaser: string;

  relatedContent: RelatedContent[];
}

export interface SessionLanguage {
  title: string;
  description: string;
  highlightTeaser: string;
  onDemandOnly: boolean;

  relatedContent: RelatedContent[];
  chat?: string;
}

export interface SpeakerSession extends Session {
  sessionType: 'SpeakerSession';
  sessionMedia: { [region: string]: SessionMedia };
  speakers: SpeakerRoleAssignment[];
}

export interface BreakoutSession extends Session {
  sessionType: 'BreakoutSession';
  sessionMedia: { [language: string]: SessionMedia };
  speakers: SpeakerRoleAssignment[];
  breakouts: Breakout[];
}

export interface SessionWithVideoAsset extends Session {
  sessionMedia: { [language: string]: SessionMediaWithAsset };
}

export interface Breakout {
  _id: string;
  asset?: VideoAsset;
  link: string;
  speakers: string[];
  keytopics: string[];
  local: { [language: string]: BreakoutLanguage };
}

export interface BreakoutLanguage {
  shortTitle: string;
  title: string;
  description: string;
}

export interface SpeakerRoleAssignment {
  role: SpeakerRole;
  speaker: string;
}

export const sessionMediaTypes = ['VodSessionMedia', 'LiveSessionMedia', 'ReLiveSessionMedia'] as const;
export type SessionMediaType = (typeof sessionMediaTypes)[number];

export interface SessionMedia {
  sessionMediaType: SessionMediaType;
  asset?: VideoAsset;
}

export interface VodSessionMedia extends SessionMedia {
  sessionMediaType: 'VodSessionMedia';
  asset: VideoAsset;
}

export interface LiveSessionMedia extends SessionMedia {
  sessionMediaType: 'LiveSessionMedia';
  stream: Stream;
  asset?: VideoAsset;
}

export interface ReLiveSessionMedia extends SessionMedia {
  sessionMediaType: 'ReLiveSessionMedia';
  asset: VideoAsset;
}

export interface SessionMediaWithAsset extends SessionMedia {
  asset: VideoAsset;
}

export function isSessionWithSessionMedia(object: any): object is { sessionMedia: { [region: string]: SessionMedia } } {
  return isSpeakerSession(object) || isBreakoutSession(object);
}

export function isSpeakerSession(object: any): object is SpeakerSession {
  return object?.sessionType === 'SpeakerSession';
}

export function isBreakoutSession(object: any): object is BreakoutSession {
  return object?.sessionType === 'BreakoutSession';
}

export function isVodSessionMedia(object: any): object is VodSessionMedia {
  return object?.sessionMediaType === 'VodSessionMedia';
}

export function isLiveSessionMedia(object: any): object is LiveSessionMedia {
  return object?.sessionMediaType === 'LiveSessionMedia';
}

export function isReLiveSessionMedia(object: any): object is ReLiveSessionMedia {
  return object?.sessionMediaType === 'ReLiveSessionMedia';
}

export function isSessionMediaWithAsset(object: any): object is { asset: Asset } {
  return isVodSessionMedia(object) || isLiveSessionMedia(object) || isReLiveSessionMedia(object);
}

export function isSessionWithSpeakers(object: any): object is { speakers: SpeakerRoleAssignment[] } {
  return isSpeakerSession(object) || isBreakoutSession(object);
}
