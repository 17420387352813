import { Dayjs } from 'dayjs'
import create from 'zustand'
import { Session } from '../../services/common/entities/Session'

export enum ThreeColumnGridMQType {
  PHONE = 'PHONE',
  TABLET = 'TABLET',
  OTHERS = 'OTHERS',
}

export type StreamStoreProps = {
  threeColGridMqType: ThreeColumnGridMQType
  isOnLatestVideo: boolean
  isUserPaused: boolean
  isUserMuted: boolean
  selectedSession: Session | undefined
  sessionChangedOnUserInteraction: boolean
  firstLiveSessionNotYetStarted: boolean
  lastLiveSessionIsOver: boolean
  streamEndedPrematurely: boolean
  firstSessionBeginsAt: Dayjs | undefined
  nextSessionBeginsAt: Dayjs | undefined
  currentStreamingStartAt: number | undefined
  setThreeColGridMqType(mqType: ThreeColumnGridMQType): void
  setIsOnLatestVideo(value: boolean): void
  setIsUserPaused(value: boolean): void
  setIsUserMuted(value: boolean): void
  setSelectedSession(session: Session | undefined): void
  setSessionChangedOnUserInteraction(value: boolean): void
  setFirstLiveSessionNotYetStarted(value: boolean): void
  setLastLiveSessionIsOver(value: boolean): void
  setStreamEndedPrematurely(value: boolean): void
  setFirstSessionBeginsAt(value: Dayjs | undefined): void
  setNextSessionBeginsAt(value: Dayjs | undefined): void
  setCurrentStreamingStartAt(value: number | undefined): void
}

/**
 * @deprecated The store should not be used: use app/services/*
 */
export const useStreamStore = create<StreamStoreProps>((set) => ({
  threeColGridMqType: ThreeColumnGridMQType.OTHERS,
  isOnLatestVideo: true,
  isUserPaused: false,
  isUserMuted: false,
  selectedSession: undefined,
  sessionChangedOnUserInteraction: false,
  firstLiveSessionNotYetStarted: true,
  lastLiveSessionIsOver: false,
  streamEndedPrematurely: false,
  firstSessionBeginsAt: undefined,
  nextSessionBeginsAt: undefined,
  currentStreamingStartAt: undefined,
  setThreeColGridMqType(mqType: ThreeColumnGridMQType) {
    set((state) => ({
      ...state,
      threeColGridMqType: mqType,
    }))
  },
  setIsOnLatestVideo: (value) => {
    set((state) => ({
      ...state,
      isOnLatestVideo: value,
    }))
  },
  setIsUserPaused: (value) => {
    set((state) => ({
      ...state,
      isUserPaused: value,
    }))
  },
  setIsUserMuted: (value) => {
    set((state) => ({
      ...state,
      isUserMuted: value,
    }))
  },
  setSelectedSession: (session) => {
    set((state) => ({
      ...state,
      selectedSession: session,
    }))
  },
  setSessionChangedOnUserInteraction: (value: boolean) => {
    set((state) => ({
      ...state,
      sessionChangedOnUserInteraction: value,
    }))
  },
  setFirstLiveSessionNotYetStarted: (value: boolean) => {
    set((state) => ({
      ...state,
      firstLiveSessionNotYetStarted: value,
    }))
  },
  setLastLiveSessionIsOver: (value: boolean) => {
    set((state) => ({
      ...state,
      lastLiveSessionIsOver: value,
    }))
  },
  setStreamEndedPrematurely: (value: boolean) => {
    set((state) => ({
      ...state,
      streamEndedPrematurely: value,
    }))
  },
  setFirstSessionBeginsAt: (value: Dayjs | undefined) => {
    set((state) => ({
      ...state,
      firstSessionBeginsAt: value,
    }))
  },
  setNextSessionBeginsAt: (value: Dayjs | undefined) => {
    set((state) => ({
      ...state,
      nextSessionBeginsAt: value,
    }))
  },
  setCurrentStreamingStartAt: (value: number | undefined) => {
    set((state) => ({
      ...state,
      currentStreamingStartAt: value,
    }))
  },
}))
