/* eslint-disable class-methods-use-this */
import { FacebookPixelConfiguration } from '../../../services/common/entities/Configuration'
import { AppPluginStore } from '../types'
import Plugin from '../Plugin'
import { facebookPixelScript, facebookPixelNoScript } from './facebook-pixel-script'
import log from '../../../lib/logLevel'

import ConfigurationService from '../../services/Configuration'

class FacebookPixelPlugin extends Plugin {
  namespace = 'FacebookPixel'

  pluginStore!: AppPluginStore // definite assignment assertions

  script!: HTMLScriptElement

  config!: FacebookPixelConfiguration

  shouldInstall(): boolean {
    const config = ConfigurationService.pluginsConfiguration()?.facebookPixel
    return config?.enabled && !!config?.id
  }

  getPluginName(): string {
    return `${this.namespace}@1.0.0`
  }

  getDependencies(): string[] {
    return []
  }

  init(pluginStore: AppPluginStore): void {
    this.pluginStore = pluginStore
  }

  loadScript(): void {
    log.info(`${this.namespace}:: load script`)

    if (this.config.id && !document.getElementById(this.config.id)) {
      this.script = document.createElement('script')
      this.script.appendChild(facebookPixelScript(this.config.id))
      document.head.appendChild(this.script)

      const noscript = document.createElement('noscript')
      this.script.appendChild(facebookPixelNoScript(this.config.id))
      document.head.appendChild(noscript)

      log.log(`${this.namespace}:: script created`)
    }
  }

  activate(): void {
    this.config = ConfigurationService.pluginsConfiguration()?.facebookPixel

    if (this.config?.id) {
      this.loadScript()
    }

    this.ready()
  }

  deactivate(): void {
    log.log(`${this.namespace}:: deactivate`)
    if (this.script && this.script.parentNode) {
      document.head.removeChild(this.script)
    }
  }
}

export default new FacebookPixelPlugin()
