const getNavigatorLocales = (): string => {
  const browserLocales =
    navigator.languages === undefined ? [navigator.language] : navigator.languages

  if (!browserLocales) {
    return 'en'
  }

  return browserLocales.map((locale) => {
    const trimmedLocale = locale.trim()

    return trimmedLocale.split(/-|_/)[0]
  })[0]
}

export default getNavigatorLocales
