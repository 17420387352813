import { Patch } from './Patch';
import * as jp from 'jsonpath';

export class PatchExecutor {
  public static resolveJsonpath(jsonpath: string, jsonpathParams: { [key: string]: any }): string {
    for (const key of Object.keys(jsonpathParams)) {
      jsonpath = jsonpath.replace(new RegExp(`\\$${key}`, 'g'), jsonpathParams[key]);
    }

    return jsonpath;
  }

  public static jsonpath(patch: Patch): string {
    let jsonpath: string = patch.jsonpath;
    const params = patch.jsonpathParams || {};

    return this.resolveJsonpath(jsonpath, params);
  }

  public static patch<T extends object>(obj: T, patch: Patch): boolean {
    try {
      switch (patch.command) {
        case 'set':
          return PatchExecutor.set(obj, patch);
        case 'delete':
          return PatchExecutor.delete(obj, patch);
        case 'push':
          return PatchExecutor.push(obj, patch);
        case 'up':
          return PatchExecutor.up(obj, patch);
        case 'down':
          return PatchExecutor.down(obj, patch);
        case 'splice':
          return PatchExecutor.splice(obj, patch);
      }
    } catch (err) {
      return false;
    }
  }

  public static set<T extends object>(obj: T, patch: Patch): boolean {
    if (patch.command === 'set') {
      const jsonpath = PatchExecutor.jsonpath(patch);

      if (jsonpath === '$') {
        for (const key of Object.keys(obj)) {
          delete (obj as any)[key];
        }
        Object.assign(obj, patch.value);
        return true;
      } else {
        const parts = jp.parse(jsonpath);
        const parent = parts.slice(0, parts.length - 1);
        const parentJsonpath = jp.stringify(parent);
        const parentValue = jp.value(obj, parentJsonpath);

        if (parentValue) {
          const key = parts[parts.length - 1].expression.value;

          if (/^[a-zA-Z0-9]+$/.test(key)) {
            parentValue[parts[parts.length - 1].expression.value] = patch.value;
            return true;
          }
        }
      }
    }

    return false;
  }

  public static delete<T>(obj: T, patch: Patch): boolean {
    const jsonpath = PatchExecutor.jsonpath(patch);
    const parent = jp.parent(obj, jsonpath);
    const value = jp.value(obj, jsonpath);

    if (Array.isArray(parent)) {
      if (value !== null && typeof value === 'object') {
        const index = parent.indexOf(value);

        if (index >= 0) {
          parent.splice(index, 1);
          return true;
        }
      } else {
        const parts = jp.parse(jsonpath);
        const key = parts[parts.length - 1].expression.value;

        if (typeof key === 'number') {
          parent.splice(key, 1);
          return true;
        }
      }
    } else {
      for (const key of Object.keys(parent || {})) {
        if (parent[key] === value) {
          delete parent[key];
          break;
        }
      }
      return true;
    }

    return false;
  }

  public static push<T>(obj: T, patch: Patch): boolean {
    if (patch.command === 'push') {
      const jsonpath = PatchExecutor.jsonpath(patch);
      const array = jp.value(obj, jsonpath);

      if (Array.isArray(array)) {
        array.push(patch.value);
        return true;
      }
    }

    return false;
  }

  public static up<T>(obj: T, patch: Patch): boolean {
    const jsonpath = PatchExecutor.jsonpath(patch);
    const parent = jp.parent(obj, jsonpath);

    if (Array.isArray(parent)) {
      const value = jp.value(obj, jsonpath);

      if (value) {
        // const index = parent.indexOf(value);
        const paths = jp.nodes(obj, jsonpath)[0];
        const index = parseInt(paths.path[paths.path.length - 1].toString());

        if (index > 0) {
          parent.splice(index, 1);
          parent.splice(index - 1, 0, value);
          return true;
        }
      }
    }

    return false;
  }

  public static down<T>(obj: T, patch: Patch): boolean {
    const jsonpath = PatchExecutor.jsonpath(patch);
    const parent = jp.parent(obj, jsonpath);

    if (Array.isArray(parent)) {
      const value = jp.value(obj, jsonpath);

      if (value) {
        // const index = parent.indexOf(value);
        const paths = jp.nodes(obj, jsonpath)[0];
        const index = parseInt(paths.path[paths.path.length - 1].toString());

        if (index < parent.length - 1) {
          parent.splice(index, 1);
          parent.splice(index + 1, 0, value);
          return true;
        }
      }
    }

    return false;
  }

  public static splice<T>(obj: T, patch: Patch): boolean {
    if (patch.command === 'splice') {
      const jsonpath = PatchExecutor.jsonpath(patch);
      const array = jp.value(obj, jsonpath);

      if (Array.isArray(array)) {
        array.splice(patch.index, patch.delete || 0, patch.value);
        return true;
      }
    }

    return false;
  }
}
