import { dayjs } from '../../lib/dayjs'
import { Session } from '../../services/common/entities/Session'

export const isPastSession = (serverTime: Date, session: Session): boolean => {
  return !!session.actualEndAt && dayjs(serverTime).isAfter(dayjs(session.actualEndAt))
}
export const isLiveSession = (serverTime: Date, session: Session): boolean => {
  return (
    !!session.actualStartAt &&
    !!session.actualEndAt &&
    dayjs(serverTime).isBetween(
      dayjs(session.actualStartAt),
      dayjs(session.actualEndAt),
      null,
      '[)'
    )
  )
}
export const isFutureSession = (serverTime: Date, session: Session): boolean => {
  return !session.actualStartAt || dayjs(serverTime).isBefore(dayjs(session.actualStartAt))
}
export const sessionsChanged = (sessionsA: Session[], sessionsB: Session[]): boolean => {
  return JSON.stringify(sessionsA) !== JSON.stringify(sessionsB)
}
export const mostRelevantSession = (
  sessions: Session[],
  checkSkippableFlag: boolean
): Session | undefined => {
  return sessions.sort((a, b) => {
    if (checkSkippableFlag && a.skippable && !b.skippable) return -1
    if (checkSkippableFlag && !a.skippable && b.skippable) return 1
    if (!a.actualStartAt && b.actualStartAt) return 1
    if (a.actualStartAt && !b.actualStartAt) return -1
    if (!a.actualStartAt && !b.actualStartAt)
      return new Date(a.startAt).getTime() - new Date(b.startAt).getTime()
    return new Date(a.actualStartAt).getTime() - new Date(b.actualStartAt).getTime()
  })[0]
}
