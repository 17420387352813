import { RefObject } from 'react'
import create from 'zustand'

export enum ColumnState {
  HIDDEN = 'HIDDEN',
  MINIMIZED = 'MINIMIZED',
  MAXIMIZED = 'MAXIMIZED',
  MAXI_WIDE = 'MAXI_WIDE',
}

type Nav = 'REGION_SELECTION' | 'PREVIOUS_EVENT' | 'KEY_TOPICS' | 'CLOSED'

export type EventNavigationProps = {
  activeNav: Nav
}

export type SocialSharingProps = {
  activePopupId: string
}

export type ThreeColGridUiState = {
  col1: ColumnState
  col2: ColumnState
  col3: ColumnState
}

export type UIStoreProps = {
  headerRef: RefObject<HTMLElement> | null
  setHeaderRef: (headerRef: RefObject<HTMLElement>) => void
  quickAccessBarRef: RefObject<HTMLDivElement> | null
  setQuickAccessBarRef: (quickAccessBarRef: RefObject<HTMLDivElement>) => void
  eventNavigation: EventNavigationProps
  /**
   * @deprecated see the new grid/stream
   */
  threeColGridUiState: ThreeColGridUiState

  setEventNavigationState(data: EventNavigationProps): void
  /**
   * @deprecated see the new grid/stream
   */
  setThreeColGridUiState(gridState: ThreeColGridUiState): void

  toggleEventNavigationState(activeNav: Nav): void
  closeEventNavigationState(): void
}

export const useUIStore = create<UIStoreProps>((set) => ({
  headerRef: null,
  setHeaderRef: (headerRef) => set({ headerRef }),
  quickAccessBarRef: null,
  setQuickAccessBarRef: (quickAccessBarRef) => set({ quickAccessBarRef }),
  eventNavigation: {
    activeNav: 'CLOSED',
  },
  threeColGridUiState: {
    col1: ColumnState.HIDDEN,
    col2: ColumnState.MAXIMIZED,
    col3: ColumnState.MINIMIZED,
  },
  setEventNavigationState: (eventNavigation) => {
    set((state) => ({
      ...state,
      eventNavigation,
    }))
  },
  setThreeColGridUiState(gridState: ThreeColGridUiState) {
    set((state) => ({
      ...state,
      threeColGridUiState: gridState,
    }))
  },
  toggleEventNavigationState: (activeNav: Nav) => {
    set((state) => {
      const newActiveNav: Nav = state.eventNavigation.activeNav === activeNav ? 'CLOSED' : activeNav
      state.setEventNavigationState({
        activeNav: newActiveNav,
      })
    })
  },

  closeEventNavigationState: () => {
    set((state) => {
      state.setEventNavigationState({
        activeNav: 'CLOSED',
      })
    })
  },
}))
