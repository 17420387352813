import { combineLatest, Observable, ReplaySubject } from 'rxjs'
import { map } from 'rxjs/operators'

import Service from './Service'
import { GetLastEndPositionsResponse } from '../../services/common/api/v1/assets/GetLastEndPositions'
import http from '../../services/api'
import AuthService from './Auth'

class VideoProgressService extends Service {
  private static lastEndPositions: Record<string, ReplaySubject<number>> = {}

  public static async init(): Promise<void> {
    AuthService.authUser().subscribe((authUser) => {
      if (authUser) {
        this.fetchAssetLastEndPositions(Object.keys(this.lastEndPositions))
      }
    })
  }

  public static async refresh(assetId?: string): Promise<void> {
    if (!assetId) return
    await this.fetchAssetLastEndPositions([assetId])
  }

  public static getAssetLastEndPositions(
    assetIds: string[] = []
  ): Observable<Record<string, number>> {
    const missingIds = assetIds.filter((id) => !this.lastEndPositions[id])

    missingIds.forEach((id) => {
      this.lastEndPositions[id] = new ReplaySubject(1)
    })

    this.fetchAssetLastEndPositions(missingIds)

    const observables = assetIds.map((id) =>
      this.lastEndPositions[id].pipe(map((position) => ({ [id]: position })))
    )

    return combineLatest(observables).pipe(
      map((positionRecord) => positionRecord.reduce((result, record) => ({ ...result, ...record }), {}))
    )
  }

  private static async fetchAssetLastEndPositions(
    assetIds: string[] = []
  ): Promise<void> {
    if (assetIds.length === 0 || !AuthService.currentAuthUser()) return
    const response = await http.get<GetLastEndPositionsResponse>(`assets/lastendpositions`, { params: { assetIds } })
    const lastEndPositionsData = response.data

    Object.keys(lastEndPositionsData).forEach((assetId) => {
      this.lastEndPositions[assetId].next(lastEndPositionsData[assetId])
    })
  }
}

export default VideoProgressService
