import {
  ArrayLocale,
  BooleanLocale,
  DateLocale,
  MixedLocale,
  NumberLocale,
  ObjectLocale,
  StringLocale,
} from 'yup/lib/locale'

export const mixed: MixedLocale = {
  default: ({ path }) => ({ key: 'forms:validatorMixedDefault', values: { path } }),
  required: ({ path }) => ({ key: 'forms:validatorMixedRequired', values: { path } }),
  oneOf: ({ path, values }) => ({ key: 'forms:validatorMixedOneOf', values: { path, values } }),
  notOneOf: ({ path, values }) => ({
    key: 'forms:validatorMixedNotOneOf',
    values: { path, values },
  }),
  defined: ({ path }) => ({ key: 'forms:validatorMixedDefined', values: { path } }),
}

export const string: StringLocale = {
  length: ({ path, length }) => ({ key: 'forms:validatorStringLength', values: { path, length } }),
  min: ({ path, min }) => ({ key: 'forms:validatorStringMin', values: { path, min } }),
  max: ({ path, max }) => ({ key: 'forms:validatorStringMax', values: { path, max } }),
  matches: ({ path, regex }) => ({ key: 'forms:validatorStringMatches', values: { path, regex } }),
  email: ({ path }) => ({ key: 'forms:validatorStringEmail', values: { path } }),
  url: ({ path }) => ({ key: 'forms:validatorStringUrl', values: { path } }),
  uuid: ({ path }) => ({ key: 'forms:validatorStringUuid', values: { path } }),
  trim: ({ path }) => ({ key: 'forms:validatorStringTrim', values: { path } }),
  lowercase: ({ path }) => ({ key: 'forms:validatorStringLowercase', values: { path } }),
  uppercase: ({ path }) => ({ key: 'forms:validatorStringUppercase', values: { path } }),
}

export const number: NumberLocale = {
  min: ({ path, min }) => ({ key: 'forms:validatorNumberMin', values: { path, min } }),
  max: ({ path, max }) => ({ key: 'forms:validatorNumberMax', values: { path, max } }),
  lessThan: ({ path, less }) => ({ key: 'forms:validatorNumberLessThan', values: { path, less } }),
  moreThan: ({ path, more }) => ({ key: 'forms:validatorNumberMoreThan', values: { path, more } }),
  positive: ({ path }) => ({ key: 'forms:validatorNumberPositive', values: { path } }),
  negative: ({ path }) => ({ key: 'forms:validatorNumberNegative', values: { path } }),
  integer: ({ path }) => ({ key: 'forms:validatorNumberInteger', values: { path } }),
}

export const date: DateLocale = {
  min: ({ path, min }) => ({ key: 'forms:validatorDateMin', values: { path, min } }),
  max: ({ path, max }) => ({ key: 'forms:validatorDateMax', values: { path, max } }),
}

export const boolean: BooleanLocale = {
  isValue: ({ path, value }) => ({ key: 'forms:validatorBooleanIsValue', values: { path, value } }),
}

export const object: ObjectLocale = {
  noUnknown: ({ path }) => ({ key: 'forms:validatorObjectNoUnknown', values: { path } }),
}

export const array: ArrayLocale = {
  min: ({ path, min }) => ({ key: 'forms:validatorArrayMin', values: { path, min } }),
  max: ({ path, max }) => ({ key: 'forms:validatorArrayMax', values: { path, max } }),
  length: ({ path, length }) => ({ key: 'forms:validatorArrayLength', values: { path, length } }),
}

export default Object.assign(Object.create(null), {
  mixed,
  string,
  number,
  date,
  object,
  array,
  boolean,
})
