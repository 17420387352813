import { FallbackProps } from 'react-error-boundary'

const ErrorFallback = ({ error, resetErrorBoundary }: FallbackProps): JSX.Element => (
  <div role="alert">
    <p>Something went wrong:</p>
    <pre>{error?.message}</pre>
    <a
      href="/"
      title="Try again"
      onClick={(e) => {
        e.preventDefault()
        resetErrorBoundary()
      }}
    >
      Try again
    </a>
  </div>
)

export default ErrorFallback
