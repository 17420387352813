/* eslint-disable no-underscore-dangle */
import { BehaviorSubject, Observable, ReplaySubject, combineLatest } from 'rxjs'
import { map } from 'rxjs/operators'
import { getUTM } from '../../lib/utm'
import http from '../../services/api'
import { GetEventRegistrationsQuery, GetEventRegistrationsResponse } from '../../services/common/api/v1/events/GetEventRegistrations'
import { PostEventRegisterBody, PostEventRegisterResponse } from '../../services/common/api/v1/events/PostEventRegister'
import { PostEventUnregisterResponse } from '../../services/common/api/v1/events/PostEventUnregister'
import { EventRegistration } from '../../services/common/entities/EventRegistration'
import AuthService from './Auth'
import Service from './Service'
import {
  PostEventEventRegistrationBody,
  PostEventEventRegistrationResponse,
} from '../../services/common/api/v1/events/PostEventEventRegistration'
import EventService from './Event'

type RegisterProps = {
  eventId: string
} & ({
  data: PostEventRegisterBody
  eventRegistrationId: undefined
} | {
  data: PostEventEventRegistrationBody
  eventRegistrationId: string
})

export type PreSelection = {
  eventSlots: string[]
  customFields: {[customFieldId: string] : (string | number | boolean)}[]
  attendees?: {[id: string]: string}[]
}

class EventRegistrationService extends Service {

  private static _lastEventUpdate: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null)

  private static _registrations: {
    [eventId: string]: {
      subject: ReplaySubject<EventRegistration | null>,
      value?: EventRegistration | null
    }
  } = {}


  public static async register({ eventId,data,eventRegistrationId }:RegisterProps): Promise<PostEventRegisterResponse | PostEventEventRegistrationResponse> {
    const utm = getUTM()
    let path = `events/${eventId}/register`
    if(eventRegistrationId) {
      path = `events/${eventId}/eventregistrations/${eventRegistrationId}`
    }
    if (utm.length) {
      path += `?${utm}`
    }

    let result
    if(eventRegistrationId) {
      result = await http.post(path, data).then((res) => res.data as PostEventEventRegistrationResponse)
    } else {
      result = await http.post(path, data).then((res) => res.data as PostEventRegisterResponse)
    }

    EventRegistrationService._lastEventUpdate.next(eventId)

    if (EventRegistrationService._registrations[eventId]) {
      EventRegistrationService._registrations[eventId].value = result
      EventRegistrationService._registrations[eventId].subject.next(result)
    }

    return result;
  }

  public static async unregister(eventId: string): Promise<PostEventUnregisterResponse> {
    const result = await http.post(`events/${eventId}/unregister`).then((res) => res.data) as PostEventUnregisterResponse

    EventRegistrationService._lastEventUpdate.next(eventId)

    if (result.successful && EventRegistrationService._registrations[eventId]) {
      EventRegistrationService._registrations[eventId].value = null
      EventRegistrationService._registrations[eventId].subject.next(null)
    }

    return result
  }

  public static eventRegistration(eventId: string, forceRecache = false): Observable<EventRegistration | null> {
    if (!EventRegistrationService._registrations[eventId]) {
      EventRegistrationService._registrations[eventId] = {
        subject: new ReplaySubject<EventRegistration | null>(1),
      }
      // eslint-disable-next-line no-param-reassign
      forceRecache = true
    }

    if (forceRecache) {
      if (AuthService.currentAuthUser()) {
        EventRegistrationService.eventRegistrationAsPromise(eventId, true)
      } else {
        EventRegistrationService._registrations[eventId].value = null
        EventRegistrationService._registrations[eventId].subject.next(null)
      }
    }

    return EventRegistrationService._registrations[eventId].subject.asObservable()
  }

  public static async eventRegistrationAsPromise(eventId: string, forceRecache = false): Promise<EventRegistration | null> {
    if (!forceRecache && EventRegistrationService._registrations[eventId]?.value) {
      return EventRegistrationService._registrations[eventId]?.value as EventRegistration
    }

    const result = (await http.get(`events/registrations`, { params: { event: eventId } })).data?.items[0] || null

    if (EventRegistrationService._registrations[eventId]) {
      EventRegistrationService._registrations[eventId].value = result
      EventRegistrationService._registrations[eventId].subject.next(result)
    }

    return result
  }

  public static currentEventRegistration(eventId: string): EventRegistration | null | undefined {
    if (typeof EventRegistrationService._registrations[eventId]?.value !== undefined) {
      return EventRegistrationService._registrations[eventId]?.value
    }
    return undefined
  }

  public static extractEventTickets(eventRegistration?: EventRegistration | null): string[] {
    if (eventRegistration?.registrationData) {
      return Array.isArray(eventRegistration.registrationData) ? eventRegistration.registrationData.map(r => r.eventTicket) : [eventRegistration.registrationData.eventTicket]
    }

    return []
  }

  public static extractPreSelections(eventRegistration?: EventRegistration | null): PreSelection {
    const result: PreSelection = {eventSlots: [], customFields: [], attendees: []}
    if (eventRegistration?.registrationData) {
      const registrationData = Array.isArray(eventRegistration.registrationData) ? eventRegistration.registrationData : [eventRegistration.registrationData]
      registrationData.forEach(r => {
        r.eventSlots?.forEach(es => {
          es.eventTimeSlots.forEach((ets) => {
            result.eventSlots.push(ets)
          })
        })
        result.customFields.push(r.customFields)
        if(r.attendees){
          result.attendees = r.attendees
        }
        else {
          result.attendees = []
        }
      })
    }
    return result
  }

  public static eventTickets(eventId: string, forceRecache = false): Observable<string[]> {
    return EventRegistrationService.eventRegistration(eventId, forceRecache).pipe(map((eventRegistration) => {
      return EventRegistrationService.extractEventTickets(eventRegistration)
    }))
  }

  public static registeredEventTicketsToShow(eventId: string, forceRecache = false): Observable<string[]> {
    return combineLatest([EventRegistrationService.eventRegistration(eventId, forceRecache), EventService.get(eventId).event()]).pipe(map(
      ([eventRegistration, event]) => {
        return EventRegistrationService.extractEventTickets(eventRegistration).filter(ticket => event.currentEventVersion.eventTickets.find(et => et._id === ticket)?.showInEventRegistrationProcess);
      }
    ));
  }

  public static currentEventTickets(eventId: string): string[] {
    return EventRegistrationService.extractEventTickets(EventRegistrationService.currentEventRegistration(eventId))
  }

  public static async queryEventRegistrations(query: GetEventRegistrationsQuery = {}): Promise<GetEventRegistrationsResponse> {
    return http.get(`events/registrations`, { params: query }).then((res) => res.data)
  }

  public static lastEventUpdate(): Observable<string | null> {
    return EventRegistrationService._lastEventUpdate.asObservable()
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  static async init() {
    AuthService.authUser().subscribe((user) => {
      // eslint-disable-next-line no-restricted-syntax
      for (const eventId of Object.keys(EventRegistrationService._registrations)) {
        if (user) {
          EventRegistrationService.eventRegistration(eventId, true)
        } else {
          EventRegistrationService._registrations[eventId].value = null
          EventRegistrationService._registrations[eventId].subject.next(null)
        }
      }
    })
  }
}

export default EventRegistrationService
