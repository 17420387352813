import LoadingRetryButton from './LoadingRetryButton'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const LoadingPage = (props: any): JSX.Element | null => {
  const { error, retry, timedOut, pastDelay, isLoading } = props
  document.body.classList.add('progress-api')

  if (error) {
    return (
      <div>
        Error!
        <LoadingRetryButton onClick={retry}>Retry</LoadingRetryButton>
      </div>
    )
  }

  if (timedOut) {
    return (
      <div>
        Taking a long time...
        <LoadingRetryButton onClick={retry}>Retry</LoadingRetryButton>
      </div>
    )
  }

  if (isLoading || pastDelay) {
    return null
  }

  setTimeout(() => {
    document.body.classList.remove('progress-api')
  }, 250)

  return null
}

export default LoadingPage
