import LayoutEditorService from '../app/services/LayoutEditor'
import { findGetParameter } from './url'

export const previewParamName = 'preview'
export const previewStorageKey = 'x-preview'

export const setPreviewToken = (previewToken?: string): void => {
  if (previewToken) {
    localStorage.setItem(previewStorageKey, previewToken)
  } else {
    localStorage.removeItem(previewStorageKey)
  }
}

export const getPreviewParam = (): string | null => {
  const layoutEditorMode = LayoutEditorService.currentLayoutEditorMode()

  if (layoutEditorMode?.previewToken) {
    return layoutEditorMode.previewToken
  }

  const previewToken = findGetParameter(previewParamName)

  if (previewToken) {
    setPreviewToken(previewToken)
  }

  return localStorage.getItem(previewStorageKey)
}
