import { GeoIP } from 'services/common/entities/GeoIP'
import { GetGeoIP } from 'services/configuration/GetGeoIP'
import { OperatingCountry } from 'services/common/entities/OperatingCountry'
import getIpAddress from 'utils/ipAddress'
import Service from './Service'

class GeoIpService extends Service {
  private static geoIp: GeoIP | null = null

  public static getCountry(): string | null {
    return this.geoIp ? this.geoIp.country : null
  }

  public static getOperatingCountry(): OperatingCountry | null {
    return this.geoIp ? this.geoIp.operatingCountry : null
  }

  public static async init(): Promise<void> {
    const ipAdress = await getIpAddress

    if (ipAdress) {
      this.geoIp = await GetGeoIP(ipAdress)
    }
  }
}

export default GeoIpService