/* eslint-disable no-restricted-syntax */
import { io, Socket, SocketOptions, ManagerOptions } from 'socket.io-client'
import { Action } from 'services/common/api/v1/websocket/Action'
import isDev from '../utils/isDev'

export type WebsocketOpts = Partial<ManagerOptions & SocketOptions> | undefined

export interface WebsocketInstance {
  ioOptions: WebsocketOpts
  url: string
  path: string
  connected: boolean

  connect(url: string, path: string, isOpts?: WebsocketOpts): void
  reconnect(token?: string): void
  disconnect(): void

  on<T>(event: string, callback: (args: T) => void): Socket<any, any> | null
  emit<T>(event: string, args: T): Socket<any, any> | null
  register<T>(type: string, args: T, localKey?: string): void
  unregister<T>(type: string, args: T, localKey?: string): void

  removeListener(event: string, callback: (args: any) => void): void
  removeAllListeners(): void
}

/**
 * The Socket instance
 * socket-io.client
 */
class Websocket implements WebsocketInstance {
  private registrations: {
    [key: string]: {
      event: string
      args: any
    }
  } = {}

  ioOptions: WebsocketOpts

  url = ''

  path = ''

  connected = false

  lastSocketId?: string

  // https://socket.io/docs/v4/client-socket-instance/
  private socket: Socket | null = null

  private listeners: { [event: string]: ((args: any) => void)[] } = {}

  private static instance: Websocket

  /**
   * The Singleton's constructor should always be private to prevent direct
   * construction calls with the `new` operator.
   */
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private constructor() {}

  connect(url: string, path: string, ioOpts?: WebsocketOpts): void {
    if (localStorage.getItem('debug') === '*') {
      console.warn(`connect(url: string, path: string, ioOpts?: WebsocketOpts): void {`)
    }
    if (this.socket) {
      this.socket.disconnect()
      this.socket = null
    }

    if (!this.socket) {
      this.url = url
      this.path = path
      this.ioOptions = ioOpts
      this.connected = false

      // https://socket.io/docs/v4/client-offline-behavior/
      // auto connect
      this.socket = io(this.url, {
        ...{ path: this.path },
        ...this.ioOptions,
        withCredentials: true,
      })

      Object.keys(this.listeners).forEach((event) => {
        this.listeners[event].forEach((callback) => {
          this.socket?.on(event, callback)
        })
      })

      this.socket.on('disconnect', (reason) => {
        if (localStorage.getItem('debug') === '*') {
          console.warn(`this.socket.on('disconnect', (reason) => {`)
        }
        this.connected = false

        switch (reason) {
          case 'io client disconnect':
          case 'io server disconnect':
            // the client will not try to reconnect
            this.socket?.connect()
            break
          default:
            if (isDev) console.warn('Websocket disconnected', reason)
            break
        }
      })

      this.socket.on('connect', () => {
        if (localStorage.getItem('debug') === '*') {
          console.warn(`this.socket.on('connect', () => {`)
        }
        this.connected = this.socket?.connected || false
        if (this.lastSocketId) {
          this.emit('$lastsocketid', { lastSocketId: this.lastSocketId })
        }
        if (this.connected) {
          this.lastSocketId = this.socket?.id

          Object.keys(this.registrations)
            .filter((key) => this.registrations[key])
            .forEach((key) =>
              this.emit(this.registrations[key].event, this.registrations[key].args)
            )
        }
        ;(this.listeners.$reconnect || []).forEach((c) => c({}))
      })
    }
  }

  public disconnect() {
    if (localStorage.getItem('debug') === '*') {
      console.warn(`public disconnect() {`)
    }
    if (this.socket) {
      this.socket.disconnect()
      this.socket = null
    }
  }

  public reconnect(token?: string) {
    if (localStorage.getItem('debug') === '*') {
      console.warn(`public reconnect(token?: string) {`)
    }
    this.disconnect()

    if (!this.ioOptions) this.ioOptions = {}

    if (token) {
      this.ioOptions.auth = {
        token,
      }
    } else {
      this.ioOptions.auth = {}
    }

    this.connect(this.url, this.path, this.ioOptions)
  }

  public static getInstance(): Websocket {
    if (!Websocket.instance) {
      Websocket.instance = new Websocket()
    }
    return Websocket.instance
  }

  removeListener(event: string, callback: (args: any) => void): void {
    if (this.socket) {
      this.socket.off(event, callback)
    }
    this.listeners[event] = this.listeners[event].filter((c) => c !== callback)
  }

  removeAllListeners(): void {
    if (this.socket) {
      this.socket.offAny()
    }
    this.listeners = {}
  }

  removeEventListeners(event: string): void {
    if (this.socket) {
      this.socket.off(event)
    }
    this.listeners[event] = []
  }

  on<T>(event: string, callback: (args: T) => void): Socket<any, any> | null {
    if (this.socket) {
      this.socket.on(event, callback)
    }

    if (!this.listeners[event]) {
      this.listeners[event] = []
    }

    this.listeners[event].push(callback)
    return null
  }

  emit<T>(event: string, args: T): Socket<any, any> | null {
    if (this.socket) {
      return this.socket.emit(event, args)
    }

    return null
  }

  /**
   *
   * @param type event, asset, chat etc....
   * @param args
   * @param localKey An alternative key for storing the registration. Use this, when you have multiple socket register events with the same name, but different content (e.g. event:register for event X, event:register for event Y)
   */
  register<T>(type: string, args: T, localKey?: string) {
    const registerEvent = `${type}:register`
    const key = localKey || registerEvent

    this.registrations[key] = { event: registerEvent, args }
    this.emit(registerEvent, args)
  }

  unregister<T>(type: string, args: T, localKey?: string) {
    const registerEvent = `${type}:register`
    const unregisterEvent = `${type}:unregister`
    const key = localKey || registerEvent

    if (this.registrations[key]) {
      delete this.registrations[key]
    }

    this.emit(unregisterEvent, args)
  }

  action(action: Action) {
    this.emit('action', action)
  }
}

export default Websocket.getInstance()
