export type StIconId =
  | 'ic_arrow'
  | 'ic_bookmark_active'
  | 'ic_bookmark_default'
  | 'ic_calendar'
  | 'ic_change'
  | 'ic_check_dialog'
  | 'ic_check'
  | 'ic_chevron_down_white'
  | 'ic_chevron_down'
  | 'ic_chevron_left'
  | 'ic_chevron_right'
  | 'ic_chevron_up'
  | 'ic_close'
  | 'ic_contact'
  | 'ic_delete'
  | 'ic_dots'
  | 'ic_download'
  | 'ic_emoji'
  | 'ic_enlarge'
  | 'ic_episodes'
  | 'ic_external_link'
  | 'ic_facebook'
  | 'ic_gps'
  | 'ic_like_active'
  | 'ic_like'
  | 'ic_linkedin'
  | 'ic_location_filled'
  | 'ic_location'
  | 'ic_lock'
  | 'ic_login'
  | 'ic_logout'
  | 'ic_map'
  | 'ic_message'
  | 'ic_nav_burger'
  | 'ic_play_white'
  | 'ic_play'
  | 'ic_plus'
  | 'ic_profile-card-filled'
  | 'ic_profile-card'
  | 'ic_search'
  | 'ic_send'
  | 'ic_share'
  | 'ic_sort_up'
  | 'ic_sort'
  | 'ic_sound_on'
  | 'ic_stop'
  | 'ic_ticket'
  | 'ic_twitter'
  | 'ic_xing'
  | 'ic_zoom_in'
  | 'ic_zoom_out'
  | 'v2-ic_arrow'
  | 'v2-ic_bookmark_active'
  | 'v2-ic_bookmark_default'
  | 'v2-ic_chevron_down'
  | 'v2-ic_chevron_up'
  | 'v2-ic_message'
  | 'v2-ic_play'
  | 'v2-ic_poll_horizontal'
  | 'v2-ic_poll_vertical';

export const ST_ICON_CODEPOINTS: { [key in StIconId]: string } = {
  'ic_arrow': '61697',
  'ic_bookmark_active': '61698',
  'ic_bookmark_default': '61699',
  'ic_calendar': '61700',
  'ic_change': '61701',
  'ic_check_dialog': '61702',
  'ic_check': '61703',
  'ic_chevron_down_white': '61704',
  'ic_chevron_down': '61705',
  'ic_chevron_left': '61706',
  'ic_chevron_right': '61707',
  'ic_chevron_up': '61708',
  'ic_close': '61709',
  'ic_contact': '61710',
  'ic_delete': '61711',
  'ic_dots': '61712',
  'ic_download': '61713',
  'ic_emoji': '61714',
  'ic_enlarge': '61715',
  'ic_episodes': '61716',
  'ic_external_link': '61717',
  'ic_facebook': '61718',
  'ic_gps': '61719',
  'ic_like_active': '61720',
  'ic_like': '61721',
  'ic_linkedin': '61722',
  'ic_location_filled': '61723',
  'ic_location': '61724',
  'ic_lock': '61725',
  'ic_login': '61726',
  'ic_logout': '61727',
  'ic_map': '61728',
  'ic_message': '61729',
  'ic_nav_burger': '61730',
  'ic_play_white': '61731',
  'ic_play': '61732',
  'ic_plus': '61733',
  'ic_profile-card-filled': '61734',
  'ic_profile-card': '61735',
  'ic_search': '61736',
  'ic_send': '61737',
  'ic_share': '61738',
  'ic_sort_up': '61739',
  'ic_sort': '61740',
  'ic_sound_on': '61741',
  'ic_stop': '61742',
  'ic_ticket': '61743',
  'ic_twitter': '61744',
  'ic_xing': '61745',
  'ic_zoom_in': '61746',
  'ic_zoom_out': '61747',
  'v2-ic_arrow': '61748',
  'v2-ic_bookmark_active': '61749',
  'v2-ic_bookmark_default': '61750',
  'v2-ic_chevron_down': '61751',
  'v2-ic_chevron_up': '61752',
  'v2-ic_message': '61753',
  'v2-ic_play': '61754',
  'v2-ic_poll_horizontal': '61755',
  'v2-ic_poll_vertical': '61756',
};
