import LanguageService from '../app/services/Language'

const localizedTo = (to: string): string => {
  if (to.substring(1, 0) === '/') {
    const { language } = LanguageService.currentLanguage()
    if (to.substring(1, 4) === `${language}/`) {
      return to
    }
    const country = LanguageService.currentCountry()?.isoCode
    let locale = language
    if (country) {
      locale = `${language}_${country.toUpperCase()}`
    }
    return `/${locale}${to}`
  }
  return to
}

export default localizedTo
