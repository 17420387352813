import { ShareCTATarget } from 'services/common/entities/CTAButton'

export type CacheLocation = 'localStorage' | 'sessionStorage'

type AzureADConfig = {
  clientId: string
  authority: string
  authorityRegister: string
  knownAuthorities: string[]
}

export type Environment = {
  production: boolean
  apiEndpoint: string
  apiEndpointShowRoom: string
  wsEndpoint: string
  azureAd?: AzureADConfig
  appAzureAd?: AzureADConfig
  openIdConnect?: {
    clientId: string
    authority: string
  }
  cdn: {
    images: string
    documents: string
  }
  i18n: {
    supportedLangs: string[]
    defaultLanguage: string // process.env.REACT_APP_DEFAULT_LANGUAGE
    defaultLanguageName: string // process.env.REACT_APP_DEFAULT_LANGUAGE_NAME
  }
  defaultRegion: {
    language: string
    region: string
    regionName: string
    timeZone: string
  }
  cmp: {
    url: string
    id: string
    dialogLink: string
    dataAttributeUsercentrics: string
  }
  gtm: {
    id: string
    usercentricsConsentString: string
  }
  eventService: {
    usercentricsConsentString: string
    usercentricsTemplateId: string
  }
  socialMediaShareLinks: ShareCTATarget[] // example: share event link via
}

const environment: Environment = {
  production: false,
  apiEndpoint: '/api/v1',
  apiEndpointShowRoom: 'https://devjntracking.asys365platform.com/api/v1/showrooms',
  wsEndpoint: '//localhost:16100', // direct or -> proxy localhost:16000
  azureAd: {
    clientId: 'bf07f712-f5ad-4420-8deb-5d6535dc4c0e',
    authority: 'https://asys365platform.b2clogin.com/asys365platform.onmicrosoft.com/b2c_1_si',
    authorityRegister:
      'https://asys365platform.b2clogin.com/asys365platform.onmicrosoft.com/b2c_1_si',
    knownAuthorities: ['b2c_1_si'],
  },
  appAzureAd: {
    clientId: 'bf07f712-f5ad-4420-8deb-5d6535dc4c0e',
    authority: 'https://asys365platform.b2clogin.com/asys365platform.onmicrosoft.com/b2c_1_si',
    authorityRegister:
      'https://asys365platform.b2clogin.com/asys365platform.onmicrosoft.com/b2c_1_si',
    knownAuthorities: ['b2c_1_si'],
  },
  openIdConnect: {
    clientId: 'openid-connect-test',
    authority: 'https://dev-365platform-keycloak.azurewebsites.net/auth/realms/master',
  },
  cdn: {
    images: 'https://dev.cdn.asys365platform.com',
    documents: 'https://dev.cdn.asys365platform.com',
  },
  i18n: {
    defaultLanguage: process.env.REACT_APP_DEFAULT_LANGUAGE, // en
    defaultLanguageName: process.env.REACT_APP_DEFAULT_LANGUAGE_NAME, // english
    supportedLangs: ['en', 'de', 'pl', 'jp', 'us', 'it', 'fr', 'es', 'cn', 'cz', 'kr', 'tr', 'ru'], // UI language list
  },
  defaultRegion: {
    language: 'en',
    region: 'en',
    regionName: 'English (Global)',
    timeZone: 'Europe/London',
  },

  cmp: {
    url: 'https://app.usercentrics.eu/latest/main.js',
    id: '2ohgPwC81',
    dialogLink: '#uc-central-modal-show',
    dataAttributeUsercentrics: 'data-usercentrics',
  },
  gtm: {
    id: 'GTM-MBZLPGD',
    usercentricsConsentString: 'Google Tag Manager',
  },
  eventService: {
    usercentricsConsentString: 'Digital Event Analytics',
    usercentricsTemplateId: 'F-8MlZZ-Y',
  },
  socialMediaShareLinks: ['facebook', 'linkedin', 'twitter', 'xing'], // see keys: src/lib/social-sharing.ts
}

const loadedEnvironment = (window as any).loadedEnv

if (loadedEnvironment && loadedEnvironment !== '%ENVIRONMENTJSON%') {
  Object.assign(environment, JSON.parse(loadedEnvironment))
}

export default environment
