import { useEffect } from 'react'
import isDev from 'utils/isDev'
import Button from '../button/Button'

type LoadingScreenProps = {
  isLoading?: boolean
  pastDelay?: boolean
  timedOut?: boolean
  error?: Error | undefined
  retry?: () => any
}

const LoadingScreen = ({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  isLoading,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  pastDelay,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  timedOut,
  error,
  retry,
}: LoadingScreenProps): JSX.Element => {
  const screenWrapper: React.CSSProperties = {
    textAlign: 'center',
    background: '#fff',
  }

  const loadingComponents: React.CSSProperties = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    transform: 'translate(-50%, -50%)',
  }

  useEffect(() => {
    if (isDev && error) {
      console.error('[LoadingScreen]', error)
    }
  }, [error])

  return (
    <div style={screenWrapper} aria-busy="true">
      <div style={loadingComponents}>
        {error ? (
          <div>
            <p>Loading error!</p>
            <Button
              onClick={() => {
                if (retry) {
                  retry()
                } else {
                  document.location.reload()
                }
              }}
            >
              Retry
            </Button>
          </div>
        ) : (
          <div className="spinner-box">
            <div className="circle-border">
              <div className="circle-core" />
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

LoadingScreen.defaultProps = {
  isLoading: false,
  pastDelay: false,
  timedOut: false,
  error: undefined,
  retry: undefined,
}

export default LoadingScreen
