import { HTMLAttributes } from 'react'
import { HashLink as InternalHashLink } from 'react-router-hash-link'
import { isExternalUrl, isHashLink } from '../../utils/url'
import { LocalizedLink as InternalLocalizedLink } from '../common/LocalizedLink'
import ExternalLink from '../external-link/ExternalLink'
import localizedTo from '../../utils/localizedTo'

type LinkElementProps = HTMLAttributes<HTMLElement> & {
  to: string | undefined
  children: React.ReactNode
}

const LinkElement = ({ to, children, ...htmlAttributeProps }: LinkElementProps): JSX.Element => {
  if (!to) {
    return <div {...htmlAttributeProps}>{children}</div>
  }

  if (isExternalUrl(to)) {
    return (
      <ExternalLink
        to={to}
        {...htmlAttributeProps}
        target={to?.includes(window.location.pathname) ? '_self' : '_blank'}
      >
        {children}
      </ExternalLink>
    )
  }

  if (isHashLink(to)) {
    return (
      <InternalHashLink to={localizedTo(to)} {...htmlAttributeProps} smooth>
        {children}
      </InternalHashLink>
    )
  }

  return (
    <InternalLocalizedLink to={to} {...htmlAttributeProps}>
      {children}
    </InternalLocalizedLink>
  )
}

export default LinkElement
