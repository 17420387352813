/* eslint-disable no-underscore-dangle */
import { AuthenticationResult, PublicClientApplication, SilentRequest } from '@azure/msal-browser'
import Service from '../Service'
import ConfigurationService from '../Configuration'
import { appMSALConfiguration, defaultMSALConfiguration, MSALConfiguration } from '../../config/ad-b2c'
// eslint-disable-next-line import/no-cycle
import { getEventTrackingStatus } from '../../../utils/loadEventTracking'
import http from '../../../services/api'
import LocationService from '../Location'
import { AzureADLogin, AppAzureADLogin } from '../../../services/common/entities/configuration/LoginConfiguration'
import { ViewMode } from '../../store'

export const APP_QUERY_PARAM = 'singleeventapp'

class AzureAdService extends Service {
  private static _msalInstance: PublicClientApplication

  private static msalConfig: MSALConfiguration

  public static isEnabled(): boolean {
    return ConfigurationService.loginConfiguration().logins.some(
      (l) => (l.loginType === 'AzureAD' || l.loginType === 'AppAzureAD') && l.enabled
    )
  }

  private static get msalInstance(): PublicClientApplication | undefined {
    if (!AzureAdService._msalInstance && AzureAdService.isEnabled()) {
      let msalConfigTmp: MSALConfiguration
      let azureAdConfig: AzureADLogin | AppAzureADLogin

      if (LocationService.currentQueryParams().viewmode === APP_QUERY_PARAM || localStorage.getItem('viewMode') === ViewMode.App) {
        msalConfigTmp = appMSALConfiguration
        azureAdConfig = ConfigurationService.loginConfiguration().logins.find((l) => l.loginType === 'AppAzureAD' && l.defaultForType) as AppAzureADLogin
        azureAdConfig = azureAdConfig || ConfigurationService.loginConfiguration().logins.find((l) => l.loginType === 'AppAzureAD') as AppAzureADLogin
      } else {
        msalConfigTmp = defaultMSALConfiguration
        // todo defaultForType (LoginConfiguration)
        azureAdConfig = ConfigurationService.loginConfiguration().logins.find((l) => l.loginType === 'AzureAD' && l.defaultForType) as AzureADLogin
        azureAdConfig = azureAdConfig || ConfigurationService.loginConfiguration().logins.find((l) => l.loginType === 'AzureAD') as AzureADLogin
      }

      if(azureAdConfig) {
        msalConfigTmp.config.auth.clientId = azureAdConfig.clientId
        msalConfigTmp.config.auth.authority = azureAdConfig.authority
        msalConfigTmp.config.auth.redirectUri = azureAdConfig.redirectURI
        msalConfigTmp.config.auth.postLogoutRedirectUri = azureAdConfig.logoutRedirectURI
        msalConfigTmp.config.auth.knownAuthorities = azureAdConfig.knownAuthorities
        msalConfigTmp.registerRedirectRequest.authority = azureAdConfig.authorityRegister
      }

      this.msalConfig = msalConfigTmp

      AzureAdService._msalInstance = new PublicClientApplication(this.msalConfig.config)
    }

    return AzureAdService._msalInstance
  }

  private static postLogin(token: string, clientId?: string) {
    if (!AzureAdService.msalInstance) {
      return null
    }

    const loginEndpoint = `auth/login/dynamicazuread?clientId=${clientId || this.msalConfig.config.auth.clientId}`

    return http.post(loginEndpoint, {
      acceptAnalytics: getEventTrackingStatus()
    }, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((response) => response.data).catch((error) => {
      console.error(error)
      return null
    })
  }

  public static async loginInProgress(): Promise<boolean> {
    return !!(await AzureAdService.msalInstance?.handleRedirectPromise().catch())
  }

  public static async tokenViaAdToken(adToken: string, clientId?: string): Promise<string | null> {
    const auth = await AzureAdService.postLogin(adToken, clientId)

    if (auth && 'token' in auth && auth.token) {
      return auth.token
    }
    return null
  }

  public static async token(messageToken?: string): Promise<string | null> {
    let loginToken: string | undefined
    if(messageToken) {
      loginToken = messageToken
    } else {
      const tokenResponse = await AzureAdService.msalInstance?.handleRedirectPromise().catch()
      loginToken = tokenResponse?.idToken
    }

    if (loginToken) {
      const accounts = AzureAdService.msalInstance?.getAllAccounts() || []
      if (accounts.length) {
        const auth = await AzureAdService.postLogin(loginToken)

        if (auth && 'token' in auth && auth.token) {
          return auth.token
        }
      }
    }

    return null
  }

  public static async login(): Promise<void> {
    return AzureAdService.msalInstance?.loginRedirect(this.msalConfig.loginRedirectRequest)
  }

  public static async register(): Promise<void> {
    return AzureAdService.msalInstance?.loginRedirect(this.msalConfig.registerRedirectRequest)
  }

  public static async logout(): Promise<void> {
    return AzureAdService.msalInstance?.logoutRedirect({ postLogoutRedirectUri: window.location.href })
  }

  public static async performSilentLogin(): Promise<AuthenticationResult | null> {
    const silentRequest: SilentRequest = {
      scopes: ['openid'],
      redirectUri: window.location.origin,
      authority: this.msalConfig.config.auth.authority,
    }

    try {
      return await AzureAdService.msalInstance?.ssoSilent(silentRequest) ?? null
    } catch (error) {
      return null
    }
  }
}

export default AzureAdService
