import { createTrackedSelector } from 'react-tracked'
import create, { SetState, StateCreator, StateListener } from 'zustand'
import { configurePersist } from 'zustand-persist'
import { Region } from '../../services/common/entities/Region'
import { AuthUser } from '../../services/common/entities/AuthUser'
import { GetAuthResponseError } from '../../services/common/api/v1/auth/GetAuth'
import { EventPhase } from '../../services/common/entities/Event'
import LayoutEditorService from '../services/LayoutEditor'

import '../../lib/cache-boost'

export type UserStoryProps = {
  user?: AuthUser | undefined
  error?: GetAuthResponseError | undefined
  token?: string | undefined
  region?: Region | undefined
  phase?: EventPhase
  utcOffset?: number | undefined
  loginCheckInProgress: boolean
  setToken(token: string): void
  setUser(user: AuthUser | undefined, token: string): void
  setError(error: GetAuthResponseError | undefined): void
  resetUser(): void
  isLoggedIn(): boolean
  setLoginCheckInProgress(inProgress: boolean): void
  setUtcOffset(newUtcOffset: number | undefined): void
}

export const { persist, purge } = configurePersist({
  rootKey: '365Platform_me',
  storage: localStorage,
})

const stateFn: StateCreator<UserStoryProps, SetState<UserStoryProps>> = (set, get) => ({
  loginCheckInProgress: true,
  resetUser() {
    if (!LayoutEditorService.currentLayoutEditorMode()) purge()
    set((state) => ({ ...state, user: undefined, token: undefined, error: undefined }), true)
  },
  setToken(token) {
    set((state) => ({ ...state, token }))
  },
  setUser(user, token) {
    set((state) => ({
      ...state,
      user: user && { ...user },
      token,
    }))
  },
  setError(error) {
    if (error) {
      set((state) => ({ ...state, error }))
    } else {
      set((state) => ({ ...state, error: undefined }))
    }
  },
  setLoginCheckInProgress(inProgress: boolean) {
    set((state) => ({ ...state, loginCheckInProgress: inProgress }))
  },
  isLoggedIn(): boolean {
    const { token, error } = get()
    return token !== undefined && error === undefined
  },
  setUtcOffset(newUtcOffset: number | undefined) {
    set((state) => ({
      ...state,
      utcOffset: newUtcOffset,
    }))
  },
})

export const useUserStore = create<UserStoryProps>(
  LayoutEditorService.currentLayoutEditorMode()
    ? stateFn
    : persist(
        {
          key: 'user',
          denylist: ['phase'],
        },
        stateFn
      )
)

export function addListener(cb: StateListener<UserStoryProps>): any {
  return { unsubscribe: useUserStore.subscribe(cb), state: useUserStore }
}

export const useTrackedStore = createTrackedSelector(useUserStore)
