import * as jp from 'jsonpath'
import { Search } from 'services/common/entities/Search'
import { Configuration } from 'services/common/entities/Configuration'
import { OperatingRegion } from 'services/common/entities/OperatingRegion'
import { OperatingCountry } from 'services/common/entities/OperatingCountry'
import { GlobalTicketRegistration } from 'services/common/entities/GlobalTicketRegistration'
import { isCustomPageModule } from 'services/common/entities/pagemodules/CustomPageModule'
import { Session } from '../../services/common/entities/Session'
import { Event } from '../../services/common/entities/Event'
import { MenuItem } from '../../services/common/entities/MenuItem'
import { Page } from '../../services/common/entities/Page'
import { PageModule } from '../../services/common/entities/PageModule'
import { Speaker } from '../../services/common/entities/Speaker'
import { Map } from '../../services/common/entities/Map'
import { VideoAsset, VideoAssetSeries } from '../../services/common/entities/Asset'
import { Product } from '../../services/common/entities/Product'
import Service from './Service'

const eventPageModules = [
  '.local[*].videos[*].local',
  '.local[*].items[*].cta.local',
  '.local[*].items[*].cta.appFunction.local',
  '.local[*].items[*].video.local',
  '.local[*].topics[*].cta.local',
  '.local[*].topics[*].cta.appFunction.local',
  '.local[*].topics[*].storyAssets[*].cta.local',
  '.local[*].topics[*].storyAssets[*].cta.appFunction.local',
  '.local',
  '.local[*].video.local',
  '.ctaButton.local',
  '.ctaButton.appFunction.local',
  '.link.local',
  '.events[*].currentEventVersion.local',
  '.local[*].events[*].currentEventVersion.local',
  '.video.local',
  '.products[*].local',
  '.products[*].technology.local',
  '.products[*].exhibitedWith.local',
  '.products[*].relatedAssets[*].local',
  '.products[*].relatedProducts[*].local',
  '.products[*].exhibitedWith.local',
  '.groups[*].local',
  '.groups[*].series[*].local',
  '.groups[*].series[*].products[*].local',
  '.groups[*].series[*].products[*].technology.local',
  '.groups[*].series[*].products[*].exhibitedWith.local',
  '.groups[*].series[*].products[*].relatedAssets[*].local',
  '.elements[*].local',
  '.elements[*].childElements[*].local',
  '.ctalist[*].ctaButton.local',
  '.ctalist[*].ctaButton.appFunction.local',
  '.ctalistInteraction[*].ctaButton.local',
  '.ctalistInteraction[*].ctaButton.appFunction.local',
  '.ctalistInteraction[*].ctaButton.local..video.local',
  '.local[*].assets[*].local',
  '.local[*].image.local',
  '.sortingOptions[*].local',
  '.discoverMoreCta.local',
  '.discoverMoreCta.appFunction.local',
]

class LocalizeService extends Service {
  private static apply(obj: any, paths: string[], language: string) {
    paths.forEach((path) => {
      jp.apply(obj, path, (a) => {
        if (!a || Object.keys(a).length === 0) {
          return {
            current: {}
          }
        }

        const current: any = {}

        const selectedLanguage = a[language] || {}
        const firstFallbackLanguage = a.en || {}
        const secondFallbackLanguage = a[Object.keys(a)[0]] || {}
        const selectedLanguageKeys = Object.keys(selectedLanguage)
        const firstFallbackLanguageKeys = Object.keys(firstFallbackLanguage)
        const secondFallbackLanguageKeys = Object.keys(secondFallbackLanguage)

        const properties = [...selectedLanguageKeys, ...firstFallbackLanguageKeys, ...secondFallbackLanguageKeys].filter((value, index, self) => { // unique values
          return self.indexOf(value) === index;
        })

        properties.forEach((property) => {
          let value = selectedLanguage[property] || firstFallbackLanguage[property] || secondFallbackLanguage[property]

          if (!value) { // get a value of any language
            const languages = Object.keys(a)
            const validValue = languages.findIndex(l => a[l] && a[l][property])

            if (validValue >= 0) {
              value = a[languages[validValue]][property]
            }
          }

          current[property] = value
        })

        return {
          ...a,
          current,
        }
      })
    })
  }

  public static event(event: Event, language: string): Event {
    LocalizeService.apply(
      event,
      [
        '$.currentEventVersion.local',
        '$.currentEventVersion.eventSlotConfiguration.local',
        '$.currentEventVersion.eventTicketConfiguration.local',
        '$.currentEventVersion.eventTicketConfiguration.digitalPassWarningCTA.local',
        '$.currentEventVersion.eventTicketConfiguration.digitalPassWarningCTA.appFunction.local',
        '$.currentEventVersion.eventTickets[*].local',
        '$.currentEventVersion.eventTickets[*].digitalPassWarningCTA.local',
        '$.currentEventVersion.eventTickets[*].digitalPassWarningCTA.appFunction.local',
        '$.currentEventVersion.stages[*].local',
        '$.currentEventVersion.eventProducts[*].product.local',
        '$.currentEventVersion.eventProducts[*].product.technology.local',
        '$.currentEventVersion.eventProducts[*].product.exhibitedWith.local',
        '$.currentEventVersion.eventProducts[*].product.relatedAssets[*].local',
        '$.currentEventVersion.eventProducts[*].product.relatedProducts[*].local',
        '$.currentEventVersion.eventProducts[*].product.image.local',
        '$.currentEventVersion.eventProducts[*].override.local',
        '$.currentEventVersion.eventProducts[*].override.exhibitedWith.local',
        '$.currentEventVersion.eventProducts[*].override.relatedAssets[*].local',
        '$.currentEventVersion.eventProducts[*].override.relatedProducts[*].local',
        '$.currentEventVersion.eventProducts[*].override.image.local',
        '$.currentEventVersion.sessions[*].local',
        '$.currentEventVersion.sessions[*].sessionMedia',
        '$.currentEventVersion.sessions[*].local[*].relatedContent[*].asset.local',
        '$.currentEventVersion.eventKeytopics[*].local',
        '$.currentEventVersion.eventKeytopics[*].keytopic.local',
        '$.currentEventVersion.eventKeytopics[*].override.local',
        '$.currentEventVersion.eventKeytopics[*].local[*].assets[*].local',
        '$.currentEventVersion.registrationCustomFields[*].customField.local',
        '$.currentEventVersion.registrationCustomFields[*].customField.fields[*].local',
        '$.currentEventVersion.contentChecklistGroups[*].local',
        '$.currentEventVersion.contentChecklistGroups[*].items[*].local',

        '$.currentEventVersion.phase..pages[*].local',
        '$.currentEventVersion.phase..pages[*].onboarding.local',
        '$.currentEventVersion.phase..pages[*].onboarding.steps[*].local',
        '$.currentEventVersion.phase..pages[*].onboarding.steps[*].ctaButton.local',
        '$.currentEventVersion.phase..pages[*].onboarding.steps[*].ctaButton.appFunction.local',
        '$.currentEventVersion.phase..pages[*].eventStage.ctaButton.local',
        '$.currentEventVersion.phase..pages[*].eventStage.ctaButton.appFunction.local',
        '$.currentEventVersion.phase..pages[*].eventStage.local',
        '$.currentEventVersion.phase..pages[*].eventStage.local[*].items[*].cta.local',
        '$.currentEventVersion.phase..pages[*].eventStage.local[*].items[*].cta.appFunction.local',
        ...eventPageModules.map(e => `$.currentEventVersion.phase..pages[*].pageModules[*]${e}`),
        
        // for CustomPageModules
        ...eventPageModules.map(e => `$.currentEventVersion.phase..pages[*].pageModules[*].pageModules[*]${e}`),
        
        '$.currentEventVersion.phase..mobileMenuItems[*].local',
        '$.currentEventVersion.phase..mobileMenuItems[*].appFunction.local',
        '$.currentEventVersion.phase..appMenuItems[*].local',
        '$.currentEventVersion.phase..appMenuItems[*].appFunction.local',
      ],
      language
    )

    event.currentEventVersion?.sessions?.forEach((session) => {
      LocalizeService.session(session, language)
    })

    return event
  }

  public static arrayOfEvents(events: Event[], language: string): Event[] {
    return events.map(event => LocalizeService.event(event, language));
  }

  public static session(session: Session, language: string): Session {
    LocalizeService.apply(session, ['$.local', '$.sessionMedia', '$.sessionMedia..asset.local', '$.breakouts[*].local'], language)
    return session
  }

  /**
   * For global pages
   */
  public static pageModule(pageModule: PageModule, language: string): PageModule {
    LocalizeService.apply(pageModule, [
      '$.local',
      '$.local[*].assets[*].local',
      '$.ctaButton.local',
      '$.ctaButton.appFunction.local',
      '$.discoverMoreCta.local',
      '$.discoverMoreCta.appFunction.local',
      '$.link.local',
      '$.tags[*].local',
      '$.link.appFunction.local',
      '$.events[*].currentEventVersion.local',
      '$.local[*].events[*].currentEventVersion.local',
      '$.local[*].videos[*].local',
      '$.local[*].items[*].video.local',
      '$.local[*].topics[*].cta.local',
      '$.local[*].topics[*].cta.appFunction.local',
      '$.local[*].topics[*].storyAssets[*].cta.local',
      '$.local[*].topics[*].storyAssets[*].cta.appFunction.local',
      '$.ctalistInteraction[*].ctaButton.local',
      '$.ctalistInteraction[*].ctaButton.local..video.local',
      '$.ctalistInteraction[*].ctaButton.appFunction.local',
      '$.selectorPages[*].local',
      '$.sortingOptions[*].local',
      '$.elements[*].local',
      '$.elements[*].childElements[*].local',
      '$.products[*].local',
      '$.products[*].technology.local',
      '$.products[*].exhibitedWith.local',
      '$.products[*].relatedAssets[*].local',
      '$.products[*].relatedProducts[*].local',
      '$.products[*].image.local',
      '$.groups[*].local',
      '$.groups[*].series[*].local',
      '$.groups[*].series[*].products[*].local',
      '$.groups[*].series[*].products[*].technology.local',
      '$.groups[*].series[*].products[*].exhibitedWith.local',
      '$.groups[*].series[*].products[*].relatedAssets[*].local',
      '$.groups[*].series[*].products[*].image.local',
      '$.ctalist[*].ctaButton.local',
    ],
      language)
    return pageModule
  }

  public static configuration(configuration: Configuration, language: string): Configuration {
    LocalizeService.apply(configuration, [
      '$.platform.defaultOnboarding.local',
      '$.platform.defaultOnboarding.steps[*].local',
      '$.platform.defaultOnboarding.steps[*].ctaButton.local',
      '$.platform.defaultOnboarding.steps[*].ctaButton.appFunction.local',
    ], language)
    return configuration
  }

  public static page(page: Page, language: string): Page {
    LocalizeService.apply(page, [
      '$.local',
      '$.onboarding.local',
      '$.parentPage.local',
      '$.children[*].local',
      '$.rootChildren[*].local',
      '$.onboarding.steps[*].local',
      '$.onboarding.steps[*].ctaButton.local',
      '$.onboarding.steps[*].ctaButton.appFunction.local',
      '$.recommendationsSettings.local',
      '$.featuredEvents[*].currentEventVersion.local'
    ], language)
    return page
  }

  public static pageModules(pageModules: PageModule[], language: string): PageModule[] {
    return pageModules.map(m => {
      if (isCustomPageModule(m)) {
        return {
          ...m,
          pageModules: LocalizeService.pageModules(m.pageModules, language)
        }
      }

      return LocalizeService.pageModule(m, language)
    });
  }

  public static menuItem(menuItem: MenuItem, language: string): MenuItem {
    LocalizeService.apply(menuItem, ['$.local', '$.appFunction.local'], language)
    return menuItem
  }

  public static menuItems(menuItems: MenuItem[], language: string): MenuItem[] {
    return menuItems.map(m => LocalizeService.menuItem(m, language));
  }

  public static speaker(speaker: Speaker, language: string): Speaker {
    LocalizeService.apply(speaker, ['$.local'], language)
    return speaker
  }

  public static arrayElement<T>(element: T, paths: string[], language: string): T {
    LocalizeService.apply(element, paths, language)
    return element
  }

  public static array<T>(array: T[], paths: string[], language: string): T[] {
    return array.map(element => LocalizeService.arrayElement(element, paths, language));
  }

  public static arrayWithLocal<T>(array: T[], language: string): T[] {
    return LocalizeService.array<T>(array, ['$.local'], language)
  }

  public static local<T>(item: T, language: string): T {
    LocalizeService.apply(item, ['$.local'], language)
    return item
  }

  public static map(map: Map, language: string): Map {
    LocalizeService.apply(map, [
      '$.overlays[*].local',
      '$.overlays[*].product.local',
      '$.overlays[*].ctaButton.local',
      '$.overlays[*].ctaButton.appFunction.local',
      '$.overlays[*].relatedAssets[*].local',
      '$.submaps[*].local',
      '$.submaps[*].markers[*].local',
      '$.submaps[*].markers[*].action.local',
      '$.markerGroups[*].local',
      '$.markerGroups[*].markerCategories[*].local',
      '$.markerGroups[*].markerCategories[*].markerCategories[*].local',
      '$.beaconGroups[*].local',
      '$.beaconGroups[*].actionCtaButton.local',
      '$.beaconSearch.local',
      '$.beaconSearch.beaconSearchCTA.local',
      '$.beaconSearch.beaconSearchCTA.appFunction.local',
      '$.beaconSearch.beaconNoRightsCTA.local',
      '$.beaconSearch.beaconNoRightsCTA.appFunction.local',
    ], language)
    return map
  }

  public static videoAsset(asset: VideoAssetSeries | VideoAsset, language: string): VideoAssetSeries | VideoAsset {
    LocalizeService.apply(asset, [
      '$.local',
      '$.tags[*].local',
      '$.assets[*].local',
    ], language)
    return asset
  }

  public static videoAssets(assets: VideoAsset[], language: string): VideoAsset[] {
    LocalizeService.array(assets, [
      '$.local',
      '$.tags[*].local',
    ], language)
    return assets
  }

  public static speakers(speakers: Speaker[], language: string): Speaker[] {
    LocalizeService.array(speakers, [
      '$.local',
      '$.externalAccounts[*].local',
    ], language)
    return speakers
  }

  public static searchResults(searchResults: Search[], language: string): Search[] {
    LocalizeService.array(searchResults, [
      '$.local',
      '$.tags[*].local',
    ], language)
    return searchResults
  }

  public static product(product: Product, language: string): Product {
    LocalizeService.apply(product, [
      '$.local',
      '$.technology.local',
      '$.exhibitedWith.local',
      '$.tags[*].local',
      '$.relatedAssets[*].local',
      '$.relatedProducts[*].local',
      '$.image.local',
    ], language)
    return product
  }

  public static operatingRegions(operatingRegions:OperatingRegion[], language: string): OperatingRegion[] {
    LocalizeService.array(operatingRegions, [
      '$.local',
    ], language)
    return operatingRegions
  }

  public static operatingCountries(operatingCountries:OperatingCountry[], language: string): OperatingCountry[] {
    LocalizeService.array(operatingCountries, [
      '$.local',
    ], language)
    return operatingCountries
  }

  public static operatingCountry(operatingCountry:OperatingCountry, language: string): OperatingCountry {
    LocalizeService.apply(operatingCountry, [
      '$.local',
    ], language)
    return operatingCountry
  }

  public static globalTicketsRegistration(globalTicketRegistration: GlobalTicketRegistration, language: string): GlobalTicketRegistration {
    LocalizeService.apply(globalTicketRegistration, [
      '$.ticket.local',
    ], language)
    return globalTicketRegistration
  }

  public static globalTicketsRegistrations(globalTicketRegistrations: GlobalTicketRegistration[], language: string): GlobalTicketRegistration[] {
    return globalTicketRegistrations.map(gtr => LocalizeService.globalTicketsRegistration(gtr, language))
  }
}

export default LocalizeService
