import FontFaceObserver from 'fontfaceobserver'

// Observe loading of Inter (to remove 'Inter', remove the <link> tag in
// the index.html file and this observer)

// TODO: load only the fonts that are configured to be used

const robotoCondensedObserver = new FontFaceObserver('Roboto Condensed', {})
const robotoObserver = new FontFaceObserver('Roboto', {})
const nettoWebProObserver = new FontFaceObserver('nettoWebPro', {})
const arialMtObserver = new FontFaceObserver('Arial MT', {})
const arialMtBoldObserver = new FontFaceObserver('Arial MT Bold', {})
const outfitLightObserver = new FontFaceObserver('Outfit Light', {})
const outfitSemiBoldObserver = new FontFaceObserver('Outfit SemiBold', {})
const outfitRegularObserver = new FontFaceObserver('Outfit Regular', {})
const montserratRegularObserver = new FontFaceObserver('Montserrat Regular', {})

if (sessionStorage.fontsLoaded) {
  document.body.classList.replace('font-loading', 'font-loaded')
} else {
  // When Inter is loaded, add a font-family using Inter to the body
  Promise.all([
    robotoCondensedObserver.load(),
    robotoObserver.load(),
    nettoWebProObserver.load(),
    arialMtBoldObserver.load(),
    arialMtObserver.load(),
    outfitLightObserver.load(),
    outfitSemiBoldObserver.load(),
    outfitRegularObserver.load(),
    montserratRegularObserver.load(),
  ]).then(() => {
    document.body.classList.replace('font-loading', 'font-loaded')
    sessionStorage.setItem('robotoLoaded', 'true')
    sessionStorage.setItem('nettoLoaded', 'true')
    sessionStorage.setItem('arialMtLoaded', 'true')
    sessionStorage.setItem('arialMtboldLoaded', 'true')
    sessionStorage.setItem('outfitLightLoaded', 'true')
    sessionStorage.setItem('outfitSemiBoldLoaded', 'true')
    sessionStorage.setItem('outfitRegularLoaded', 'true')
    sessionStorage.setItem('montserratRegularLoaded', 'true')
  })
}
