import * as React from 'react'
import styles from '../button/Button.module.scss'
import block from '../../lib/bem'

const b = block(styles, 'Button')

export interface ButtonProps {
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void
}

const LoadingRetryButton: React.FC<ButtonProps> = ({ onClick = undefined, ...props }) => {
  const { children } = props

  return (
    <button className={b()} onClick={onClick} type="button">
      {children && <span>{children}</span>}
    </button>
  )
}

export default LoadingRetryButton
