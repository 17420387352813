import http from '../api'
import { AuthUser } from '../common/entities/AuthUser'
import { GetAuthResponse, GetAuthResponseError } from '../common/api/v1/auth/GetAuth'
import { getNewUTMs, markUTMsAsOld } from '../../lib/utm'

async function GetAuthUser(token: string): Promise<AuthUser | GetAuthResponseError> {
  const utm = getNewUTMs()
  let path = 'auth'

  if (utm.length) {
    path += `?${utm}`
  }

  return http
    .get<GetAuthResponse>(path, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => {
      markUTMsAsOld()
      return res.data
    })
    .catch((error) => error?.response?.data)
}

export default GetAuthUser
