import { IPlugin, PluginStore } from 'react-pluggable'

abstract class Plugin implements IPlugin {
  abstract pluginStore: PluginStore

  abstract namespace: string

  abstract getPluginName(): string

  abstract getDependencies(): string[]

  public getStoreKey(key?: string): string {
    const result = `365Plugin-${this.namespace}`
    return key ? `${result}-${key}` : result
  }

  abstract init(pluginStore: PluginStore): void

  abstract activate(): void

  abstract deactivate(): void

  abstract shouldInstall(): boolean

  private readyResolve?: () => void

  private readyPromise = new Promise<void>((resolve: () => void) => {
    this.readyResolve = resolve
  })

  protected ready(): void {
    if (this.readyResolve) {
      this.readyResolve()
    }
  }

  public isReady(): Promise<void> {
    return this.readyPromise
  }
}

export default Plugin
