import create from 'zustand'
import { persist, purge } from './user-store'
import { EventTicket } from '../../services/common/entities/EventTicket'

export type TicketSelectionStoreProps = {
  selectedTickets?: Array<EventTicket>
  eventId?: string | undefined
  setSelectedTickets(selectedTickets: Array<EventTicket>): void
  setEventId(eventId: string | undefined): void
  getSelectedTickets(): Array<EventTicket> | undefined
  getEventId(): string | undefined
  reset(): void
}

export const useTicketSelectionStore = create<TicketSelectionStoreProps>(
  persist(
    {
      key: 'ticket',
    },
    (set, get) => ({
      reset: () => {
        purge()
        set((state) => ({ ...state, selectedTickets: [], eventId: undefined }))
      },
      setSelectedTickets: (selectedTickets) => {
        set((state) => ({
          ...state,
          selectedTickets,
        }))
      },
      setEventId: (eventId) => {
        set((state) => ({
          ...state,
          eventId,
        }))
      },
      getSelectedTickets: () => {
        return get().selectedTickets
      },
      getEventId: () => {
        return get().eventId
      },
    })
  )
)
