/* eslint-disable prettier/prettier */
import { BehaviorSubject, Observable } from 'rxjs'
import { useAppStore, ViewMode } from 'app/store/app-store'
import { EventPhase } from '../../services/common/entities/Event'
import { Page } from '../../services/common/entities/Page'
import { PageModule, PageModuleClipboardData } from '../../services/common/entities/PageModule'
import LocationService from './Location'
import Service from './Service'

export interface LayoutEditorMode {
  page: string;
  pageVersion?: string;
  previewToken: string;
  event?: string;
  eventShortId?: string;
  eventVersion?: string;
  eventPhase?: EventPhase;
  viewport?: string;
}

class LayoutEditorService extends Service {

  private static layoutEditorModeSubject: BehaviorSubject<LayoutEditorMode | null> = new BehaviorSubject<LayoutEditorMode | null>(LayoutEditorService.getMode(LocationService.currentUrl()))

  private static clipboardDataSubject: BehaviorSubject<PageModuleClipboardData | null> = new BehaviorSubject<PageModuleClipboardData | null>(null)

  private static getMode(url: URL): LayoutEditorMode | null {
    const mode = url.searchParams.get('_edit')
    return mode ? JSON.parse(mode) : null
  }

  private static processUrl(url: URL | null) {
    if (!url) return;

    const newMode = LayoutEditorService.getMode(url)

    if (newMode) {
      const currentMode = LayoutEditorService.currentLayoutEditorMode()

      if (!currentMode || JSON.stringify(newMode) !== JSON.stringify(currentMode)) {
        LayoutEditorService.layoutEditorModeSubject.next(newMode);
      }

      if (newMode.viewport) {
        const viewport = document.querySelector("meta[name=viewport]");
        viewport?.setAttribute('content', newMode.viewport);
      }
    }
  }

  public static async init(): Promise<void> {
    LocationService.url().subscribe(url => {
      LayoutEditorService.processUrl(url)

      const parts = url.pathname.split('/')

      if (parts[1] === 'data-protection') { // only page that is left. TODO: data-protection as a core page / remove from routing
        LayoutEditorService.pageSelected(null)
      }

    })
  }

  public static clipBoardListening(page: Page | undefined, pageModule: PageModule | null): Observable<PageModuleClipboardData | null> {
    // initially requestClipboardData from backend
    this.pageModuleMessage(page, pageModule, 'pagemodule:refreshClipboardData')
    return LayoutEditorService.clipboardDataSubject.asObservable()
  }

  public static refreshClipboard(clipboardData: PageModuleClipboardData | null): void {
    return LayoutEditorService.clipboardDataSubject.next(clipboardData)
  }

  public static layoutEditorMode(): Observable<LayoutEditorMode | null> {
    return LayoutEditorService.layoutEditorModeSubject.asObservable()
  }

  public static currentLayoutEditorMode(): LayoutEditorMode | null {
    return LayoutEditorService.layoutEditorModeSubject.getValue()
  }

  public static eventStageAdd(page: Page): void {
    window.parent.postMessage(
      {
        type: 'eventstage:add',
        pageId: page._id,
        pageVersionId: page.pageVersion,
      },
      '*'
    )
  }

  public static eventStageRemove(page: Page): void {
    window.parent.postMessage(
      {
        type: 'eventstage:remove',
        pageId: page._id,
        pageVersionId: page.pageVersion,
      },
      '*'
    )
  }

  public static eventStageEdit(page: Page): void {
    window.parent.postMessage(
      {
        type: 'eventstage:edit',
        pageId: page._id,
        pageVersionId: page.pageVersion,
      },
      '*'
    )
  }

  private static pageModuleMessage(page: Page | undefined, pageModule: PageModule | null, type: string, data?: any): void {
    window.parent.postMessage(
      {
        type,
        pageId: page?._id,
        pageVersionId: page?.pageVersion,
        pageModuleId: pageModule?._id,
        ...data
      },
      '*'
    )
  }

  public static async pageModuleCopy(page: Page, pageModule: PageModule): Promise<void> {
    LayoutEditorService.pageModuleMessage(page, pageModule, `pagemodule:copy`)
  }

  public static pageModulePaste(clipboardData: PageModuleClipboardData, page: Page, pageModule: PageModule | null, index: number): void {
    window.parent.postMessage(
      {
        type: 'pagemodule:paste',
        pageId: page._id,
        pageVersionId: page.pageVersion,
        pageModuleId: pageModule?._id,
        pageModuleType: clipboardData.pageModuleType,
        index
      },
      '*'
    )
  }

  public static pageModuleRemove(page: Page, pageModule: PageModule): void {
    LayoutEditorService.pageModuleMessage(page, pageModule, `pagemodule:remove`)
  }

  public static pageModuleEdit(page: Page, pageModule: PageModule): void {
    LayoutEditorService.pageModuleMessage(page, pageModule, `pagemodule:edit`)
  }

  public static pageModuleUp(page: Page, pageModule: PageModule): void {
    LayoutEditorService.pageModuleMessage(page, pageModule, `pagemodule:up`)
  }

  public static pageModuleDown(page: Page, pageModule: PageModule): void {
    LayoutEditorService.pageModuleMessage(page, pageModule, `pagemodule:down`)
  }

  public static pageModuleInsert(page: Page, index: number): void {
    window.parent.postMessage(
      {
        type: 'pagemodule:insert',
        pageId: page._id,
        pageVersionId: page.pageVersion,
        index
      },
      '*'
    )
  }

  public static pageSelected(page: Page | null): void {
    window.parent.postMessage(
      {
        type: 'page:selected',
        pageId: page?._id,
        pageVersionId: page?.pageVersion
      },
      '*'
    )
  }

  public static setViewMode(viewMode: ViewMode): void {
    switch (viewMode) {
      case ViewMode.App:
        localStorage.setItem('viewMode', ViewMode.App)
        useAppStore.setState({ isAppViewMode: true })
        break
      case ViewMode.Web:
      default:
        // localStorage.removeItem('viewMode')
        useAppStore.setState({ isAppViewMode: false })
    }
  }
}

export default LayoutEditorService
