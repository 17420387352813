type LookupObject<T> = {
  [k: string]: T
}

type Key<T> = keyof T | ((item: T) => string)

export const createLookupObject = <T>(array: T[], key?: Key<T>): LookupObject<T> => {
  return Object.fromEntries(
    array.map((item) => {
      if (!key) {
        return [item, item]
      }

      if (typeof key === 'function') {
        return [key(item), item]
      }

      return [item[key], item]
    })
  )
}

export const fillDefaults = <T extends Record<string, unknown>>(
  object: Partial<T>,
  defaultObject: T,
  keys?: (keyof T)[]
): T => {
  const targetKeys = keys ?? Object.keys(defaultObject)

  return targetKeys.reduce((acc, key) => {
    return {
      ...acc,
      [key]: object[key] ?? acc[key],
    }
  }, defaultObject)
}

export default { createLookupObject, fillDefaults }
