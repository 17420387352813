import i18n from 'i18next'
import ChainedBackend from 'i18next-chained-backend'
import HttpApi from 'i18next-http-backend'
import LocalStorageBackend from 'i18next-localstorage-backend'
import { initReactI18next } from 'react-i18next'
// import LanguageDetector from 'i18next-browser-languagedetector'

// customization: HttpApi and language detector
import { request } from '../services/api'
import browserLanguageDetectorOpt from './browser-language-detector'

// tools
import formatter from './formatter/formatter'
import isDev from '../utils/isDev'

// settings
import i18nextAppOpts from '../app/config/i18next'
import environment from '../environments/environment'

// Caching time out
const cacheTime = isDev ? 3800 : 1 * 24 * 60 * 60 * 1000 // 24H

// for other settings see: https://www.i18next.com/overview/configuration-options
i18n
  .use(ChainedBackend)
  .use(browserLanguageDetectorOpt)
  .use(initReactI18next)
  .init({
    backend: {
      backends: [LocalStorageBackend, HttpApi],
      backendOptions: [
        {
          expirationTime: cacheTime,
          prefix: `${i18nextAppOpts.languageKeyName}_`,
          version: process.env.REACT_APP_VERSION,
        },
        {
          // translation file path
          loadPath: 'configuration/i18n/{{lng}}?module={{ns}}',
          useSuspense: false,
          saveMissing: false,
          withCredentials: true,
          request,
        },
      ],
    },
    detection: {
      // see: https://github.com/i18next/i18next-browser-languageDetector#detector-options
      order: ['path', 'localStorage', 'querystring', 'navigator'],
      lookupLocalStorage: i18nextAppOpts.languageKeyName,
    },
    load: 'languageOnly',
    lowerCaseLng: true,
    cleanCode: true,
    supportedLngs: environment.i18n.supportedLangs, // default: false
    nonExplicitSupportedLngs: false,
    fallbackLng: [environment.i18n.defaultLanguage], // en?, // environment.i18n.defaultLanguage, // en?
    debug: isDev,
    preload: [environment.i18n.defaultLanguage],
    ns: ['web', 'common', 'forms'],
    defaultNS: 'common',
    react: {
      bindI18n: 'languageChanged loaded',
      bindI18nStore: 'added removed',
      transSupportBasicHtmlNodes: true,
      transKeepBasicHtmlNodesFor: ['br', 'strong', 'b', 'i'],
    },
    keySeparator: false,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
      format: formatter,
    },
  })

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function l(obj?: any, language?: string): string {
  if (!obj || obj[language || i18n.language]) {
    return language || i18n.language
  }
  if (obj.en) {
    return 'en'
  }
  return Object.keys(obj)[0]
}

export default i18n
