import { AssetRegister } from '../../services/common/api/v1/websocket/AssetRegister'
import { AssetUnregister } from '../../services/common/api/v1/websocket/AssetUnregister'
import { VideoAsset } from '../../services/common/entities/Asset'
import { SessionMode } from '../../services/common/entities/Event'
import { Session } from '../../services/common/entities/Session'
import { ActionContext, ActionType } from '../../services/common/api/v1/websocket/Action'
import { removeFragment } from '../../utils/url'
import Websocket from '../../services/ws'
import GeoIpService from './GeoIp'
import LanguageService from './Language'
import Service from './Service'
import SessionHelperService from './SessionHelper'
import uuid from '../../lib/uuid'

class TrackingService extends Service {
  private static lastSessionTracking: {
    session: Session,
    mode: SessionMode
  } | null = null

  private static trackingId: string | null

  public static getTrackingId(): string {
    if(!this.trackingId) {
      const trackingIdLocalStorage = localStorage.getItem('trackingId')
      const trackingId = trackingIdLocalStorage?? uuid()
      localStorage.setItem('trackingId', trackingId)
      this.trackingId = trackingId
    }
    return this.trackingId
  }

  private static trackSession(session: Session, mode: SessionMode, action: 'register' | 'unregister', viewmode?: 'app' | 'normal' , url?: string) {

    try {
      const { language } = LanguageService.currentLanguage()
      let media: { asset?: string, stream?: string } | null = null

      if (mode === 'live') {
        const stream = SessionHelperService.stream(session)?._id
        if (stream) media = { stream }
      } else {
        const asset = SessionHelperService.asset(session)?.videoAsset?._id
        if (asset) media = { asset }
      }

      if (media) {
        if (action === 'register') {
          Websocket.register<AssetRegister>('asset', {
            ...media,
            url: url?.split('#')[0],
            viewmode,
            language,
            event: typeof session.event === 'string' ? session.event : session.event._id,
            sessionMode: mode,
            session: session._id,
            trackingId: this.getTrackingId(),
            country: GeoIpService.getCountry(),
            operatingCountry: GeoIpService.getOperatingCountry()?.isoCode,
          })
        } else {
          Websocket.unregister<AssetUnregister>('asset', {
            ...media,
            event: typeof session.event === 'string' ? session.event : session.event._id,
            sessionMode: mode,
            viewmode,
            url: url?.split('#')[0],
            session: session._id,
            language,
            trackingId: this.getTrackingId()
          })
        }
      }
    } catch (err) {
      console.error(err)
    }
  }

  public static registerSession(session: Session, mode: SessionMode, viewmode?: 'app' | 'normal', url?: string): void {
    TrackingService.unregisterSession()
    TrackingService.lastSessionTracking = { session, mode }
    TrackingService.trackSession(
      session,
      mode,
      'register',
      viewmode,
      url?.split('#')[0]
    )
  }

  public static unregisterSession(viewmode?: 'app' | 'normal', url?: string): void {
    if (TrackingService.lastSessionTracking) {
      TrackingService.trackSession(
        TrackingService.lastSessionTracking.session,
        TrackingService.lastSessionTracking.mode,
        'unregister',
        viewmode,
        url?.split('#')[0]
      )
      TrackingService.lastSessionTracking = null
    }
  }

  public static registerAsset(asset: VideoAsset, position: number, context: ActionContext): void {
    Websocket.register<AssetRegister>('asset', {
      ...context,
      asset: asset._id,
      language: LanguageService.currentLanguage().language,
      position,
      url: context?.url?.split('#')[0],
      trackingId: this.getTrackingId(),
      country: GeoIpService.getCountry(),
      operatingCountry: GeoIpService.getOperatingCountry()?.isoCode,
    })
  }

  public static unregisterAsset(context: ActionContext): void {
    Websocket.unregister<AssetUnregister>('asset', {
      asset: context?.asset,
      session: context.session,
      event: context.event,
      viewmode: context.viewmode,
      url: context?.url?.split('#')[0],
      language: LanguageService.currentLanguage().language,
      trackingId: this.getTrackingId()
    })
  }

  public static trackAction(action: ActionType, context: ActionContext): void {
    const sanitizedContext = {
      ...context,
      url: removeFragment(context.url ?? '')
    };

    Websocket.action({
      action,
      context: sanitizedContext,
      trackingId: this.getTrackingId(),
      country: GeoIpService.getCountry(),
      operatingCountry: GeoIpService.getOperatingCountry()?.isoCode
    })
  }

  static async init(): Promise<void> {
    this.getTrackingId()
  }
}

export default TrackingService
